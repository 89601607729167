import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../data/ENDPOINT'
import UserAccount from '../account/UserAccount'
import Localbase from 'localbase'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Chip } from '@mui/material'
import DetectDevice from '../../data/DetectDevice'
import SetPage from '../../data/SetPage'
import CourseSuggestions from './CourseSuggestions'
import CommunitySidebar from './CommunitySidebar'
import FetchFingerprint from '../../actions/FetchFingerprint'
const MyPostsTemplate = ({account,children}) => {

const db = new Localbase("db")
const {user} = useContext(AuthContext)


     const GetPosts = async (status)=>{
          setloadmore(false)
  
    const formData = new FormData();
    formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
    formData.append('limit',30)
    const res = await axios.post(ENDPOINT+"my-posts.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     //  //console.log("student posts"+JSON.stringify(res.data))
     if(res.data.status==="success"){
  
  
  
     
      
  
      if(append){
        setposts(res.data.posts)
        
       
      
  
   
    
  
        if((res.data.posts).length>=10){
  setloadmore(true)
  
  
  
        }
      }
      else{
        setposts(res.data.posts)
      }
     }
     else{
    
     }
    }
    }
  
  


const [posts,setposts] = useState(null)
const [loadmore,setloadmore] = useState(false)

const [append,setappend] = useState(false)
useEffect(()=>{
GetPosts()

setpage(JSON.parse(sessionStorage.getItem("page")))

},[])

const [page,setpage] = useState(null)
const device = DetectDevice()
  return (
    <div className="min-vh-100">
     
     <div className="d-flex mt-3 justify-content-between flex-wrap container">


<div className="col-lg-3 col-12 p-0">


{device==="mobile"?
<>
<div className=" d-flex w-100">

<div className="grid-2 grid w-100">
  <Link to="/my-posts">
  <Chip size="small" className={page==="My Posts"?"w-100 bg-primary text-white":"w-100"} label="My Posts"/>
  </Link>


<Link to="/saved-posts">
<Chip size="small" className={page==="Saved Posts"?"w-100 bg-primary text-white":"w-100"}    label="Saved"/>
</Link>


</div>
</div>

</>:
<>

<CommunitySidebar/>



<div className="mt-5  br-5 p-2">
  <CourseSuggestions/>
</div>



</>}





</div>
<div className="col-lg-8 col-12">





{children}








</div>


     </div>
     
     
     </div>
  )
}

export default MyPostsTemplate