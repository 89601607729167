import React from "react";
import CourseLeaderboard from "./CourseLeaderboard";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import GetCourseBatch from "../../../actions/GetCourseBatch";
import Localbase from "localbase";
import { AuthContext, AuthContextProvider } from "../../../context/AuthContext";
import { Avatar } from "@mui/material";
import "../../../assets/css/leaderboard.css";
import winner from "../../../assets/images/winner.png";
import bronze from "../../../assets/images/bronze-badge.png";
import silver from "../../../assets/images/silver-badge 3.png";
import Test from "../../courses/Test";
import ConnectionItemSidebarSuggestion from "../../connections/ConnectionItemSidebarSuggestion";
import CommunitySuggestions from "../../community/CommunitySuggestions";
import GettingStartedBatchprogress from "./GettingStartedBatchprogress";
const CourseLeaderboardPage = () => {
  const params = useParams();
  const course_url = params.course_url;
  const batch_id = params.batch_id;

  let db = new Localbase("db");
  const { user } = useContext(AuthContext);
  const [batch, setbatch] = useState(null);
  const GetBatch = async () => {
    const res = await GetCourseBatch({
      payload: {
        course_url: course_url,
        limit: 100,
        batch_id: batch_id,
        user: user.SessionId,
      },
    });
    if (res) {
      if (res.status === "success" && res.team !== null) {
        setbatch(res.team);

        db.collection("paths").add(
          {
            batch: res.team,
          },
          "batch",
        );
      }
      //  //console.log("batch "+JSON.stringify(res))
    }
  };

  console.log(batch ? batch[0] : null);
  useEffect(() => {
    db.collection("paths")
      .doc("batch")
      .get()
      .then((batch) => {
        setbatch(batch.batch);
      })
      .catch((error) => {});

    GetBatch();
  }, []);

  return (
    <div className="w-100  ">
      <div className="leaderboard-hero p-0 p-md-5 pt-5">
        <div className="d-flex justify-content-center flex-wrap ">
          {batch ? (
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center">
              <div className="p-3">
                <div className="mx-auto">
                  <Avatar
                    className="mx-auto leaderboard-doe"
                    alt="Remy Sharp"
                    src={batch[1].profile_pic}
                    sx={{ width: 75, height: 75 }}
                  />
                </div>
                <h5 className="p-0 m-0 text-white">{batch[1].first_name}</h5>
                <h5 className="p-0 m-0 text-white">{batch[1].last_name}</h5>
                <img className="leaderboard-img" src={silver} />
              </div>
              <div className="leaderboard-bg p-5  col-lg-6 col-md-6 col-sm-6 col-8 mx-auto rounded">
                <h2 className="text-white ">2</h2>
              </div>
            </div>
          ) : null}

          {batch ? (
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center">
              <div className="p-2">
                <div className="mx-auto ">
                  <Avatar
                    className="mx-auto leaderboard-doe"
                    alt="Remy Sharp"
                    src={batch[0].profile_pic}
                    sx={{ width: 90, height: 90 }}
                  />
                </div>
                <h5 className="p-0 m-0 text-white">{batch[0].first_name}</h5>
                <h5 className="p-0 m-0 text-white">{batch[0].last_name}</h5>
                <img className="leaderboard-img" src={winner} />
              </div>
              <div className="leaderboard-bg p-5  col-lg-6 col-md-6 col-sm-6 col-8 mx-auto rounded">
                <h2 className="text-white">1</h2>
              </div>
            </div>
          ) : null}

          {batch ? (
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center">
              <div className="p-4">
                <div className="mx-auto">
                  <Avatar
                    className="mx-auto leaderboard-doe"
                    alt="Remy Sharp"
                    src={batch[2].profile_pic}
                    sx={{ width: 70, height: 70 }}
                  />
                </div>
                <h5 className="p-0 m-0 text-white">{batch[2].first_name}</h5>
                <h5 className="p-0 m-0 text-white">{batch[2].last_name}</h5>
                <img className="leaderboard-img" src={bronze} />
              </div>
              <div className="leaderboard-bg p-3 col-lg-6 col-md-6 col-sm-6 col-8 mx-auto rounded">
                <h2 className="text-white">3</h2>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="container col-12">
        <div className="d-flex flex-wrap">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <CourseLeaderboard />
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 col-12 px-sm-2 px-0">
            <div className=" bg-white rounded border border-1 my-5">
              <div className="p-md-4 p-sm-4 p-1">
                <GettingStartedBatchprogress />
                <ol>
                  <li>
                    Make sure you submit assignment on or before deadline to
                    gain more points
                  </li>
                  <li>Also reach your milestones to get more points</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLeaderboardPage;
