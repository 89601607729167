import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import MenuBookIcon from '@mui/icons-material/MenuBook';
import divider from '../../assets/bg/divider.png'
import ENDPOINT from '../../data/ENDPOINT'
import Alert from '@mui/material/Alert';
import { Avatar } from '@mui/material'
import axios from 'axios'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Close from '@mui/icons-material/Close';
import SetPage from '../../data/SetPage';
import Gallery from '../gallery/Gallery'
import DetectDevice from '../../data/DetectDevice'
import MobileGallery from '../gallery/MobileGallery'
import { Helmet } from 'react-helmet'
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import styled from 'styled-components';
import img1 from '../../assets/about/images/team.png';
import img2 from '../../assets/about/images/team2.png';
import img3 from '../../assets/about/images/team3.png';
import pradeep from '../../assets/about/images/pradeep.png'
import minhaz from '../../assets/about/images/minhaz.png'
import company from '../../assets/about/images/image1.png';
import certificate from '../../assets/about/images/gobal-certificate.webp';
import holic from '../../assets/about/images/image2.jpeg'

import group136 from '../../assets/about/images/Group 136.jpg'
import facebook from '../../assets/about/images/facebook (3).png'
import twitter from '../../assets/about/images/twitter.png'
import instagram from '../../assets/about/images/instagram.png'
import linkedin from '../../assets/about/images/linkedin.png'

import '../../assets/css/style.css'
import image6 from '../../assets/about/images/image6.png';
import rating from '../../assets/about/images/ratings.jpeg'
import ContactPage from '../contact/ContactPage';

import mission from "../../assets/about/images/mission.svg";
import vision from "../../assets/about/images/vision.svg";
import group from '../../assets/about/images/Group2.png'

const AboutCompanyPage = () => {

    const [statistics, setstatistics] = useState(null)
    useEffect(() => {
        window.scrollTo(0, 0)
        onSubmit()
    }, [])


    const onSubmit = async () => {


        let res = await uploadFile();

        if (res) {
            //  //  //  //console.log("about"+JSON.stringify(res))
            if (res.data.status === "success") {


                setstatistics(res.data)
            }
            else {

            }
        }

    }
    const device = DetectDevice()

    const uploadFile = async () => {

        ////  //  //console.log("apply internship")
        const formData = new FormData();

        return await axios.post(ENDPOINT + "about-page-statistics.php", formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    }





    const [show, setshow] = useState(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
    };



    useEffect(() => {
        SetPage("About Company")
    }, [])



    const [showAll, setShowAll] = useState(false);

    const handleViewAll = () => {
        setShowAll(!showAll);
    };



    return (
        <StyledAboutPage>

            <>
                <Helmet>‍
                    <title>About Us - Skills Uprise</title>‍
                    <meta name="description" content="Skills Uprise" />
                    <meta name="twitter:card" content="" />
                    <meta name="twitter:site" content="@skillsuprise" />
                    <meta name="twitter:creator" content="@skillsuprise" />
                    <meta name="twitter:title" content="" />
                    <meta name="twitter:description" content="" />
                    <meta name="twitter:image" content="" />
                    <meta property="og:title" content="" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="" />
                    <meta property="og:url" content="" />
                    <meta property="og:site_name" content="Skills Uprise" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
                </Helmet>
                <Modal
                    open={show}
                    onClose={() => setshow(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="col-lg-5 col-xl-4 col-md-6 col-sm-10 col-11"
                        sx={style}>

                        <div className="d-flex justify-content-between">
                            <h4 className="text-dark">History of Skills Uprise</h4>
                            <Close type="button" className="text-danger" onClick={() => setshow(false)} />
                        </div>
                        <hr />
                        <video height="auto" autoPlay width="100%" src="https://skillsuprise.com/data/videos/history.mp4" title="Skills Uprise Trainings Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen controls controlsList="nodownload"></video>


                    </Box>
                </Modal>


                <div>


                    <div className='container-fluid box py-0 py-md-4 py-lg-4'>
                        <div className='d-flex container mx-auto py-3 px-0  justify-content-between  '>
                            <div className='my-auto mt-3 mt-md-5 mt-lg-5'>
                                <span className=' fs col-lg-5 col-md-8 col-sm-12 col-12 sm-30'><b>We are people who care about<strong className='growth'> Your Growth</strong></b></span>
                                <p className='mt-3 mt-md-5 mt-lg-5 fs-5 col-lg-8 col-md-9 col-sm-12 col-12 sm-20 '>Over 20,000 students are placed in more than 30 companies all  these
                                    breadth of gobal coverage, combined with specialist.</p>
                                <button className='bg btn col-lg-3 d-none text-light mt-3 py-2'><b>Explore More+</b></button>
                            </div>
                            <img className='col-lg-5 ms-3 d-lg-block d-md-none d-sm-none d-none' src={group}></img>
                        </div>
                    </div>




                    <div className='container-fluid'>
                        <div className='container min-vh-90 pt-5 pb-5'>
                            <div className=' mt-5 mb-5'>

                                <h1 className=''><b>Awarded As <span className='growth'><br></br>Best Educational Service Provider</span></b></h1>
                                <p className=''><b>At Global Business & Education Awards 2023</b></p>

                                <div className='mt-3 d-flex flex-wrap justify-content-between align-items-center'>
                                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 my-auto '>

                                        <img className='w-100  col-12 rounded' src={certificate}></img>
                                    </div>

                                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 p-md-3 col-12  '>

                                        <div className='col-lg-12 col-12  d-flex flex-wrap'>
                                            <div className='col-lg-6 col-md-6 col-sm-8 col-12 p-2'>
                                                <img className='w-100' src={mission} alt="" />
                                            </div>


                                            <div className='col-lg-6 col-md-6 col-sm-8 col-12 p-2'>
                                                <img className='w-100' src={vision} alt="" />
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>


                    {/* <div className=' container-fluid d-none box2 text-light p-3'>
                        <div className='mt-5 mb-5 container '>
                            <h1><b>We're Workoholic !</b></h1>
                            <div className='d-flex flex-wrap mt-3'>
                                <h2 className='col-lg-5 col-md-6 col-sm-12 col-12'>We don't have a dead end for our determination</h2>
                                <p className='col-lg-6 col-md-8 text-white  '>For us, providing services at skills uprise is not a responsibility
                                    for 9-5 jobs. It's from the time we open our eyes till we close them.
                                    your success is what we care about.</p>
                            </div>

                            <div className='d-flex flex-wrap mt-4 justify-content-between'>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-9 col-12 mx-auto p-2 mb-2'>
                                    <div className='left bg-light text-center'>
                                        <img className='col-lg-12 col-md-12 col-sm-12 col-12 lt' src={holic}></img>
                                        <p className='text-dark p-1 fs-5 sm-14'>We love to create a success path for you.</p>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-9 col-12 mx-auto p-2 mb-2'>
                                    <div className='left bg-light text-center'>
                                        <img className='col-lg-12 col-md-12 col-sm-12 col-12 lt' src={holic}></img>
                                        <p className='text-dark px-4 py-1 fs-5 sm-14 '>We love to  share your success story.</p>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-9 col-12 mx-auto p-2 mb-2'>
                                    <div className='left bg-light text-center'>
                                        <img className='col-lg-12 col-md-12 col-sm-12 col-12 lt' src={holic}></img>
                                        <p className='text-dark p-1 fs-5 sm-14'>We love to celebrate your Achievements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='container col-lg-12 '>
                        <div className='text-center'>
                            <h1><b>Our Awesome Team Members</b></h1>
                            <h2 className='growth mb-5'><b>The People Behind Skills Uprise</b></h2>
                        </div>
                        <div className='container-fluid '>
                            <div className=' d-flex align-items-center flex-wrap'>

                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0 '>
                                    <div className=" position-relative col-11" id='demodiv1'>

                                        <img src="https://skillsuprise.com/images/team/developer.png" alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14'>VASU</h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-lg-3  col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0 '>
                                    <div className=" position-relative col-11" id='demodiv1'>

                                        <img src="https://skillsuprise.com/images/team/prasad.png" alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14'>Leela Prasad</h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Video Editor</h6>
                                        </div>
                                    </div>
                                </div>



                                <div className='col-lg-3  col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0 '>
                                    <div className="position-relative col-11" id='demodiv1'>

                                        <img src="https://skillsuprise.com/images/team/karishma.png" alt="" className='img w-100 col-12' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3 ' id='name'>
                                            <h5 className='p-0 m-0 sm-14'>Karishma</h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Trainer</h6>
                                        </div>
                                    </div>
                                </div>




                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                    <div className=" position-relative col-11" id='demodiv1'>

                                        <img src="https://skillsuprise.com/images/team/guresh.png" alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14 '>Guresh</h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                        {/* <div id="hidden_div"
                                            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
                                            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
                                            <div className='container '>
                                                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={facebook} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={twitter} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={instagram} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={linkedin} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button>
                                        </div> */}
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex flex-wrap'>

                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                    <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                        <img src="https://skillsuprise.com/images/team/masthan.png" alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14 '>Masthanaiah</h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                        {/* <div id="hidden_div"
                                            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
                                            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
                                            <div className='container '>
                                                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={facebook} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={twitter} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={instagram} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={linkedin} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button>
                                        </div> */}
                                    </div>
                                </div>


                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0 '>
                                    <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                        <img src="https://skillsuprise.com/images/team/vinay.png" alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14'>Vinay</h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                        {/* <div id="hidden_div"
                                            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
                                            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
                                            <div className='container '>
                                                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={facebook} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={twitter} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={instagram} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-3 '>
                                                        <div className='col-8'>
                                                            <img className='w-100' src={linkedin} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button>
                                        </div> */}
                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                    <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                        <img src="https://skillsuprise.com/images/team/srinu.png" alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14 '>Srinu</h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                    <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                        <img src="https://skillsuprise.com/images/team/harish.png" alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className=' p-0 m-0 sm-14 '>Harish</h5>
                                            <h6 className='text-primary  p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                    </div>
                                </div>

                                {showAll && (
                                    <div className='d-flex flex-wrap'>
                                        <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                            <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                                <img src="https://skillsuprise.com/images/team/nitheesh.png" alt="" className='img w-100' />

                                                <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                                    <h5 className='p-0 m-0 sm-14 '>Nitheesh</h5>
                                                    <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                            <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                                <img src="https://skillsuprise.com/images/team/rohith.png" alt="" className='img w-100' />

                                                <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                                    <h5 className='p-0 m-0 sm-14 '>Rohith</h5>
                                                    <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                                </div>
                                            </div>
                                        </div>



                                        <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                            <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                                <img src="https://skillsuprise.com/images/team/praveen.png" alt="" className='img w-100' />

                                                <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                                    <h5 className='p-0 m-0 sm-14 '>Praveen</h5>
                                                    <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                                </div>
                                            </div>
                                        </div>



                                        <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                            <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                                <img src="https://skillsuprise.com/images/team/shanker.png" alt="" className='img w-100' />

                                                <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                                    <h5 className='p-0 m-0 sm-14 '>Gowri Shankar</h5>
                                                    <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                            <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                                <img src="https://skillsuprise.com/images/team/mounika.png" alt="" className='img w-100' />

                                                <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                                    <h5 className='p-0 m-0 sm-14 '>Mounika</h5>
                                                    <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                                

                            {/* <div className='text-center mx-auto col-lg-4 col-md-8 col-sm-12 col-12   mt-2  '>
                                <Link to="/team" onClick={handleViewAll} className="btn btn-primary w-50 col-12"> {showAll ? 'Show Less' : 'View All'}</Link>
                            </div> */}


                            <div className='text-center mx-auto col-lg-4 col-md-8 col-sm-12 col-12 mt-2'>
                                <button onClick={handleViewAll} className="btn btn-primary w-50 col-12">
                                    {showAll ? 'Show Less' : 'View All'}
                                </button>
                            </div>

                        </div>

                    </div>


                    {/* <div className='container-fluid bg-img my-5'>
                        <div className='py-5 padding-about text-center text-light col-lg-9 mx-auto col-md-12 col-sm-12 col-12 d-lg-block d-md-block d-sm-none d-none'>
                            <h1>Every skill you acqurie doubles your odds of success</h1>
                            <div className='mt-5 d-flex justify-content-between'>
                                <div>
                                    <MenuBookIcon className='fs' />
                                    <h1 className='text-warning'>15+</h1>
                                    <h5>No.of Courses</h5>
                                </div>
                                <div>
                                    <PersonIcon className='fs' />
                                    <h1 className='text-warning'>1,000+</h1>
                                    <h5>No.of Students</h5>
                                </div>
                                <div>
                                    <PersonIcon className='fs' />
                                    <h1 className='text-warning'>30+</h1>
                                    <h5>No.of Placements</h5>
                                </div>
                                <div>
                                    <PersonIcon className='fs' />
                                    <h1 className='text-warning'>1,000+</h1>
                                    <h5>No.of Registrations</h5>
                                </div>
                            </div>
                        </div>
                    </div> */}




                    <div className='container col-lg-11 col-md-12 col-sm-12 col-12 my-5 md-p-5  p-3 p-lg-5  '>
                        <div className='text-center'>
                            <h1><b>We spread smiles through success stories</b></h1>
                            <h2 className='growth'><b>& At work, We love to learn more</b></h2>
                            <p className='mt-2 mb-4'>Listen to what your team says about the work cuture at skills uprise</p>
                        </div>
                        <div className='d-flex flex-wrap'>
                            <div className='col-lg-4 col-md-6 col-sm-8 col-12 p-0 p-md-4 p-lg-4 mb-4 mx-auto  '>
                                <div className='shadow sdw '>
                                    <div className='overflow-hidden order'>
                                        <img className='w-100 zoom' src={img1}></img>
                                    </div>

                                    <div className='col-lg-12 p-3 '>
                                        <h5 className='growth'>You can commit mistakes. But don't forgot
                                            to learn lessons out of it.
                                        </h5>
                                        <p className='mt-1 fs5'>Your mistakes are valued before being summoned for
                                            commiting if.Team leaders will take up the responsibility, they care
                                            and they teach you how to do it the right way. It is like we are human's,
                                            we commit mistakes. But remember ! repeating the same mistake will always
                                            lead to unpredietable consequences.
                                        </p>
                                        <hr className='mt-3'></hr>
                                        <div className='d-flex align-items-center'>
                                            <img className='pradeep rounded rounded-5 ms-1' src={pradeep}></img>
                                            <p className='ms-1 mb-0'>~Pradeep Sai</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-sm-8 col-12 p-0  p-md-4 p-lg-4 mb-4 mx-auto '>
                                <div className='shadow sdw '>
                                    <div className='overflow-hidden order'>
                                        <img className='w-100 zoom' src={img2}></img>
                                    </div>

                                    <div className='col-lg-12 p-3 '>
                                        <h5 className='growth'>Be workoholic ! Also have fun the same way.
                                        </h5>
                                        <p className='mt-1 fs5'>At Skillsuprise people are respected for their work.
                                            Being responsible and timely at work always has many advantages. Your team
                                            becomes your family and you will have lots of brothers and sistrs at work.
                                            At Skillsuprise we are given equal priority to professional & professional
                                            life. Our teams have mastered strategic approaches to work short for better
                                            outcomes.
                                        </p>
                                        <hr></hr>
                                        <div className='d-flex align-items-center'>
                                            <img className='pradeep rounded rounded-5 ms-1' src={minhaz}></img>
                                            <p className='ms-1 mb-0'>~Minhaz</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-sm-8 col-12 p-0  p-md-4 p-lg-4 mb-3 mx-auto '>
                                <div className='shadow sdw  '>
                                    <div className='overflow-hidden  order'>
                                        <img className='w-100 zoom' src={img3}></img>
                                    </div>

                                    <div className='col-lg-12 p-3 '>
                                        <h5 className='growth'>You can be the naughtiest person of your team.
                                        </h5>
                                        <p className='mt-1 fs5'>You can jump around the corners to have fun and laugher.
                                            You are valued as a person than your profession. Ofcourse your can be summoned
                                            for your over action too. But a friendly working environment with brotherhood
                                            between the colleagues always turns our work pressure into pleasure as we
                                            support each other for individual growth.
                                        </p>
                                        <hr></hr>
                                        <div className='d-flex align-items-center'>
                                            <img className='pradeep rounded rounded-5 ms-1' src="https://skillsuprise.com/resumes150199/442013-blob"></img>
                                            <p className='ms-1 mb-0'>~Ravi Sai</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container  mb-5">
                        {device === "mobile" ?
                            <>
                                <MobileGallery />

                            </> :
                            <>
                                <Gallery />
                            </>}



                    </div>

                    <div className=' mt-5 col-lg-12 mx-aut0 col-md-11 col-sm-12 col-12 '>

                        <div className=' '>

                            <div className='col-lg-12 col-md-12 col-sm-12 col-12 mb-5'>

                                <div className='col-lg-12  col-md-12 col-sm-12 col-12'>
                                    <ContactPage topspace={"pt-5"} />
                                </div>
                            </div>

                            {/* <div className=' mx-auto col-lg-4 col-md-6 col-sm-12 col-12 margin-about mt-5 mb-5 '>
                                <h2 className='growth m-0 p-0 text-center'>Company Address</h2>
                                <div className='shadow p-2 mt-4'>
                                    <iframe className='w-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3799.958499890391!2d83.32966787458469!3d17.746594483203157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39435d19776749%3A0x3956984daf0fc07c!2sSkills%20Uprise!5e0!3m2!1sen!2sin!4v1719312254068!5m2!1sen!2sin" height="235" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    <button className='btn btn-primary m-2'>View Map</button>
                                </div>
                            </div> */}

                        </div>


                    </div>

                    <div className='container-fluid bg-img2 my-5 py-5'>
                        <div className='text-center text-light col-lg-9 mx-auto p-0 p-md-5 p-lg-5'>
                            <h1 className='mt-4'><b>Come, Join Us</b></h1>
                            <h2 className='text-primary mt-3 sm-16'><b>Let's Revolutionise People's Careers Together</b></h2>
                            <p className='my-lg-5 my-md-5 my-0 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto fnt text-white sm-14'>Currently there are <b>{statistics ? statistics.openings : "100+"}</b>openings on multiple positions. Apply now to onboard
                                with our team and be a part of our mission to help individuals to have a better
                                career opportunity.
                            </p>

                            <Link to="/internships" className="btn  btn-warning sm-mt-20 mt-3">Browse Opportunities</Link>
                        </div>
                    </div>



                    {/* <div className="container justify-center pt-5 pb-5 mt-5">


<div className="col-lg-11 mobile-reverse flex-wrap d-flex ">
    

    <div className="col-lg-6 sm-mt-20">
<h3 className="sm-18 secondary-text">Come , Join Us </h3>
<h1 className="sm-22 text-primary">Let's Revolutionise People's Careers Together</h1>
<p>Currently, there are <b>{statistics?statistics.openings:"100+"} openings</b> on multiple positions. Apply now to onboard with our team and be a part of our mission to help individuals have a better career opportunity.</p>
  
 <Link to="/internships" className="btn  btn-warning sm-mt-20 mt-3">Browse Opportunities</Link> 
    </div>

    <div className="col-lg-6 sm-mt-20">
        <img src="https://skillsuprise.com/resources/images/onboard.png" className="w-100 br-5"/>
    </div>
</div>





</div> */}





                </div>


            </>

        </StyledAboutPage>
    )
}

export default AboutCompanyPage

const StyledAboutPage = styled.div`
.box{
  background-color:#E4E4FF}

.bg{
 background-color:#070B60} 
 
 .fs{
 font-size:60px;
 }

.fs5{
 font-size:13px;}

.growth{
 color:#0649ff}

 .mission{
  border-top:60px solid #8CC63E
  }
  .mission{
  border-bottom:20px solid #8CC63E
  }
  .missiontx{
   color:#8CC63E}
.culture{
  border-top:60px solid #FBB547
  }
  .culture{
  border-bottom:20px solid #FBB547
  }
  .culturetx{
  color:#FBB547}
  .box2{
  background-color:#071F43}

.pradeep{
  height:40px;
  }

.lt{
border-radius: 60px 5px 0px 0px;
}
.left{
  border-radius: 60px 5px 5px 5px;
}

@media only screen and (max-width: 992px) {
  .margin-about {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 576px) {
  .padding-about {
    padding:0px !important;
  }
}

@media only screen and (max-width: 576px) {
  .last-name {
    margin-top:50px !important;
  }
}

.adress{
height:250px;

}

/* .order{
border-top-left-radius:10px;
border-top-right-radius:10px;
} */

`