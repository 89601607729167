import CloseIcon from '@mui/icons-material/Close';
import {Col,Button,Dropdown} from 'react-bootstrap';
import Modal from '@mui/material/Modal';
import {useState,useEffect, useContext} from 'react';
import axios from 'axios'
import { Chip } from '@mui/material'
import Rating from '@mui/material/Rating';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Toast from 'react-bootstrap/Toast'
import React from 'react';
import { AuthContext } from '../../../context/AuthContext';
import MONTHS from '../../../context/Months';

import MoreVert from '@mui/icons-material/MoreVert';
import { useParams } from 'react-router-dom';
import FetchFingerprint from '../../../actions/FetchFingerprint';

const InterpersonalSkillsList=()=>{
  const {user} = useContext(AuthContext)
 const params =useParams()
 const username = params.username
    const [title,settitle]=useState(null);
 
    const [education,seteducation]=useState(null);
const [level,setlevel] = useState(3)
    const SaveEducation= async(e)=>{
        e.preventDefault()
        const formData= new FormData();
        formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
        formData.append('title',title);
        formData.append('level',level);
     
        const res = await axios.post('https://skillsuprise.com/interpersonal-skills-add.php',formData,{
           headers:{'content-type':'multipart/form-data'}
        })
       
        if(res){
          // //  //  //console.log("added "+JSON.stringify(res));
            if(res.data.status==="success"){
              settoasttitle("Education added successfully")
              settoast(true)
              Fetch_education()
               setaddeducation(false)
           ClearData()
            }
            else{
                
            }
          //  //  //  //console.log(JSON.stringify(res))
        }
    }
   
const [self,setself] = useState(false)
    const Fetch_education = async () => {
        const formdata = new FormData();
        formdata.append('session',user.SessionId)
        formdata.append("fp",FetchFingerprint());
        formdata.append("username",username)
        const res = await axios.post(
          "https://skillsuprise.com/user-interpersonal-skills.php",
          formdata,
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );
        if (res.data) {
        //    //  //  //console.log(JSON.stringify(res))
          if (res.data.status === "success") {
            if(res.data.data!==null || res.data.data!=="null"){
              seteducation(res.data.data);
              setself(res.data.self)
            }
        
          }
        }
      };

const [editid,seteditid] = useState(null)
      const Edit = async ({id,action,index}) => {
     seteditid(id)
        const formdata = new FormData();
        formdata.append('session',user.SessionId)
        formdata.append("fp",FetchFingerprint());
        formdata.append('id',id);
        if(action==="get"){
          settitle(education[index].title)
          setlevel(education[index].level)
          formdata.append('get',true);
        }else{
          formdata.append('title',title);
          formdata.append('level',level);
         
        }
        const res = await axios.post(
          "https://skillsuprise.com/interpersonal-skills-edit.php",
          formdata,
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );
        if (res) {
          //  //  //  //console.log("edit "+JSON.stringify(res))
          if (res.data.status === "success") {
            if(action==="get"){
               
              setedit(true)
             
            }else{
              setedit(false)
              Fetch_education()
            }
            if(res.data.data===null){
              settoasttitle("Edit successful")
              settoast(true)
             setedit(false)
             ClearData()
             Fetch_education()
            }
            else{
              if(action==="get"){
               
                setedit(true)
               
              }
            
           
            }
        
          }
        }
      };







      useEffect(() => {
        Fetch_education();
      },[]);
      const Delete = async ({education_id}) =>{
          const data = new FormData();
          data.append('id',education_id);
          data.append('session',user.SessionId)
        data.append("fp",FetchFingerprint())
          const res = await axios.post(
            "https://skillsuprise.com/interpersonal-skills-delete.php",
            data,
            {
              headers: { "content-type": "multipart/form-data" },
            }
          );
          if (res) {
             // //  //  //console.log(JSON.stringify(res))
            if (res.data.status === "success") {
              settoasttitle("Deleted successfully")
              settoast(true);
          
             Fetch_education();
             
            }
          }
      }

      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    
        bgcolor: 'background.paper',
      
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };
       
const [addeducation,setaddeducation] = useState(false)
const defaultProps = {
  options: MONTHS,
  getOptionLabel: (option) => option.name,
};


const [edit,setedit] = useState(false)



const [toast,settoast] = useState(false)

const [toasttitle,settoasttitle] = useState(null);
const [toastmessage,settoastmessage] = useState(null)

const ClearData = ()=>{
  settitle(null);

}
    return(

      <>





<div className="toast-container">


<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
           
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>














            <Modal
        open={edit}
        onClose={()=>setedit(false)}

      >
        <Box className="col-lg-5 col-md-5 col-sm-6 col-11" sx={style}>

          <div className="w-100">
            <div className="flex align-items-center space-between">

              <h4>Add Skill</h4>
              <CloseIcon type="button" onClick={()=>{setedit(false)}}/>
            </div>
            <hr/>
          <form method="post" onSubmit={(e)=>{e.preventDefault();Edit({id:editid,action:"edit"})}}>
          <label className="mt-1 w-100 me-3">Skill Title</label>
                <input 
                required
                minLength={3} maxLength={30}
                className="  form-control me-3" type="text" value={title}  onChange={(e)=>{settitle(e.target.value)}} name="title"></input><br/>
                <label className="mt-1 me-3">Level</label>
               
                
      <div className="flex">

           
      <Rating
  name="simple-controlled"
  value={level}
  onChange={(event, newValue) => {
    setlevel(newValue);
  }}
/>


      {level===1?"Beginner":level===2?"Intermediate":level===3?"Average":level===4?"Better":"Best"}
    
      </div>

        
             








        
        
        
                <Button variant="primary w-100 mt-5"  type="submit">Save Changes</Button>
              
                
                
            </form>
            

          </div>
        
        </Box>
      </Modal>



      <Modal
        open={addeducation}
        onClose={()=>setaddeducation(false)}

      >
        <Box className="col-lg-5" sx={style}>

          <div className="w-100">
            <div className="flex align-items-center space-between">

              <h4>Add Skill</h4>
              <CloseIcon type="button" onClick={()=>{setaddeducation(false)}}/>
            </div>
            <hr/>
          <form method="post" onSubmit={SaveEducation}>
                <label className="mt-1 w-100 me-3">Skill Title</label>
                <input 
                required
                minLength={3} maxLength={30}
                className="  form-control me-3" type="text" value={title} onChange={(e)=>{settitle(e.target.value)}} name="title"></input><br/>
               
                
    
        <div className="flex">
        <Rating
  name="simple-controlled"
  value={level}
  onChange={(event, newValue) => {
    setlevel(newValue);
  }}
/>
{level===1?"Beginner":level===2?"Intermediate":level===3?"Average":level===4?"Better":"Best"}
          
</div>
              
              
  <Button variant="primary w-100 mt-5"  type="submit">Save Changes</Button>
              
                
                
            </form>
            

          </div>
        
        </Box>
      </Modal>





     














{education?
<>
<div className="border p-2 br-5">



  



<div className="flex space-between align-items-center">
<h4 className="mb-3 pt-2">Interpersonal Skills</h4>
{self?
<>

<AddIcon type="button" onClick={()=>setaddeducation(true)}/>

</>:null} 
</div>





        


           
            <Col lg={12} md={12} sm={12} xs={12} className=" rounded">
            {education?
                        <>
                        {education.map((edu,index)=>(
                        <>
                       <div className="flex space-between mt-3 mb-3">
<div className="flex">
  <Chip  size="small"  label={edu.title} readOnly/>
<Rating  size="small"  value={edu.level} readOnly/>
<div>


</div>
</div>

{self?
<>
<div className="flex">
  
  <Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu> 

<Dropdown.Item onClick={()=>Edit({id:edu.id,action:"get",index:index})}>Edit</Dropdown.Item>
  <Dropdown.Item  onClick={()=>Delete({education_id:edu.id})} >Delete</Dropdown.Item>



  
  </Dropdown.Menu>
</Dropdown>
</div>
</>:null}

</div>

                        
                        </>
                        ))}
                        </>
                        :<>
                       {self?
                       <>
                       
                       <div onClick={()=>setaddeducation(true)} type="button"  className="flex text-primary align-items-center">
<AddIcon className="mr-2"/>
<h6 className="mb-0">Add Skill</h6>

                        </div>
                       
                       </>:null}
                   
                      
                        </>}
                
            </Col>
      

        </div>




</>:
<>

<div className="border container mb-3 border br-5  mt-3">
  
{education?
<>
<div className="flex space-between align-items-center">
<h4 className="mb-3 pt-2">Interpersonal Skills</h4>

{self?
<>

<AddIcon type="button" onClick={()=>setaddeducation(true)}/>
</>:null}
</div>
</>:
<>
</>}


{education?
<>

</>:
<div className="text-center pt-4 pb-4">

{self===true?
<>

<h4 className="btn btn-outline-secondary rounded-pill"  onClick={()=>setaddeducation(true)}>Add interpersonal skill</h4>

</>:
<>

<div className="text-center">
    <h5 className="text-secondary">No Inperpersonal Skills Added</h5>
  </div>
</>}

    </div>}

</div>


</>}


























      </>
   
    );
}
export default InterpersonalSkillsList;