import signupplacements from "../../assets/signup-placements.svg";

import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import textlogo from "../../assets/images/skillsuprise-logo.png";
import firebaseConfig from "../../firebase-Config";
import axios from "axios";
import googlelogo from "../../assets/images/google-logo.png";
import ENDPOINT from "../../data/ENDPOINT";
import { Link } from "react-router-dom";
import { Button, Form, Toast } from "react-bootstrap";
import { useEffect } from "react";
import LogoutAllNotification from "./LogoutAllNotification";
import AuthFeatures from "./AuthFeatures";
import SetPage from "../../data/SetPage";
import SecurityHeaders from "../../data/SecurityHeaders";
import { Google, Visibility } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { ref, push,set, onValue, getDatabase } from "firebase/database";
const Login = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
const InitiateGoogleLogin = ()=>{
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
   // console.log("user is "+JSON.stringify(user)+" secret :"+credential.secret+" proid: "+credential.providerId+" id token:"+credential.idToken+" token "+credential.accessToken)
    console.log(user.providerData[0].email)

  const dbRef = ref(
    getDatabase(),
   "/auth/"+user.uid  );
  set( dbRef,{
    sessionID: credential.accessToken,
    email:user.providerData[0].email
  }).then(() => {
    // Redirect the user to the "google-signin" path


    CheckGoogleSignin({payload:{uid:user.uid,token:credential.accessToken}})
   })
  .catch((error) => {
    // Handle any errors that occurred during setting data
    console.error('Error setting data in the database:', error);
  });

  // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log("user error ")
  
    // ...
  });
}

  
  const [ToastTitle, setToastTitle] = useState(null);
  const [ToastMessage, setToastMessage] = useState(null);

  const redirect = sessionStorage.getItem("next") || "/dashboard";
  const msg = sessionStorage.getItem("msg") || null;

  const [loginerror, setloginerror] = useState(null);

  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);
  const usercredentials = { email, password };
  const [isFetching, setisFetching] = useState(false);
  const loginaction = async (e) => {
    setisFetching(true);
    e.preventDefault();
    const formdata = new FormData();

    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("sc_id",JSON.parse(localStorage.getItem("uid")))
    //  //console.log("trying "+email+" "+password)
    try {
      const res = await axios.post(
        `${ENDPOINT}login.php`,
        formdata,
        SecurityHeaders,
      );

      setisFetching(false);
      if (res) {
        //  //console.log("login is "+JSON.stringify(res.data))

        const status = res.data.status;
        const data = res.data.data;
        if (status === "success" && data !== null) {
          localStorage.setItem("user", JSON.stringify(res.data.data));

          setToastTitle("Login Successful");
          setToastMessage("Redirecting...");
          setShowA(true);

          if (
            sessionStorage.getItem("next") === "null" ||
            sessionStorage.getItem("next") === "" ||
            sessionStorage.getItem("next") === null ||
            sessionStorage.getItem("next") === undefined
          ) {
            sessionStorage.setItem("next", "");
            window.location.replace("/dashboard");
          } else {
            var redirection_url = redirect;
            sessionStorage.setItem("next", "");
            window.location.replace(redirection_url);
          }
        } else if (status === "error" && data === "limit exceeded") {
          setloginerror("limit exceeded");
        } else if (status === "error" && data === "wrong credentials") {
          setloginerror("wrong credentials");
          setToastTitle("Login Error !");
          setToastMessage("Invalid email or password.");
          setShowA(true);
        } else if (status === "error" && data === "session error") {
          setToastTitle("Something went wrong !");
          setToastMessage("Please try again after sometime.");
          setShowA(true);
        } else {
          setisFetching(false);
          setToastTitle("Something went wrong here!");
          setToastMessage("Please try again after sometime.");
          setShowA(true);
        }
      }
    } catch (err) {
      setToastTitle("Something went wrong !");
      setToastMessage("Please try again after sometime.");
      setShowA(true);
    }
  };

  const [showA, setShowA] = useState(false);

  const toggleShowA = () => setShowA(!showA);

  const [visibility, setvisibility] = useState(false);

  const MakeVisible = () => {
    if (visibility) {
      document
        .getElementById("password")
        .attributes.setNamedItem("type", "password");
    } else {
      document
        .getElementById("password")
        .attributes.setNamedItem("type", "text");
    }
    setvisibility(!visibility);
  };

  SetPage("Login");


  const [path,setpath] = useState("anonymouutdyd")
  const dbRef = ref(
    getDatabase(),
   "/skillsuprise"
  );

useEffect(()=>{


},[])
  useEffect(() => {
    // Listen for changes in the 'messages' node in the database
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messageList = Object.values(data);
      
      }
    });
  }, []);



  const CheckGoogleSignin = async({payload})=>{
setloading(true)
    const data = new FormData()

    data.append("uid",payload.uid)
 
    data.append("token",payload.token)

const res = await axios.post(ENDPOINT+"/google-signin.php",data,SecurityHeaders)
if(res){
setloading(false)
if(res.data.status==="success"){

if(res.data.data){

  localStorage.setItem("user", JSON.stringify(res.data.data));

  setToastTitle("Login Successful");
  setToastMessage("Redirecting...");
  setShowA(true);
  if (
    sessionStorage.getItem("next") === "null" ||
    sessionStorage.getItem("next") === "" ||
    sessionStorage.getItem("next") === null ||
    sessionStorage.getItem("next") === undefined
  ) {
    sessionStorage.setItem("next", "");
    window.location.replace("/dashboard");
  } else {
    var redirection_url = redirect;
    sessionStorage.setItem("next", "");
    window.location.replace(redirection_url);
  }
}
}
else if (res.data.status==="error"){

  setToastTitle(res.data.title);
  setToastMessage(res.data.msg);
  setShowA(true);


}

}

}


  const [loading, setloading] = useState(false);
  return (
    <LoginStyled>
      <Helmet>
        <title>Login | Skills Uprise</title>
        <meta
          name="description"
          content="Login into skills uprise for placement guaranteed training | online training | offline training | pre-recorded courses | online courses | offline courses"
        />
      </Helmet>

      <div className="toast-container">
        <div className="mb-2 col-lg-3 col-md-3 col-sm-4 col-10">
          <Toast
            show={showA}
            onClose={toggleShowA}
            delay={4000}
            autohide
            className="space-between bg-warning"
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">{ToastTitle}</strong>
            </Toast.Header>
            <Toast.Body>{ToastMessage}</Toast.Body>
          </Toast>
        </div>
      </div>

      <div className="w-100 min-vh-100 p-0">
        <div className="flex-wrap align-items-center w-100 p-0 bg-white  d-flex min-vh-100">
          <div className="col-lg-9 col-md-10 col-xl-8 col-sm-10 shadow border-none md-border sm-shadow-none h-fit p-3 rounded bg-white d-flex mx-auto">
            {/*right holder*/}
            <div className="col-lg-6 login-bg   d-none d-sm-none d-md-none d-lg-flex align-items-center justify-content-center  bg-login">
              <AuthFeatures
                className="mx-auto"
              />
            </div>

            {/*right holder*/}

            {/*login form holder*/}
            <div className=" p-0 col-lg-6 col-md-12 col-12  d-flex bg-white align-items-center">
              {loginerror ? (
                loginerror === "limit exceeded" ? (
                  <LogoutAllNotification usercredentials={usercredentials} />
                ) : loginerror === "wrong credentials" ? (
                  ""
                ) : loginerror === "session error" ? (
                  <div className="error">
                    <h5>Error !</h5>
                    <p>Something went wrong</p>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <div className=" bg-white br-5 col-12 mb-5 p-3 br-4 ">
                <div className="my-3">
                  <img className="w-75 d-none" src={textlogo} />

                  <div className="d-flex">
                    <div>
                      <p>Hi !</p>
                      <h3>Welcome to Skills Uprise</h3>
                    </div>
                  </div>
                </div>

                <button onClick={()=>InitiateGoogleLogin()} className="btn border border-1 rounded rounded-2 w-100">
                  {" "}
                  <img className="auth-google-logo" src={googlelogo}></img>{" "}
                  Login with Google
                </button>

                <div className="d-flex my-2 align-items-center justify-content-center w-100">
                  <hr className="bg-secondary"></hr>
                  <p>or</p>
                  <hr className="bg-secondary"></hr>
                </div>

                <p className="text-danger">{msg ? msg : ""}</p>
                <Form
                  className="mt-3 w-100"
                  autoComplete="off"
                  onSubmit={loginaction}
                >
                  <TextField
                    className="sinput w-100 "
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                    label="Email"
                    type="email"
                    variant="outlined"
                    minLength={10}
                    maxLength={60}
                    required
                  />

                  <Form.Text className="text-muted"></Form.Text>

                  <TextField
                    id="password"
                    className="sinput w-100 mt-3"
                    label="Password"
                    type="password"
                    variant="outlined"
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    minLength={6}
                    maxLength={60}
                    required
                  />

                  <div className="d-flex mb-3 mt-3 justify-content-between align-items-center sm-14">
                    <div className="d-flex align-items-center">
                      <Checkbox
                        className="w-fit"
                        name="Remember password"
                        defaultChecked
                      />
                      <p className="mb-0">Remember password</p>
                    </div>
                    <Link className="text-center sm-16" to="/password-reset">
                      Forgot Password ?
                    </Link>
                  </div>
                  {isFetching ? (
                    <>
                      <Button
                        className="w-100 mb-3"
                        disabled
                        variant="primary"
                        type="submit"
                      >
                        Signing in...
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="w-100 mb-3 rounded-pill"
                        variant="primary"
                        type="submit"
                      >
                        Login
                      </Button>
                    </>
                  )}
                </Form>

                <div className="d-none">
                  <hr></hr>
                  <div className="d-flex d-none justify-content-center">
                    <button className="btn btn-primary d-flex">
                      {" "}
                      <div className="bg-white p-2">
                        <img src="" className=""></img>
                      </div>{" "}
                      Sign in with Google
                    </button>
                  </div>
                </div>

                <hr></hr>

                <div className="text-center  pb-2">
                  <Link
                    to="/signup"
                    className="btn border border-2 bg-theme-green text-light rounded rounded-pill col-10"
                  >
                   
                    <b className="text-light">Create New Account</b>
                  </Link>
                </div>

                {/*copy rights*/}
              </div>
            </div>
            {/*login form holder*/}
          </div>
        </div>
      </div>

      {/*main container end*/}
    </LoginStyled>
  );
};

export default Login;

export const LoginStyled = styled.div`
  .check {
    height: 15px;
    width: 15px;
  }
`;
