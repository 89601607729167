import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'
import FetchFingerprint from './FetchFingerprint'

const GetPersonalRewards = async ({limit,user})=>{

const data = new FormData()
if(limit){
     data.append("limit",limit)
}
data.append('session',user.SessionId)
        data.append("fp",FetchFingerprint())
const res = await 
axios.post(ENDPOINT+`get-badges.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}

export default GetPersonalRewards