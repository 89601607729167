import CloseIcon from '@mui/icons-material/Close';
import {Col,Button,Dropdown} from 'react-bootstrap';
import Modal from '@mui/material/Modal';
import {useState,useEffect, useContext} from 'react';
import axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Toast from 'react-bootstrap/Toast'
import { AuthContext } from '../../../context/AuthContext';
import MONTHS from '../../../context/Months';
import TextField from '@mui/material/TextField';
import Cities from '../../../context/Cities';
import Months from '../../../context/Months';
import Years from '../../../context/Years';
import Checkbox from '@mui/material/Checkbox';
import { Avatar } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import FetchFingerprint from '../../../actions/FetchFingerprint';
const CertificationsList=()=>{
const params = useParams()
  const username = params.username;
  const {user} = useContext(AuthContext)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [title,settitle]=useState(null);
    const [description,setdescription]=useState(null);
  
    const [privacy,setprivacy]=useState(null);
    const [education,seteducation]=useState(null);
const [self,setself] = useState(false)
    const SaveEducation= async(e)=>{
        e.preventDefault()
        const formData= new FormData();
        formData.append("username",username)
        formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
        formData.append('college',title);
        formData.append('degree',description);
        formData.append('start_month',startmonth);
        formData.append('end_month',endmonth);
        formData.append('start_year',startyear);
        formData.append('end_year',endyear);
        formData.append('privacy',privacy);
    formData.append('field_of_study',studyfield)
    formData.append('degree',description)
        const res = await axios.post('https://skillsuprise.com/user-certification-add.php',formData,{
           headers:{'content-type':'multipart/form-data'}
        })
       
        if(res){
          // //  //  //console.log("added "+JSON.stringify(res));
            if(res.data.status==="success"){
              settoasttitle("Certification added successfully")
              settoast(true)
              Fetch_education()
               setaddeducation(false)
           ClearData()
            }
            else{
                
            }
           // //  //  //console.log(JSON.stringify(res))
        }
    }
   

    const Fetch_education = async () => {
        const formdata = new FormData();
        formdata.append('session',user.SessionId)
        formdata.append("fp",FetchFingerprint());
        formdata.append("username",username)
        const res = await axios.post(
          "https://skillsuprise.com/user-certifications.php",
          formdata,
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );
        if (res) {
          //  //console.log("certifications "+JSON.stringify(res))
          if (res.data.status === "success") {
            if(res.data.data!==null || res.data.data!=="null"){
              seteducation(res.data.data);
              setself(res.data.self)
            }
        
          }
        }
      };

const [editid,seteditid] = useState(null)
      const Edit = async ({id,action}) => {
        const formdata = new FormData();
        formdata.append('session',user.SessionId)
        formdata.append("fp",FetchFingerprint());
        formdata.append('id',id);
        if(action==="get"){
          formdata.append('get',true);
        }else{
          formdata.append('college',title);
          formdata.append('degree',description);
          formdata.append('start_month',startmonth);
          formdata.append('end_month',endmonth);
          formdata.append('start_year',startyear);
          formdata.append('end_year',endyear);
          formdata.append('privacy',privacy);
      formdata.append('field_of_study',studyfield)
      formdata.append('degree',description)
        }
        const res = await axios.post(
          "https://skillsuprise.com/user-certification-edit.php",
          formdata,
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );
        if (res) {
           // //  //  //console.log("edit "+JSON.stringify(res))
          if (res.data.status === "success") {
           
            if(res.data.data===null){
              settoasttitle("Edit successful")
              settoast(true)
             setedit(false)
             ClearData()
             Fetch_education()
            }
            else{
              if(action==="get"){
               
                setedit(true)
               
              }
              const data = res.data.data[0];
              settitle(data.college);
              setdescription(data.degree)
              setstudyfield(data.field_of_study)
              setendyear(data.end_year)
              setendmonth(data.end_month)
              setstartyear(data.start_year)
              setstartmonth(data.start_month)
              setprivacy(data.privacy)
             seteditid(data.id)
            
            }
        
          }
        }
      };







      useEffect(() => {
        Fetch_education();
      },[]);
      const Delete = async ({education_id}) =>{
          const data = new FormData();
          data.append('id',education_id);
          data.append('session',user.SessionId)
        data.append("fp",FetchFingerprint())
          const res = await axios.post(
            "https://skillsuprise.com/user-certification-delete.php",
            data,
            {
              headers: { "content-type": "multipart/form-data" },
            }
          );
          if (res) {
           //   //  //  //console.log(JSON.stringify(res))
            if (res.data.status === "success") {
              settoasttitle("Deleted successfully")
              settoast(true);
          
             Fetch_education();
             
            }
          }
      }

      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    
        bgcolor: 'background.paper',
      
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };
       
const [addeducation,setaddeducation] = useState(false)
const defaultProps = {
  options: MONTHS,
  getOptionLabel: (option) => option.name,
};

const [startmonth,setstartmonth] = useState(null)
const [startyear,setstartyear] = useState(null);
const [endyear,setendyear] = useState(null)
const [endmonth,setendmonth] = useState(null)
const [studyfield,setstudyfield] = useState(null)
const [city,setcity] = useState(null)
const [presentstatus,setpresentstatus] = useState(false)
const [edit,setedit] = useState(false)



const [toast,settoast] = useState(false)

const [toasttitle,settoasttitle] = useState(null);
const [toastmessage,settoastmessage] = useState(null)

const ClearData = ()=>{
  settitle(null);
  setdescription(null);
  setstudyfield(null);
  setendyear(null);
  setendmonth(null);
  setstartyear(null);
  setstartmonth(null);
}
    return(

      <>





<div className="toast-container">


<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
           
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>














            <Modal
        open={edit}
        onClose={()=>setedit(false)}

      >
        <Box className="col-lg-4 rounded col-md-5 col-sm-5 col-11 p-3" sx={style}>

          <div className="w-100">
            <div className="flex align-items-center space-between">

              <h4 className="pb-0">{self?"Add ":null}License/Certification</h4>
              <CloseIcon type="button" onClick={()=>{setedit(false)}}/>
            </div>
            <hr/>
          <form method="post" onSubmit={(e)=>{e.preventDefault();Edit({id:editid,action:"edit"})}}>
  
                <input placeholder="Title" className="  form-control me-3" type="text" value={title} onChange={(e)=>{settitle(e.target.value)}} name="title" required></input><br/>
               
                <input
                placeholder="Certificate URL"
                className=" form-control me-3" type="text"value={description} onChange={(e)=>{setdescription(e.target.value);}} name="description" required></input><br/>
                
                
                <label className="mt-1 me-3">Issued Organization</label>
                <input
                placeholder=""
                 className=" form-control me-3" type="text" value={studyfield} onChange={(e)=>{setstudyfield(e.target.value);}} name="description" required></input><br/>
                <label className="mt-1 me-3">Issued Date</label>
                <div className="flex w-100">
<div className="col pl-0">
<Autocomplete
      disablePortal
      id="combo-box-demo"
      options={Months}
  disableClearable
      value={startmonth}
      onChange={(event, newValue) => {
        setstartmonth(newValue);
      }}
      inputValue={startmonth}
      onInputChange={(event, newInputValue) => {
        setstartmonth(newInputValue);
      }}

      renderInput={(params) => 
      <TextField {...params} label="Month" />}
    />
        

           
</div>

<div className="col pr-0">
<Autocomplete
      disablePortal
      id="combo-box-demo"
      options={Years}
  disableClearable
      value={startyear}
      onChange={(event, newValue) => {
        setstartyear(newValue);
      }}
      inputValue={startyear}
      onInputChange={(event, newInputValue) => {
        setstartyear(newInputValue);
      }}

      renderInput={(params) => 
      <TextField {...params} label="Year" />}
    />
        

           
</div>

                </div>












<div className="flex align-items-center mt-1 mb-1">
<Checkbox onClick={()=>setpresentstatus(!presentstatus)} value={presentstatus} />
<p className="mb-0">This certificate doesn't expire</p>
</div>


           {presentstatus?
           <>
              
           </>:
           <>
             <label className="mt-1 me-3">Expiry Date</label>
                <div className="flex w-100">
<div className="col pl-0">
<Autocomplete
      disablePortal
    
      options={Months}
  disableClearable
      value={endmonth}
      onChange={(event, newValue) => {
        setendmonth(newValue);
      }}
      inputValue={endmonth}
      onInputChange={(event, newInputValue) => {
        setendmonth(newInputValue);
      }}

      renderInput={(params) => 
      <TextField {...params} label="Month" />}
    />
        

           
</div>

<div className="col pr-0">
<Autocomplete
      disablePortal
      id="combo-box-demo"
      options={Years}
  disableClearable
      value={endyear}
      onChange={(event, newValue) => {
        setendyear(newValue);
      }}
      inputValue={endyear}
      onInputChange={(event, newInputValue) => {
        setendyear(newInputValue);
      }}

      renderInput={(params) => 
      <TextField {...params} label="Year" />}
    />
        

           
</div>

                </div>
           </>}
               
        

        
                <Button variant="primary w-100 mt-2"  type="submit">Save Changes</Button>
              
                
                
            </form>
            

          </div>
        
        </Box>
      </Modal>



      <Modal
        open={addeducation}
        onClose={()=>setaddeducation(false)}

      >
        <Box className="col-lg-4 col-md-5 col-sm-5 col-11 p-3"  sx={style}>

        
        <div className="w-100">
            <div className="flex align-items-center space-between">

              <h4>{self?"Add ":null}License/Certification</h4>
              {self?
              <>
              <CloseIcon type="button" onClick={()=>{setaddeducation(false)}}/>
    
              </>:null}
                      </div>
            <hr/>
          <form method="post" onSubmit={SaveEducation}>
                <label className="mt-1 w-100 me-3">Title</label>
                <input className="  form-control me-3" type="text" value={title} onChange={(e)=>{settitle(e.target.value)}} name="title" minLength={5} maxLength={200} required></input><br/>
                <label className="mt-1 me-3">Certificate URL</label>
                <input
                placeholder=""
                className=" form-control me-3" type="text"value={description} onChange={(e)=>{setdescription(e.target.value);}} name="description" minLength={5} maxLength={300} required></input><br/>
                
                
                <label className="mt-1 me-3">Issued Organization</label>
                <input
                placeholder=""
                 className=" form-control me-3" type="text" value={studyfield} onChange={(e)=>{setstudyfield(e.target.value);}} name="description" minLength={3} maxLength={200} required ></input><br/>
                <label className="mt-1 me-3">Issued Date</label>
               
                <div className="flex w-100">
<div className="col pl-0">
<Autocomplete
      disablePortal
      id="combo-box-demo"
      options={Months}
  disableClearable
      value={startmonth}
      onChange={(event, newValue) => {
        setstartmonth(newValue);
      }}
      inputValue={startmonth}
      onInputChange={(event, newInputValue) => {
        setstartmonth(newInputValue);
      }}

      renderInput={(params) => 
      <TextField {...params} label="Month" required/>}
    />
        

           
</div>

<div className="col pr-0">
<Autocomplete
      disablePortal
      id="combo-box-demo"
      options={Years}
  disableClearable
      value={startyear}
      onChange={(event, newValue) => {
        setstartyear(newValue);
      }}
      inputValue={startyear}
      onInputChange={(event, newInputValue) => {
        setstartyear(newInputValue);
      }}

      renderInput={(params) => 
      <TextField {...params} label="Year" required />}
    />
        

           
</div>

                </div>












<div className="flex align-items-center mt-1 mb-1">
<Checkbox onClick={()=>setpresentstatus(!presentstatus)} value={presentstatus} />
<p className="mb-0">This certificate doesn't expire</p>
</div>


           {presentstatus?
           <>
              
           </>:
           <>
             <label className="mt-1 me-3">Expiry Date</label>
                <div className="flex w-100">
<div className="col pl-0">
<Autocomplete
      disablePortal
    
      options={Months}
  disableClearable
      value={endmonth}
      onChange={(event, newValue) => {
        setendmonth(newValue);
      }}
      inputValue={endmonth}
      onInputChange={(event, newInputValue) => {
        setendmonth(newInputValue);
      }}

      renderInput={(params) => 
      <TextField {...params} label="Month" />}
    />
        

           
</div>

<div className="col pr-0">
<Autocomplete
      disablePortal
      id="combo-box-demo"
      options={Years}
  disableClearable
      value={endyear}
      onChange={(event, newValue) => {
        setendyear(newValue);
      }}
      inputValue={endyear}
      onInputChange={(event, newInputValue) => {
        setendyear(newInputValue);
      }}

      renderInput={(params) => 
      <TextField {...params} label="Year" />}
    /></div>

                </div>
           </>}
               
        
               
        

        
                <Button variant="primary w-100 mt-2"  type="submit">Save Changes</Button>
              
                
                
            </form>
            

          </div>
        
        </Box>
      </Modal>






































{education?
<>

<div className="shadow sm-shadow-none p-3">



  



<div className="flex space-between align-items-center">
<h4 className="mb-3 pt-2">Licences/Certifications</h4>
{self?
<>
<AddIcon type="button" onClick={()=>setaddeducation(true)}/>
</>:null}

</div>

        


           
            <Col lg={12} md={12} sm={12} xs={12} className=" rounded p-3 ">
            {education?
                        <>
                        {education.map(edu=>(
                        <>
                       <div className="flex space-between mt-3 mb-3">
<div className="flex">
<Avatar className="mr-2" alt={edu.college} src=""/>
<div>
  <div className="flex space-between">
  <h5>{edu.college}</h5>

  </div>
  
  <p className="mb-1">{edu.field_of_study}</p>
<p className="mb-0 text-secondary"><b>Validity : </b>{edu.start_month},{edu.start_year}  - {edu.end_month?edu.end_month+", "+edu.end_year:"Lifetime"}</p>
</div>
</div>

<div className="flex">
  
  <Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu> 

<Dropdown.Item onClick={()=>Edit({id:edu.id,action:"get"})}>Edit</Dropdown.Item>
  <Dropdown.Item  onClick={()=>Delete({education_id:edu.id})} >Delete</Dropdown.Item>



  
  </Dropdown.Menu>
</Dropdown>
</div>
</div>

                        </>
                        ))}
                        </>
                        :<>
                      
                     {self?
                     <>
                      <div onClick={()=>setaddeducation(true)} type="button"  className="flex text-primary align-items-center">
<AddIcon className="mr-2"/>
<h6 className="mb-0">Add License/Certification</h6>

                        </div>
                     </>:null}
                      
                        </>}
                
            </Col>
      

        </div>

</>:
<>

<div className="border  p-3 border br-5">
<div className=" p-3 sm-shadow-none">
{education?
<>
<div className="flex space-between align-items-center">
<h4 className="mb-3 pt-2">Licences/Certifications</h4>
{self?
<>
<AddIcon type="button" onClick={()=>setaddeducation(true)}/>
</>:null}

</div>
</>:
<>

</>}

{education?
<>
</>:
<>


{self?
<>
<div className="container  empty-profile-section sm-br-none   text-center">
    <h4  onClick={()=>setaddeducation(true)} className="btn btn-outline-secondary rounded-pill">Add Certificate</h4>
  </div>
</>:
<>
<div className="text-center">
    <h5 className="text-secondary">No Certifications Added</h5>
  </div>

</>}

</>
}

</div>

</div>




</>}









      </>
   
    );
}
export default CertificationsList;