import React, { useContext, useEffect, useState } from 'react'
import Switch from '@mui/material/Switch';

import Alert from 'react-bootstrap/Alert'
import preferencesicon from '../../assets/preferences.svg'
import { styled } from '@mui/material/styles';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import generalsettingsicon from '../../assets/general-settings-icon.svg'
import ENDPOINT from '../../data/ENDPOINT'
import { Toast } from 'react-bootstrap';
import LoadingTemplate from '../temaplates/LoadingTemplate'
import { ArrowDownward } from '@material-ui/icons';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import GetIntro from '../../actions/intros/GetIntro';
import { Helmet } from 'react-helmet';
import FetchFingerprint from '../../actions/FetchFingerprint';
const NotificationSettings = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
    
    },[])
const loginnotif = useRef()
const {user} = useContext(AuthContext)

const [key,setkey] = useState(null);
const [value,setvalue] = useState(false)

const [status,setstatus]= useState(false);
const [toast,settoast] = useState(false)


const [preferences,setpreferences] = useState(null)

const updatePreferences = async ({key2,val})=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
  
    formData.append('key',key2);
     if(val===false){
      formData.append('value',0);
     // //  //  //console.log("key is"+key2+" val "+0)

    }else{

      formData.append('value',1);
    //  //  //  //console.log("key is"+key2+" val "+1)


    }

  const res = await axios.post(ENDPOINT+"update-preferences.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
////  //  //console.log(" data "+JSON.stringify(res))
   if(res.data.status==="success"){
     setstatus(true)
    settoast(true)
    getPreferences()
   }
   else{
     settoast(false)
  settoast(true)
  getPreferences()
   }
  }
  }
  




const [pref,setpref] = useState(null)


const [account_login,setaccount_login] = useState(0)

//GET PREFERENCES
const getPreferences = async ()=>{
        

  const formData = new FormData();
  
  
 
  formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
  


  const res = await axios.post(ENDPOINT+"get-preferences.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){

 
   if(res.data.status==="success"&&res.data.data!==null){

     //  //console.log("preferences "+JSON.stringify(res.data.data))
     setpreferences(res.data.data)
     const data = res.data.data;
     setaccount_login(data.account_login)
     setpref(res.data.data)
   //  //  //  //console.log("data is "+JSON.stringify(res.data))
    
     setstatus(true)
 
   // //  //  //console.log("server stat "+res.data.data.upcoming_classes)
   }
   else if(res.data.status==="success"&&res.data.data===null){
    setpreferences(res.data.data)

    
   }
   else{
     //error
   }
  }
  }


useEffect(()=>{
getPreferences()

},[])


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
          marginleft:10,
          margintop:5
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));
      


const [othersettings,setothersettings] = useState(false)
const [coursesettings,setcoursesettings] = useState(false)
const [workshopsettings,setworkshopsettings] = useState(false)
const [internshipsettings,setinternshipsettings] = useState(false)
const [othernotifications,setothernotifications] = useState(false)
const [connectionsettings,setconnectionsettings] = useState(false)
const [whatsappsettings,setwhatsappsettings] = useState(false)

const CheckBeginner = async({payload})=>{
  const res = await GetIntro({payload})
  if(res){
        //  //console.log("beginner "+JSON.stringify(res))
       if(res.status==="success"){
            setbeginner(res.beginner)
       }}}
       const [beginner,setbeginner] = useState(false)
    return (
     
<>
<Helmet>‍
        <title>Notification Settings - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
{preferences?
<>

<div className="container-xl min-vh-100 mx-auto p-2">


<div className="position-fixed bottom-60 left-0 container-fluid justify-center">


<Toast className={status?"bg-warning":"bg-warning"} onClose={() => settoast(false)} show={toast} delay={3000} autohide>
         
         
         
       

          <Toast.Body>{status?"Changes Saved Successfully !":"Something went wrong !"}</Toast.Body>
          </Toast>
</div>


<div className="flex-wrap mb-5 pt-3 ">








<div className="col-lg-7 col-md-7 col-sm-12 col-12 mt-0">

















<div className="mb-4 left-red-border">
  
<h4 className="sm-22 mb-0">Take more control over your privacy</h4>
<p>Decide when and what information to receive from us</p>
{beginner?
<>

<Alert  variant="info">
 
   All the notifications are enabled by default. You can change each category of notifications for customised alerts and notifications. 
  </Alert>
</>:
<>

</>}

</div>












<div className="setting-section click   p-3 w-100">

<div onClick={()=>{setcoursesettings(!coursesettings)}}  className="d-flex justify-content-between">
<h5>Course Notification Settings</h5>

{coursesettings?
<>
<UpIcon className="click"  onClick={()=>setcoursesettings(!coursesettings)}/>

</>:
<>
<DownIcon className="click"  onClick={()=>setcoursesettings(!coursesettings)}/>


</>}

</div>

{coursesettings?
<>
<hr/>
<div >
<div className="flex space-between align-items-center ">
    <div>
<p  className="mb-0">Notify me when someone shares a knowledgebase.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.course_knowledgebase==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"course_knowledgebase",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when someone shares a post.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.course_post==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"course_post",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when trainer contacts me.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.trainer_contact==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"trainer_contact",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my classes are scheduled.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.class_schedule==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"class_schedule",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>






<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when an assignment is added.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.assignment_added==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"assignment_added",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me after my assignment is evaluated.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.assignment_evaluated==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"assignment_evaluated",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>




<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when a project is assigned to me.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.project_added==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"project_added",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my project is evaluated.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.project_evaluated==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"project_evaluated",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when a reward is gifted to me.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.reward_gifted==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"reward_gifted",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my rank changes.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.rank_changes==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"rank_changes",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me about my exam updates / schedules.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.exam_updates==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"exam_updates",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me on any updates regarding placement support.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.placement_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"placement_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my course journey progress changes.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.journey_progress==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"journey_progress",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



</div>

</>:
<>
</>}







</div>


{/*section ends*/}




















{/*section starts*/}




<div  className="setting-section click  mt-3 p-3 w-100">

<div onClick={()=>{setinternshipsettings(!internshipsettings)}} className="d-flex justify-content-between">
<h5>Internship Notification Settings</h5>

{internshipsettings?
<>
<UpIcon className="click"  onClick={()=>setinternshipsettings(!internshipsettings)}/>

</>:
<>
<DownIcon className="click"  onClick={()=>setinternshipsettings(!internshipsettings)}/>


</>}


</div>
{internshipsettings?
<>
<hr/>
<div className="flex space-between align-items-center ">
    <div>
<p  className="mb-0">Notify me when my application is shortlisted/rejected.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_application_updates==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_application_updates",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>








<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my team lead tries to communicate me.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_lead_contact==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_lead_contact",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>






<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when an interview is scheduled.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.interview_updates==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"interview_updates",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my team shares a knowledgebase.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_knowledgebase==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_knowledgebase",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my team shares a post.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_post==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_post",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>






<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when an task is added.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_task==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_task",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when a meeting is scheduled.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_meetings==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_meetings",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>




<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me about my leave updates.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_leaves==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_leaves",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>






<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when a reward is gifted to me.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_rewards==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_rewards",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my rank changes.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_rank==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_rank",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me about my certification .
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_certification==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_certification",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me on any updates regarding my stipend.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_stipend==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_stipend",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>







</>:
<>

</>}







</div>


{/*section ends*/}





















{/*section starts*/}




<div className="setting-section click  mt-3 p-2 w-100">
<div  onClick={()=>{setworkshopsettings(!workshopsettings)}} className="d-flex justify-content-between">
<h5>Workshop Notification Settings</h5>

{workshopsettings?
<>
<UpIcon className="click"  onClick={()=>setworkshopsettings(!workshopsettings)}/>

</>:
<>
<DownIcon className="click"  onClick={()=>setworkshopsettings(!workshopsettings)}/>


</>}




</div>

<div className="w-100">


{workshopsettings?
<>
<hr/>
<div className="flex space-between align-items-center ">
    <div>
<p  className="mb-0">Notify me when a new workshop is added.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.workshop_added==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"workshop_added",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>








<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when workshop starts.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.workshop_starts==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"workshop_starts",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>






<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when workshops are rescheduled.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.workshop_rescheduled==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"workshop_rescheduled",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when certificate is issued.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.workshop_certificate==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"workshop_certificate",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>













</>:
<>


</>}
</div>





</div>


{/*section ends*/}















{/*section starts*/}




<div  className="setting-section click  mt-3 p-3 w-100">

<div onClick={()=>{setconnectionsettings(!connectionsettings)}} className="d-flex justify-content-between">
<h5>Connections Notifications</h5>
{connectionsettings?
<>
<UpIcon className="click"  onClick={()=>setconnectionsettings(!connectionsettings)}/>

</>:
<>
<DownIcon className="click"  onClick={()=>setconnectionsettings(!connectionsettings)}/>


</>}

</div>


<div>
{connectionsettings?
<>
<hr/>
<div className="flex space-between align-items-center ">
    <div>
<p  className="mb-0">Notify me when i receive a connection request.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.connection_request==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"connection_request",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>








<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my connections shares a post.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.connection_post==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"connection_post",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>






<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my connections comments on any post.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.connection_comments==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"connection_comments",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notify me when my connections like any post.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.connection_likes==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"connection_likes",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>








</>:
<>

</>}

</div>











</div>


{/*section ends*/}












{/*section starts*/}




<div  className="setting-section click  mt-3 p-2 w-100">

<div onClick={()=>{setothersettings(!othersettings)}} className="d-flex justify-content-between">
<h5>Other Notifications</h5>
{othersettings?
<>
<UpIcon className="click"  onClick={()=>setothersettings(!othersettings)}/>

</>:
<>
<DownIcon className="click"  onClick={()=>setothersettings(!othersettings)}/>


</>}

</div>



<div>
{othersettings?
<>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Send notifications about important updates via email/sms/WhatsApp when i'm offline.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.offline_priority==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"offline_priority",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center ">
    <div>
<p  className="mb-0">Send important notifications through WhatsApp.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.whatsapp_notification==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"whatsapp_notification",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>




<div className="flex space-between align-items-center ">
    <div>
<p  className="mb-0">Send important notifications through email.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.email_notification==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"email_notification",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p  className="mb-0">Send important notifications through sms.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.sms_notification==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"sms_notification",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>













<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Send notifications when a new tutorial is added.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.tutorial_added==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"tutorial_added",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


































</>:
<>

</>}

</div>




</div>


{/*section ends*/}





















































</div>

<div className="col-lg-5 col-md-5 col-sm-12 col-12 pt-5 justify-center">
    <div className="w-50 mx-auto text-center">
    <img src={preferencesicon} className="w-100 mb-3" alt=""></img>
    <p>If you'd like to learn more about privacy , you can read about it in the <Link to="/privacy-policy">Privacy Policy</Link></p>
    </div>
</div>



</div>




</div>

</>:

<>

<div className="container">
<div className="col-lg-8  col-md-8 col-sm-12 col-12">

<LoadingTemplate/>
</div>

</div>

</>

}


</>

    
    )
}

export default NotificationSettings


