import React from 'react'
import axios from 'axios';
import { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import ENDPOINT from '../../../data/ENDPOINT';
import FetchFingerprint from '../../../actions/FetchFingerprint';
const  ProjectSubmit = ({id})=>{
const params = useParams()
const courseurl = params.course_url;
const batchid = params.batch_id
const [file,setfile] = useState(null)
const [status,setstatus]= useState(false)
 const   UPLOAD_ENDPOINT = `${ENDPOINT}upload-project.php`;
   
   const onSubmit = async (e)=> {
       setstatus(true)
        e.preventDefault() 
        let res = await uploadFile(file);
      
        

        if(res.data.status==="success"){

        
          window.location.reload()
        }else{
          setstatus(false)
         
   
        }
    }
  const  onChange = (e)=> {
        setfile(e.target.files[0])

        
    }
   const uploadFile = async (file)=>{
        

        const formData = new FormData();
        const user = JSON.parse(localStorage.getItem("user"));
   
        formData.append('resume',file);
        formData.append('id',id);
        formData.append('batch_id',batchid)

        formData.append('course_url',courseurl)
     
        formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
    
        return  await axios.post(UPLOAD_ENDPOINT, formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
      }
    


    return(

<>

<ApplyInternshipStyled>

<form onSubmit={(e)=>onSubmit(e)} className="position-relative p-3">
          
<h4>Note:</h4>
<p>Submit the project as a .zip file by keeping all the resources (images,vdeos,pdf,documents etc) inside the zip file.</p>



   <input type="file" className="mt-3"  accept=".zip" onChange={onChange} required/>
       <br/>
       
            <button className="btn btn-primary w-100 mt-5" type="submit">{status?<CircularProgress className="text-white " size={20}/>:""}Upload Task</button>
          
          
         
          </form>



          </ApplyInternshipStyled>
</>

    )
} 

   

export default ProjectSubmit;

const ApplyInternshipStyled = styled.div`

input, textarea{
  border: 1px solid lightgrey;
  border-radius:5px;
  margin-bottom: 10px;
}

`