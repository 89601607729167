import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetCourses = async ({payload})=>{

const data = new FormData()

data.append("mode",payload.mode)
if(payload.limit){
     data.append("limit",payload.limit)
}
if(payload.device){

     data.append("device",payload.device)
}
const res = await 
axios.post(ENDPOINT+`get-courses.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}
export default GetCourses