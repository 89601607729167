import { Box, CircularProgress, Typography } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import GetCourseQuiz from "../../actions/quiz/GetCourseQuiz";
import SubmitCourseQuiz from "../../actions/quiz/SubmitCourseQuiz";
import { AuthContext } from "../../context/AuthContext";
import ENDPOINT from "../../data/ENDPOINT";
import SecurityHeaders from "../../data/SecurityHeaders";
import Examportal from "../exam/ExamPortal";
import { Helmet } from "react-helmet";
import Alert from "@mui/material/Alert";
import { Button, Modal } from "react-bootstrap";
const MockTest = ({attemptedque,setattemptedque,markedque,setmarkedque}) => {
  const [beginner, setbeginner] = useState(true);

  const { user } = useContext(AuthContext);
  const params = useParams();
  const test_type = params.text_type;
  const test_code = params.test_code;
  const course_url = params.course_url;
  const [quiz, setquiz] = useState(null);
  const [questionid, setquestionid] = useState(null);
  const [reloading,setreloading] = useState(false)
  const NewQuiz = async ({ quiz_id }) => {
    setloading(true);

    const res = await GetCourseQuiz({
      payload: {
        user: user.SessionId,
        test_code: test_code,
        course_url,
        course_url,
        quiz_id: quiz_id,
      },
    });
    if (res) {
      setloading(false);
      setreloading(false);
      if (res.status === "success") {
        setquiz(res.quiz);
        setquestions(res.questions);

        HighlightQuestion({ question_id: res.quiz.quiz_id });
        setattempts(res.attempts);
        window.scrollTo(0, 0);
      } else {
        setquiz(null);
      }
      //  //console.log("quiz is "+JSON.stringify(res))
    }
  };
  const [questions, setquestions] = useState(null);
  const [attempts, setattempts] = useState(null);
  const SubmitAnswer = async ({ option }) => {
    const res = await SubmitCourseQuiz({
      payload: {
        option: option,
        user: user.SessionId,
        quiz_id: quiz.quiz_id,
      },
    });

    if (res) {
      if (res.status === "success") {
        const current_index = questions.indexOf(quiz.quiz_id);

        NewQuiz({ quiz_id: questions[current_index + 1] });
        setattemptedque(attemptedque+1);
        console.log(attemptedque)
        setquestionid(null);
      }
    }
  };

  const HighlightQuestion = ({ question_id }) => {
    const button = document.getElementById(question_id);
    if (button) {
      button.style.backgroundColor = "#000000";
    }
  };

  const [counter, setcounter] = useState(0);
  const date = new Date();

  const [loading, setloading] = useState(false);

  const NextQuestion = () => {
    const current_index = questions.indexOf(quiz.quiz_id);

    //console.log("quiz id "+questions.indexOf(quiz.quiz_id))
    //console.log("quiz id 2"+questions[current_index+1])
    NewQuiz({ quiz_id: questions[current_index + 1] });
    setquestionid(null);
  };
  const PreviousQuestion = () => {
    const current_index = questions.indexOf(quiz.quiz_id);

    //console.log("quiz id "+questions.indexOf(quiz.quiz_id))
    //console.log("quiz id 2"+questions[current_index-1])
    NewQuiz({ quiz_id: questions[current_index - 1] });
    setquestionid(null);
  };

  const LoadQuestion = ({ quiz_id }) => {
    NewQuiz({ quiz_id: quiz_id });
  };

  useEffect(() => {
    CheckTest();
  }, []);

  const StartTest = async () => {
    setreloading(true)
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("test_id", test_code);
    data.append("course_url", course_url);
    const res = await axios.post(
      ENDPOINT + "start-test.php",
      data,
      SecurityHeaders,
    );
    if (res) {
      if (res.data.status === "success") {
        if (res.data.exam_status === "unattempted") {
          NewQuiz({ quiz_id: null });
        } else {
        }
      }
    }
  };

  const [examstatus, setexamstatus] = useState("unattempted");

  const [attempted, setattempted] = useState(false);
  const CheckTest = async () => {
    const data = new FormData();
    if (user) {
      data.append("session", user.SessionId);
    }
    data.append("test_id", test_code);
    const res = await axios.post(
      ENDPOINT + "check-test-attempt.php",
      data,
      SecurityHeaders,
    );
    if (res) {
      setchecking(false);
      if (res.data.status === "success") {
        setattempted(res.data.data.attempted);
        console.log(attemptedque)
      }
    }
  };
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  useEffect(() => {
    // user leaves the tab
    document.addEventListener("blur", (e) => {
      // your custom code here
      //console.log("unloaded")
    });
    // user enters the tab (again)
    document.addEventListener("focus", (e) => {
      // your custom code here
      //console.log("loaded")
    });
  }, []);

  const MarkQuestion = () =>{
    setmarkedque(markedque+1)
  }

  const handleSubmitTest = () => {
    setSubmitModalOpen(false)
    window.location.reload()
  };
  const [checking, setchecking] = useState(true);
  return (
    <div className="min-vh-100 ">
      <Helmet>
        ‍<title>Mock Tests - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />
        <meta name="twitter:card" content="" />
        <meta name="twitter:site" content="@skillsuprise" />
        <meta name="twitter:creator" content="@skillsuprise" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
      {reloading ? (
        <>
          <div className="justify-content-center min-vh-50 w-100 d-flex mt-5 pt-5 mb-4">
            <CircularProgress />
          </div>
        </>
       ):(
      <div className="w-100 container vh-90 quiz-container pt-3 pb-3">
        {checking ? (
          <>
            <Skeleton className="col-lg-8 mt-2" height={60} />

            <Skeleton className="col-lg-8 mt-2" height={60} />

            <Skeleton className="col-lg-8 mt-2" height={60} />

            <Skeleton className="col-lg-8 mt-2" height={60} />

            <Skeleton className="col-lg-8 mt-2" height={60} />

            <Skeleton className="col-lg-8 mt-2" height={60} />
          </>
        ) : (
          <>
            {quiz ? (
              <>
                <div className=" d-flex flex-wrap justify-content-between">
                  {examstatus === "unattempted" ? (
                    <>
                      <div className="col-lg-7 min-vh-50 border br-5 p-3 sm-p-10">
                        <h2>Test Information</h2>

                        {quiz ? (
                          <>
                            <div className="d-flex mb-4 mt-3 flex-wrap col-lg-7 col-md-8 col-sm-12 col-12 justify-content-between">
                              <p>
                                <b>Level : </b>1
                              </p>
                              <p>
                                <b>Total Questions : </b>
                                {questions ? questions.length : 0}
                              </p>
                              <p>
                                <b>Duration : </b>1
                              </p>

                              <p>
                                <b>Time Left : </b>1
                              </p>
                            </div>

                            <hr />
                          </>
                        ) : (
                          <></>
                        )}

                        {loading ? (
                          <>
                            <div className="justify-content-center min-vh-50 w-100 d-flex mt-5 pt-5 mb-4">
                              <CircularProgress />
                            </div>
                          </>
                        ) : (
                          <>
                            <h6>
                              {questions.indexOf(quiz.quiz_id) + 1}.{" "}
                              {quiz.question}
                            </h6>

                            <div className="question-options mt-4  min-vh-50 col-lg-8 col-md-8 col-sm-12 col-12 p-0">
                              {quiz.option1 ? (
                                <>
                                  <div className="option-item mb-3">
                                    <button
                                      disabled={quiz.attempted ? true : false}
                                      onClick={() =>
                                        SubmitAnswer({ option: "option1" })
                                      }
                                      className="btn w-100  btn-outline-dark"
                                    >
                                      {quiz.option1}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {quiz.option2 ? (
                                <>
                                  <div className="option-item mb-3">
                                    <button
                                      disabled={quiz.attempted ? true : false}
                                      onClick={() =>
                                        SubmitAnswer({ option: "option2" })
                                      }
                                      className="btn w-100  btn-outline-dark"
                                    >
                                      {quiz.option2}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {quiz.option3 ? (
                                <>
                                  <div className="option-item mb-3">
                                    <button
                                      disabled={quiz.attempted ? true : false}
                                      onClick={() =>
                                        SubmitAnswer({ option: "option3" })
                                      }
                                      className="btn w-100  btn-outline-dark"
                                    >
                                      {quiz.option3}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {quiz.option4 ? (
                                <>
                                  <div className="option-item mb-3">
                                    <button
                                      disabled={quiz.attempted ? true : false}
                                      onClick={() =>
                                        SubmitAnswer({ option: "option4" })
                                      }
                                      className="btn w-100  btn-outline-dark"
                                    >
                                      {quiz.option4}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        )}

                        <div className="d-flex slign-items-center justify-content-between">
                          {questions.indexOf(quiz.quiz_id) < 1 ? (
                            <>
                              <span></span>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-primary"
                                onClick={() => PreviousQuestion()}
                              >
                                Previous Question
                              </button>
                            </>
                          )}

                          <button className="btn btn-primary" onClick={()=>{MarkQuestion()}}>
                            Mark for Review
                          </button>
                          {(questions.indexOf(quiz.quiz_id) >=
                          attempts.length - 1) ? (
                            <>
                                <button className="btn btn-primary" onClick={()=>{setSubmitModalOpen(true)}}>Submit Test</button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-primary"
                                onClick={() => {NextQuestion();}}
                              >
                                Next Question
                              </button>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4 sm-mt-20 border br-5 p-3">
                        <h4>Questions</h4>
                        <hr></hr>
                        <div className="d-flex   flex-wrap quiz-options-container  overflow-y h-70">
                          {questions && attempts ? (
                            <>
                              {questions.map((item, index) => (
                                <>
                                  {attempts[index][item].attempted ? (
                                    <>
                                      <button
                                        disabled={
                                          parseInt(quiz.quiz_id) === item
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          LoadQuestion({ quiz_id: item });
                                        }}
                                        key={item}
                                        className="btn option-button bg-success text-light rounded-circle"
                                      >
                                        {index + 1}
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        disabled={
                                          parseInt(quiz.quiz_id) === item
                                            ? true
                                            : false
                                        }
                                        id={item}
                                        onClick={() => {
                                          LoadQuestion({ quiz_id: item });
                                        }}
                                        key={item}
                                        className="btn option-button btn-primary rounded-circle"
                                      >
                                        {index + 1}
                                      </button>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="d-flex mt-3 justify-content-between">
                          <div className="text-center">
                            <button className="btn w-fit h-fit text-white bg-dark">{markedque}</button>
                            <p className="small">Marked for review</p>
                          </div>

                          <div className="text-center">
                            <button className="btn w-fit h-fit text-white bg-success">{attemptedque}</button>
                            <p className="small">Attempted</p>
                          </div>

                          <div className="text-center">
                            <button className="btn w-fit h-fit text-white bg-primary">{attempts.length-(markedque+attemptedque)}</button>
                            <p className="small">Unseen</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        className="col-lg-8 col-10 mb-3 col-sm-8"
                        src="https://skillsuprise.com/resources/images/exam-person.svg"
                      />

                      <h2>Test Already attempted</h2>
                      <p>It seems like you have already attempted the test</p>

                      <p>{examstatus}</p>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {examstatus === "unattempted" ? (
                  <>
                    <div className="border p-3 br-5 mt-5 col-lg-6 mx-auto text-center">
                      {attempted ? (
                        <>
                          {attempted && examstatus === "pending" ? (
                            <>
                              <img
                                className="col-lg-8 col-10 mb-3 col-sm-8"
                                src="https://skillsuprise.com/resources/images/exam-person.svg"
                              />

                              <h2>Already attempted</h2>
                              <p>
                                It seems like you have already attempted the
                                exam or you might have refreshed the page/have
                                gone back while attempting the exam
                              </p>
                            </>
                          ) : (
                            <>
                              <img
                                className="col-lg-8 col-10 mb-3 col-sm-8"
                                src="https://skillsuprise.com/resources/images/exam-person.svg"
                              />

                              <h2>Already attempted</h2>
                              <p>You have already attempted</p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="text-align-left">
                            <h2>Instructions</h2>
                            <hr />

                            <div className="flex">
                              <p className="mr-15">
                                <b>Total Questions :</b>35
                              </p>
                              <p>
                                <b>Duration : </b>45 mins
                              </p>
                            </div>
                            <div class="instructions">
                              <ul>
                                <li>
                                  <p>The test carries a total of </p>
                                </li>
                                <li>
                                  <p>
                                    The test comprises of 35 weighted questions.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    A question could be any of the following
                                    type(s): Multiple choice question, Match the
                                    following, Arrange in order and Fill in the
                                    blank.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Minimum 33% marks are required to download
                                    the certificate.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    It is mandatory to attempt all test
                                    questions.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    You have only one attempt for this test.
                                  </p>
                                </li>
                                <li>
                                  <p>No negative marking.</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="note text-left">
                            <Alert severity="error">
                              For a seamless experience, please ensure that you
                              have a stable internet connection and do not press
                              the back button or refresh the page during the
                              test.
                            </Alert>
                          </div>
                          <button
                            className="btn btn-primary mt-2 mb-2 px-5 mt-2"
                            onClick={() => StartTest()}
                          >
                            {reloading?<><CircularProgress/></>:"Start Test"}
                          </button>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <h2>Error </h2>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>)}
        <Modal show={submitModalOpen} onHide={() => setSubmitModalOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Submit Test</Modal.Title>
          </Modal.Header>
        
          <Modal.Body>
            <p>Are you sure you want to submit the test?</p>
            
            <div className="modal-summary">
              <div className="summary-item">
                <strong>Total Questions:</strong> <span>{attempts ? attempts.length : null}</span>
              </div>
              <div className="summary-item">
                <strong>Attempted Questions:</strong> <span>{attemptedque}</span>
              </div>
              <div className="summary-item">
                <strong>Marked for Review:</strong> <span>{markedque}</span>
              </div>
            </div>
          </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSubmitModalOpen(false)}>
            Return to Test
          </Button>
          <Button variant="primary" onClick={handleSubmitTest}>
            Submit Test
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default MockTest;
