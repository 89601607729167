import Chip from '@mui/material/Chip';
import React, { useEffect } from 'react';
import ScratchCards from './ScratchCards';
import { useContext,useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import { AuthContext } from '../../context/AuthContext'
import Toast from 'react-bootstrap/Toast'
import LoadingTemplate from '../../templates/LoadingTemplate'
import {
     EmailIcon,
     EmailShareButton,
     FacebookIcon,
     FacebookShareButton,
    
  
     LinkedinIcon,
    
     LinkedinShareButton,
  
    
     TelegramIcon,
  
    
     TelegramShareButton,
     
     TwitterIcon,
     
     TwitterShareButton,
  
     WhatsappIcon,
  
     WhatsappShareButton,
  
   } from "react-share";
 import Localbase from 'localbase'
 import {CopyToClipboard} from 'react-copy-to-clipboard';
 import axios from 'axios'
import SetPage from '../../data/SetPage';
import { AvatarGroup } from '@mui/material';
import { Avatar } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import FetchFingerprint from '../../actions/FetchFingerprint';
const ReferralProgram = ({props}) => {

     const {user} =  useContext(AuthContext)

const db = new Localbase('db');

const [toast,settoast] = useState(false);
const [message,setmessage] = useState(null)
useEffect(()=>{

 SetPage("Refer & Earn")
         
           GetReferrals();

},[])
useEffect(() => {
     window.scrollTo(0, 0)
   }, [])
const [refdata,setrefdata] = useState(null)


const GetReferrals = async ()=>{


     const formData = new FormData();
     formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());

const res = await axios.post(`${ENDPOINT}referral-program.php`,formData,{
     headers:{
          'content-type':'multipart/form-data'
     }
})



if(res){
     if(res.data.status==="success"){
          setrefdata(res.data.data)
     }else{
          
     }
}

}


  return(

<>
<Helmet>‍
        <title>Referral Program - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
<div className="position-fixed bottom-60 left-0 w-100 justify-center">
<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header className="bg-warning text-dark">
            <img
              src="holder.js/20x20?text=%20"
              className="rounded bg-warning me-2"
              alt=""
            />
            <strong className="me-auto">Referral code copied</strong>
       
          </Toast.Header>
          </Toast>

</div>






{refdata?
<>

<div className="container-xl position-relative sm-p-15 pt-0  mb-5 pb-5">


<div className="pt-5 container-fluid ">

<div className="flex-wrap d-flex justify-content-around mb-5">


<div className="col-lg-3 col-md-3 col-4 p-2">

<div className="border br-5 text-center p-3">
<h1 className="sm-20">{refdata.successful_signups}</h1>
<h4 className="sm-14">Successful Signups</h4>
</div>


</div>






<div className="col-lg-3 col-md-3 col-4 p-2">

<div className="border br-5 text-center p-3">
<h1 className="sm-20">{refdata.coupons_earned}</h1>
<h4 className="sm-14">Coupons Earned</h4>
</div>


</div>





<div className="col-lg-3 col-md-3 col-4 p-2">

<div className="border br-5 text-center p-3">
<h1 className="sm-20">₹ {refdata.money_earned}</h1>
<h4 className="sm-14">Money Earned</h4>
</div>


</div>














</div>

 
  <div className="text-center referral-title">
       
    <h1 className="sm-25">Earn Upto ₹ 20,000 </h1>
     <p className="col-lg-7 mx-auto col-md-8 col-sm-10 col-12">
Refer your friend to join Skills Uprise and earn exciting  cash rewards</p>

     

  </div>

<div className="mt-3 justify-content-center mb-3">
     <div className="text-center">
     <CopyToClipboard onCopy={()=>settoast(true)} text={`https://skillsuprise.com/referral=${refdata.referral_code}`}  >
 
     <Chip label={`https://skillsuprise.com/referral=${refdata.referral_code}`}/>

</CopyToClipboard>
   <br/>
<CopyToClipboard onCopy={()=>settoast(true)} text={`https://skillsuprise.com/referral=${refdata.referral_code}`}  >
 
  <button className="btn mt-3 btn-warning">Copy Referral Code</button>
</CopyToClipboard>
     </div>
</div>




</div>






<div className="col-lg-5 mx-auto border br-5 p-3 mb-5">









<div className="d-flex align-items-center justify-content-center mx-auto">
<WhatsappShareButton size={30}  className="d-flex align-items-center"   round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`}><WhatsappIcon size={30}  type="button"  round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`} className="mr-2"/>

</WhatsappShareButton>
<LinkedinShareButton size={30}   round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`}><LinkedinIcon size={30}  type="button"  round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`} className="mr-2"/></LinkedinShareButton>

<FacebookShareButton className="d-flex align-items-center" size={35}  round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`}> <FacebookIcon size={30} round={true} className="mr-2"/>

</FacebookShareButton>

<TelegramShareButton  className="d-flex align-items-center"  size={30}   round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`}><TelegramIcon size={30}  type="button"  round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`} className="mr-2"/>

</TelegramShareButton>


     <TwitterShareButton  className="d-flex align-items-center"  size={30}   round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`}><TwitterIcon size={30}  type="button"  round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`} className="mr-2"/>

</TwitterShareButton>

   
<EmailShareButton size={30} className="d-flex align-items-center"  round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`}>
     

     <EmailIcon size={30}  type="button"  round={true} url={`https://skillsuprise.com/referral=${refdata.referral_code}`} className="mr-2"/>
     
     
     </EmailShareButton>

</div>


</div>


<div className="text-center pt-2 pb-3">
     <h2 className="sm-25">Rewards & Coupons</h2>
</div>

<div className=" col-lg-12">


     <ScratchCards/>
</div>




</div>




</>


:

<>
<div className="container-xl">
<LoadingTemplate/>
</div>

</>
}


</>


  );
};

export default ReferralProgram;
