
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@mui/material';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import { AuthContext } from '../../context/AuthContext';
import StudentAccessModal from '../../components/modals/StudentAccessModal';
import ShareIcon from './ShareIcon';
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import FetchFingerprint from '../../actions/FetchFingerprint';
import PauseIcon from '@mui/icons-material/Pause';
import { FavoriteBorder, MoreVert } from '@material-ui/icons';
import FavoriteIcon from '@mui/icons-material/Favorite';

const ShortItem = ({ video, account }) => {
  const { user } = useContext(AuthContext);
  const [alert, setAlert] = useState(false);
  const [access, setAccess] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (account && account.purchases && account.purchases.length > 0) {
      setAccess(true);
    }
  }, [account]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (videoRef.current) {
              videoRef.current.play(); 
            }
          } else {
            if (videoRef.current) {
              videoRef.current.pause();
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const CloseStudentAccessModal = () => {
    setAlert(false);
  };

  return (
    <>
      <StudentAccessModal visibility={alert} HandleClose={CloseStudentAccessModal} />
      <Design video={video} user={user} setAlert={setAlert} videoRef={videoRef} />
    </>
  );
};

const Design = ({ video, user, setAlert, videoRef }) => {
  const history = useHistory();
  const [showCaption, setShowCaption] = useState(false);
  const [share, setShare] = useState(false);
  const [liked, setLiked] = useState(video.liked);
  const [likes, setLikes] = useState(parseInt(video.likes));
  const [isPaused, setIsPaused] = useState(false);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  const ToggleLike = async () => {
    setLiked(!liked);
    const formData = new FormData();
    formData.append('session', user.SessionId);
    formData.append('fp', FetchFingerprint());
    formData.append('video_id', video.video_id);

    const res = await axios.post(ENDPOINT + "short-like.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });

    if (res.data.status === "success") {
      setLiked(res.data.liked);
      setLikes(res.data.liked ? likes + 1 : likes - 1);
    }
  };

  const handlePlay = () => {
    setIsPaused(false);
  };

  const handlePause = () => {
    setIsPaused(true);
  };

  const styles = {
    icon: {
      position: 'absolute',
      top: '40%',
      left: '37%',
      pointerEvents: 'none',
      color: 'white',
    },
  };

  return (
    <div className="reel-item">
      <div className="reel-top d-flex align-items-center">
        <div onClick={() => history.goBack()} className="container click d-flex align-items-center">
          <BackIcon className='text-light' />
          <h6 className="p-0 m-0 ms-2 text-light">Short Videos</h6>
        </div>
      </div>
      <video
        ref={videoRef}
        poster={video.poster ? video.poster : null}
        onPlay={handlePlay}
        onPause={handlePause}
        onClick={handleVideoClick}
        className='w-100'
        src={video.url}
      />
      {isPaused && (
        <div style={styles.icon}>
          <PauseIcon className='w-50 h-50' />
        </div>
      )}
      {showCaption ? null : (
        <div className="reel-title">
          <div className="d-flex align-items-center">
            <Avatar src={video.profile_pic} sx={{ height: 28, width: 28 }} />
            <div>
              <h6 className='ms-1 p-0 m-0 text-light'>{video.posted_by}</h6>
            </div>
          </div>
          <div className="pt-1 click" onClick={() => setShowCaption(true)}>
            <p className={`text-12 text-light truncate truncate2`}>
              {video.description ? video.description : null}
            </p>
          </div>
        </div>
      )}

      <div className="reel-actions text-light">
        <div>
          <div className="click" onClick={ToggleLike}>
            {!liked ? <FavoriteBorder /> : <FavoriteIcon className='text-danger' />}
          </div>
          <p>{likes ? likes : 0}</p>
        </div>
        <div onClick={() => setShare(true)} className='mt-2'>
          <ShareIcon className='send' />
          <p>{video.shares ? video.shares : 0}</p>
        </div>
        <div>
          <MoreVert />
        </div>
      </div>
    </div>
  );
};

export default ShortItem;

