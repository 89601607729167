import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GetProjects from '../../../actions/course/GetProjects'
import { AuthContext } from '../../../context/AuthContext'
import ProjectDescriptionAccordion from './ProjectDescriptionAccordion'
import dateFormat from 'dateformat'
import SetPage from '../../../data/SetPage'
import { Alert, Skeleton } from '@mui/material'
import AssignmentsChart from './AssignmentsChart'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ProjectSubmit from './ProjectSubmit'
import ModalStyle from '../../../data/ModalStyle'
import Close from '@mui/icons-material/Close'
import noprojects from '../../../assets/images/no-assignments.svg'
import DetectDevice from '../../../data/DetectDevice'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import progressicon from '../../../assets/icons/progress-report.svg'
import Localbase from 'localbase'
const MyProjects = () => {
const [report,setreport] = useState(null)
const {user} = useContext(AuthContext)
const params = useParams()
const course_url = params.course_url
const batch_id  = params.batch_id
const [loading,setloading] = useState(true)
const [projects,setprojects] = useState(null)
const db=new Localbase("db")

const NewProjects = async()=>{
  const res = await GetProjects({payload:{user:user.SessionId, course_url:course_url, batch_id:batch_id}})

if(res){
  setloading(false)
  if(res.status==="success"&&res.data!==null){
    setprojects(res.data)
    setreport(res.report[0])
    db.collection("paths").add({projects:res.data},("projects-"+JSON.stringify(course_url+batch_id)))
  }
   //  //console.log("proj"+JSON.stringify(res))
}
}
const Getofflinedata = ()=>{
  db.collection("paths").doc(("projects-"+JSON.stringify(course_url+batch_id))).get().then((data)=>{
    setprojects(data.projects)
    setloading(false)
  }).catch(()=>{
    NewProjects()
  })
}

useEffect(()=>{
  Getofflinedata()
},[window.location])

const device = DetectDevice()
useEffect(()=>{
  SetPage("Projects")
  window.scrollTo(0,0)
  if(device==="mobile"){
setmore(false)
  }else{

  }
NewProjects()
},[submit])


const [currentproject,setcurrentproject] = useState(null)
const [submit,setsubmit] = useState(false)
const InitiateSubmission = ({id})=>{
setcurrentproject(id)
setsubmit(true)

}

const [more,setmore] = useState(true)


  return (
    <div className="pb-5 min-vh-100">
           <Modal
        open={submit}
        onClose={()=>{setsubmit(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="col-lg-4 p-3 rounded col-md-5 col-sm-6 col-11">
          <div className="modal-header">
            <h4>Upload Project</h4>
            <Close className="click" onClick={()=>setsubmit(false)}/>
          </div>
          <hr/>
        <ProjectSubmit id={currentproject}/>
        </Box>
      </Modal>
     <div className="d-flex sm-col-reverse container justify-content-between  flex-wrap">



          <div className="col-lg-6 col-12">




{projects?
<>


{projects.map((project,index)=>(
  <>
<div className={"shadow br-4 mb-3 mt-3 p-3 w-100 a"+index}>
<h5>{project.title}</h5>
<div className="d-flex mt-2 justify-content-between align-items-center">

  {project.deadline?
 <>
  <p   className="sm-12"><b>Deadline : </b>{dateFormat(project.deadline,"dS mmm , HH:MM tt")}</p>

 </>:
 <>
 
 </>}
  <p   className="sm-12"><b>Marks : </b>{project.marks}</p>
</div>
<hr className="mb-0"/>
{project.description?
<>
<ProjectDescriptionAccordion className="bg-transparent" description={project.description}/>

</>:null}
<div className="d-flex mt-3 align-items-center justify-content-between">
<a href={project.resources_url} target="_blank" className="btn btn-outline-danger btn-sm">Problem Statement</a>
<button disabled={project.status==="submitted"||project.status==="rejected"?true:false} onClick={()=>{InitiateSubmission({id:project.id})}} className={project.status==='submitted'?'btn btn-success':'btn btn-primary'}>{project.status==="submitted"?"Submitted":"Submit Project"}</button>
</div>
</div>
  </>
))}
</>:<>


{loading?
<>

<div className="w-100">

<Skeleton height={200}  variant="rectangular" className="w-100 mt-2 mb-2"/>

<Skeleton height={200} variant="rectangular" className="w-100 mt-2 mb-2"/>

<Skeleton height={200} variant="rectangular" className="w-100 mt-2 mb-2"/>

</div>


</>:
<>
<div className="text-center">
  <img className="w-100 mb-3" src={noprojects}/>
  <h4>No Projects </h4>
  <p>You do not have any projects for this course. Please contact your trainer for more details.</p>
</div>
</>}






</>}



          </div>

       
          {report?
<>

<div className="col-lg-5 col-12 sm-p-0 mt-4">

<>
<div className="shadow w-100 mx-auto bg-white p-3 ">


<div className="d-flex click justify-content-between align-items-center" onClick={()=>setmore(!more)}>
  

<div className="d-flex">
  <img className="progress-icon mr-2" src={progressicon}/>
  <h3 className=" text-primary sm-17">Progress Report</h3>
</div>
{more?
<>
<ExpandLess/>
</>:
<>
<ExpandMore/>
</>}


</div>
{more?
<>
<hr/>

{report.pending>0?
<div className="mb-3">
<Alert severity="error">You have {report.pending} pending projects !</Alert>
   
</div>
    :null
}

<div className="flex">


<div className="container-fluid">


<div className="flex">


<div className="container-fluid">

<div className="d-flex flex-wrap">
<div className="col-6 p-3 text-center">
<h5>{report.total_assignments}</h5>
<p>Total</p>
  </div>
  <div className="col-6 p-3 text-center">
<h5>{report.submitted}</h5>
<p>Submitted</p>
  </div>

  <div className="col-6 p-3 text-center">
<h5>{report.completed}</h5>
<p>Completed</p>
  </div>


  <div className="col-6 p-3 text-center">
<h5>{report.rejected}</h5>
<p>Rejected</p>
  </div>
</div>

</div>



</div>

<hr/>
<div className="d-flex">
<h5 className="mt-0 mb-0  ">Marks Earned : </h5>
<h5 className="mt-0 mb-0  ">{report.marks_earned}/{report.total_marks}</h5>
</div>

</div>



</div>



{report?
   
<>

{report.completed>0?
<><hr/>


<AssignmentsChart report={report}/>

</>
:null}
</>
  
:null}
</>:
<>


</>}

</div>
</>





</div>






</>:<>

{loading?
<>
<div className="col-lg-5 col-12 sm-p-0 mt-4">

<Skeleton height={600} variant="rectangular" className="w-100"/>

</div>

</>:
<>


</>}



</>}

     </div>
     </div>
  )
}

export default MyProjects