
import React, { useContext } from 'react'
import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import { AuthContext } from '../../context/AuthContext'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import MyPostsTemplate from '../community/MyPostsTemplate'
import SuggestionItem from './SuggestionItem'
import FetchFingerprint from '../../actions/FetchFingerprint'
const FavouriteVideos = () => {
const {user} = useContext(AuthContext)



const [history,sethistory] = useState(null)
     const getDoubts = async ()=>{
        
     
          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
          
     
          
          const res = await axios.post(ENDPOINT+"favourite-videos.php?limit=10", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
       //     //  //  //console.log("history "+JSON.stringify(res.data))
     
           if(res.data.status==="success"){
         
 sethistory(res.data.data)
                 
           }
           else{
          
           }
          }
          }




    
     
     
     
     useEffect(() => {
     window.scrollTo(0, 0)
     getDoubts()
     }, [])













     return (
          <>
        <MyPostsTemplate>
<>


<div className="w-100 sm-p-0 mt-3 sm-mt-0 vh-100 overflow-y">




{history?

<>
{/*HISTORY EXISTS*/}
{history.map((suggestion)=>(

<>

<SuggestionItem suggestion={suggestion}/>
</>

))}



<div className="text-center">
<p type="button">Load More</p>
</div>
</>

:<>
{/*NO VIDEOS*/}

<div className="container-fluid justify-center">


<div className="col-lg-6  col-12 text-center">

<h4 className="mt-0 text-center mt-5">No Favourite Videos</h4>
</div>

</div>
</>
}











</div>
</>


        </MyPostsTemplate>
          </>
     )
}

export default FavouriteVideos









