import { Chip } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import dateFormat from 'dateformat'
import PriceFormat from '../../actions/PriceFormat'
const InternshipItem = ({internship}) => {
  return (
    <div className="p-3 mt-3 mb-3 br-5 internship-item border rounded w-100">
     <h5>{internship.title}</h5>
     <hr/>
     <div className="d-flex">
          <div className="col">
               <h6>Location</h6>
               <p className="text-sec">{internship.mode}</p>
          </div>
          <div className="col">
               <h6>Stipend</h6>
               <p className="text-sec">{PriceFormat(internship.stipend)}/month</p>
          </div>
          <div className="col">
               <h6>Apply by</h6>
               <p className="text-sec">{internship.apply_by}</p>
          </div>
         
     </div>
     <div className="d-flex mt-2 justify-content-end">

<Link to={"/internship/"+internship.id} className="btn btn-primary btn-sm rounded-pill">View Details</Link>
     </div>
     </div>
  )
}

export default InternshipItem