import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetCourses from '../../actions/GetCourses'
import CourseItem from './CourseItem'
import CourseItemSkeleton from './CourseItemSkeleton'
import Localbase from 'localbase'
import CoursesSkeleton from '../../components/skeletons/Courses'
import DetectDevice from '../../data/DetectDevice'
const CoursesList = ({mode,limit}) => {
const [loading,setloading] = useState(true)


const [useronline,setuseronline] = useState(true)
const db = new Localbase("db")

   
const device = DetectDevice()

  const NewGetCourses = async({mode})=>{
  

    try{
  //setloading(true)
  const res = await GetCourses({payload:{
    mode:mode,
    limit:limit||50,
    device:device==="mobile"?"mobile":"desktop"
  }});
  if(res){
  setloading(false)
   setcourses(res)

db.collection("paths").add({new_courses:res},"new_courses")
if(mode==="offline"){
db.collection("paths").add({
  offline_courses:res
},"offline_courses")
}
else if(mode==="specialization"){
db.collection("paths").add({
  specialization_courses:res
},"specialization_courses")
}
else if(mode==="live"){
db.collection("paths").add({
  live_online_courses:res
},"live_online_courses")
}   else if(mode==="selfpaced"){
db.collection("paths").add({
  selfpaced_courses:res
},"selfpaced_courses")
}


   console.log(res)
   
  }else{
    
    console.log("network error")
  }
    }
    catch(err){
      setuseronline(false)
setloading(false)
console.log("something went wrong")
    }
  }




  var scrollAmount ;

  
   const buttonclick = ()=> {
        var container = document.getElementById('container'+mode);
        sideScroll(container,'right',15,300,10);
    };
    
    
    const back = ()=> {
        var container = document.getElementById('container'+mode);
        sideScroll(container,'left',15,300,10);
    };
    
    function sideScroll(element,direction,speed,distance,step){
        scrollAmount = 0;
        var slideTimer = setInterval(function(){
            if(direction === 'left'){
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if(scrollAmount >= distance){
                window.clearInterval(slideTimer);
            }
        }, speed);
    }



    const GetOfflineData = ()=>{
      console.log("mode is "+mode)
      if(mode==="free")
        {
          db.collection("paths").doc("free_courses").get().then(data=>{
            setcourses(data.free_courses)
            setloading(false)
          }).catch(error=>{
            
          })
        }
        if(mode==="offline")
        {
          db.collection("paths").doc("offline_courses").get().then(data=>{
            console.log(data)
            setcourses(data.offline_courses)
            setloading(false)
          }).catch(error=>{
            
          })
        }
        if(mode==="selfpaced")
        {
          db.collection("paths").doc("selfpaced_courses").get().then(data=>{
            setcourses(data.selfpaced_courses)
            setloading(false)
          }).catch(error=>{
            
          })
        }
        if(mode==="live")
        {
          db.collection("paths").doc("live_online_courses").get().then(data=>{
            setcourses(data.live_online_courses)
            setloading(false)
          }).catch(error=>{
            
          })
        }
        if(mode==="specialization")
        {
          db.collection("paths").doc("specialization_courses").get().then(data=>{

            console.log("data stored is "+data.specialization_courses)
            setcourses(data.specialization_courses)
          }).catch(error=>{
            console.log("data error")
          })
        } 
     
    setloading(false)
    }
    useEffect(()=>{
 
GetOfflineData()
   
    },[window.location,courses,mode])

    useEffect(()=>{
     
 
      NewGetCourses({mode}) 
.catch(()=>{
  NewGetCourses({mode}) 
})
    },[])


  const [courses,setcourses] = useState(null) 
  return (
   <>
   
 
   <div className="w-100 d-flex flex-wrap">




{loading?
<>
<div className=" mt-3 mb-3 container">

<CoursesSkeleton/>
</div>
</>:
<>
<div  className="d-flex animation-active flex-wrap">




{courses?

<>
{courses.map((course,index)=>(
<>

<CourseItem useronline={useronline} course={course} index={index+1}/>



</>

))}
</>:

<>
<div className="justify-content-center text-center mt-3 mb-3 mx-auto">
<CircularProgress />
</div>
</>}



</div>
</>}

</div></>
  )
}

export default CoursesList