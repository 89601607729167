import CloseIcon from '@mui/icons-material/Close';
import React from 'react'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'
import {  Toast} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import textlogo from '../../assets/images/skillsuprise-logo.png'
import ENDPOINT from '../../data/ENDPOINT'
import { useEffect } from 'react'
import AuthFeatures from './AuthFeatures';
import Localbase from 'localbase'
import { Helmet } from 'react-helmet';
const SignUp = ({props}) => {


const db= new Localbase('db')




    const [toastTitle,settoastTitle] = useState(null);
    const [displaytoast,setdisplaytoast] = useState(false)
const [toastmessage,settoastmessage] = useState(null)

const [referralcode,setreferralcode] = useState(null)
const [havecode,sethavecode] = useState(false)
const [username,setusername] = useState(null)

const [emailerror,setemailerror] = useState(null);
const [usernameerror,setusernameerror] = useState(null);
const [fnameerror,setfnameerror] = useState(null);
const [lnameerror,setlnameerror] = useState(null);
const [phoneerror,setphoneerror] = useState(null);
const [passworderror,setpassworderror] = useState(null)
const [usernameavailability,setusernameavailability] = useState(false)
const [firstname,setfirstname] = useState(null)
const [lastname,setlastname] = useState(null)
const [email,setemail] = useState(null)
const [phone,setphone] = useState(null)
const [password,setpassword] = useState(null)

const [signup,setsignup] = useState(false)

const [isFetching,setisFetching] = useState(false)



useEffect(() => {
  window.scrollTo(0, 0)
  db.collection("paths").doc('rf_code').get().then(data=>{
setreferralcode(data.rf_code)
sethavecode(true)
  }).catch(error=>{

  })
}, [])


const startRegistration = (e)=>{
e.preventDefault();
if(phone.length!==10){
    setphoneerror("Enter 10 digit phone number")
}
else{
    setphoneerror(null)
    setisFetching(true)
   
    initiateRegistration()
}
}

const initiateRegistration = async ()=>{
    setisFetching(true)
    const formData = new FormData();
       
    //formData.append('whyus',document.getElementById('whyus').value)
    

    formData.append('first_name',firstname);
    formData.append('last_name',lastname);
    formData.append('email',email);
    formData.append('phone',phone);
    formData.append('password',password);
   
   // //  //  //console.log("form data "+JSON.stringify(formData))
    const res = await axios.post(ENDPOINT+"signup.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){

        setisFetching(false)
        
     if(res.data.status==="success"){
    
if(res.data.account_status==="verified"){
    setusernameavailability(true)
}
if(res.data.account_status==="active"){
    setaccountexists(true)
}
else if(res.data.account_exists==="false"){
    window.location.replace("/vcp/"+res.data.verification_code)
}
else if(res.data.verification_code){
    window.location.replace("/vcp/"+res.data.verification_code)
}

     }
     else{
    
     }
    }
    }
    



const [accountexists,setaccountexists] = useState(false)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };
  

const [terms,setterms] = useState(false)
    return (







     

<>
  <Helmet>
    <title>SignUp | Create New Account | Skills Uprise</title>
    <meta name="description" content="Signup into skills uprise and join placement guaranteed training programs | online courses | offline courses | self-paced courses"/>
    <meta name="keywords" content="skills uprise, signup, join, account"/>
    </Helmet>          
<>



<Modal
  open={accountexists}
  onClose={()=>{setaccountexists(false)}}

>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
     <div className="flex space-between">
       <h5> Account Exists !</h5>
       <CloseIcon  type="button" onClick={()=>setaccountexists(false)}/>
     </div>
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
     <div className="flex container-fluid p-0">
         <button onClick={()=>{setaccountexists(false)}} className="btn w-50 btn-danger mr-2">CANCEL</button>
         <Link to="/login" className="btn w-50 btn-primary ml-2">LOGIN</Link>
     </div>
    </Typography>
  </Box>
</Modal>





<Toast onClose={() => setdisplaytoast(false)} show={displaytoast} delay={5000} className="col" autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Error !</strong>
        
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>







<div className="container-fluid align-items-center bg-white d-flex flex-wrap min-vh-100 p-0 ">


<div className="d-flex col-lg-8 col-md-10 col-sm-10 col-12 mx-auto shadow p-3 rounded  border-none md-border  border-1">
<div className="col-lg-6 login-bg flex align-items-center justify-center col-md-6 col-sm-6 d-none d-sm-flex bg-login">




<AuthFeatures />






</div>


<div className="col-lg-6 d-flex container position-relative bg-white login-left col-md-6 col-sm-6 col-12  justify-content-center align-items-center">



<div className=" sm-p-0 bg-white  col-12 p-3 br-4  sm-mt-20">


<div className="mb-4 d-none">
    <img alt="" className="w-75" src={textlogo}/>

</div>

<div className="d-flex">


<div className='mb-3'>
  <p>Hi !</p>
  <h3>Welcome to Skills Uprise</h3>
</div>
</div>

<Form onSubmit={startRegistration} autoComplete="false">
    

    <div className="d-flex mx-auto">

<div className="col-6 pe-1  p-0 col">
<Form.Group className="mb-2" controlId="formBasicText">
  
    <TextField  className="sinput " label="First Name" type="text" variant="outlined"  onChange={(e)=>setfirstname(e.target.value)} minLength={4} maxLength={20} required  />

    {fnameerror?
<>
<Form.Text className="text-danger">
      {fnameerror}
    </Form.Text>
</>    :null
}
  </Form.Group>

</div>


<div className="col-6 ps-1  pr-0 col">
<Form.Group className="mb-4 w-100" controlId="formBasicText">
<TextField  className="sinput " label="Last Name" variant="outlined" type="text"  onChange={(e)=>setlastname(e.target.value)} minLength={4} maxLength={20} required  />
    {lnameerror?
<>
<Form.Text className="text-danger">
      {lnameerror}
    </Form.Text>
</>    :null
}
  </Form.Group>

</div>


    </div>

 

  <Form.Group className="mb-4" controlId="formBasicEmail">
  <TextField  label="Email" className="w-100 sinput" variant="outlined"  onChange={(e)=>setemail(e.target.value)} type="email" minLength={10} maxLength={60} required  />

     {emailerror?
<>
<Form.Text className="text-danger">
      {emailerror}
    </Form.Text>
</>    :null
}
  </Form.Group>



  <Form.Group className="mb-4" controlId="formBasicNumber">
  <TextField className="w-100 sinput" label="Phone Number" variant="outlined" type="number"  onChange={(e)=>setphone(e.target.value)} minLength={10} maxLength={12} required  />
   {phoneerror?
<>
<Form.Text className="text-danger">
      {phoneerror}
    </Form.Text>
</>    :null
}


  </Form.Group>









  <Form.Group className="mb-4" controlId="formBasicPassword">
  <TextField className="w-100 sinput" label="Password" variant="outlined" type="password" onChange={(e)=>setpassword(e.target.value)} minLength={6} maxLength={50} required  />
  
    {passworderror?
<>
<Form.Text className="text-danger">
      {passworderror}
    </Form.Text>
</>    :null
}
  </Form.Group>







{havecode?

<>
<Form.Group className="mb-2" controlId="formBasicText">
   
    <TextField className="w-100 sinput" value={referralcode} label="Referral Code (optional)" variant="outlined" type="text" onChange={(e)=>setreferralcode(e.target.value)} minLength={5} maxLength={15}  />
    {phoneerror?
<>
<Form.Text className="text-danger">
      {phoneerror}
    </Form.Text>
</>    :null
}


  </Form.Group>
</>
:

<><p className="mt-3 mb-3 text-secondary">Have a Referral Code ? <b onClick={()=>sethavecode(true)} className="text-primary font-weight-normal" type="button">Enter Referral Code</b></p> 
</>}









  <Form.Group className="mb-2" controlId="formBasicCheckbox">
    <Form.Check type="checkbox" onChange={(e)=>{setterms(!terms)}} label={<>I accept to the <Link to="/terms-and-conditions">terms and conditions</Link></>} />
  </Form.Group>

  

  <button className="btn btn-primary rounded rounded-pill w-100 mt-3" disabled={isFetching||!terms}  type="submit">
   {isFetching?"Registering...":"Register Now"} </button>
     </Form>
<hr className='my-3'></hr>
<div className="d-flex w-100 justify-content-center">
<Link to="/login" className=" btn border-1 border rounded-pill text-center sm-14 col-10 mx-auto">Already have an account ? <b className='text-primary'>Signin</b> </Link>
  
</div>



</div>






</div>
{/*left section ends*/}


</div>



</div>













</>


        </>



       
    )
}

export default SignUp


