import React from 'react'
import {  Badge, Modal, Toast } from 'react-bootstrap'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'

import { Box } from '@material-ui/core'
import { Rating } from '@mui/material'

import StarIcon from '@mui/icons-material/Star';

import dateFormat from 'dateformat';
import ENDPOINT from '../../../data/ENDPOINT'
import BadgeIcon from '@mui/icons-material/Badge';
import {  Chat, Check, Close } from '@material-ui/icons'
import { Chip } from '@material-ui/core'
import { useEffect } from 'react'
import ForumIcon from '@mui/icons-material/Forum';
import { CheckBox, PlayCircle } from '@mui/icons-material'
import WorkIcon from '@mui/icons-material/Work';
import { Link } from 'react-router-dom'
import {  CircularProgress } from '@material-ui/core'
import { Alert } from '@mui/material'

import { Form } from 'react-bootstrap'
import CloseIcon from '@mui/icons-material/Close';
import VideoPlayerModal from '../../../components/modals/VideoPlayerModal'
import FetchFingerprint from '../../../actions/FetchFingerprint'
import Localbase from 'localbase'
import DetectDevice from '../../../data/DetectDevice'
const PlacementSupport = ({courseurl,batchId,props}) => {
  const db = new Localbase('db');
const {user} = useContext(AuthContext)




const [chat,setchat] = useState(false);
const [subject,setsubject] = useState(null);
const [message,setmessage] = useState(null);
const [messageerror,setmessageerror] = useState(null);
const [subjecterror,setsubjecterror] = useState(null);
const [category,setcategory]= useState(null);
const [callback,setcallback]=  useState(false)
const [discussions,setdiscussions] = useState(null)

const [popup,setpopup] = useState(false)

const [loading,setloading] = useState(false)
const initiateRequest = ({cat})=>{
  setcategory(cat)
  //  //  //console.log("cat "+cat)
  setpopup(true)
}

const [feedback,setfeedback] = useState(false)
const [toast,settoast] = useState(false);
const [toasttitle,settoasttitle] = useState(null)
const [doubtid,setdoubtid] = useState(null)
const [toastmessage,settoastmessage] = useState(null)

const [studentfeedback,setstudentfeedback] = useState(null);
const [rating,setrating] = useState(3)

const labels = {
  0.5: 'Useless',
  1: 'Bad',
  1.5: 'Poor',
  2: 'Ok',
  2.5: 'Below Average',
  3: 'Average',
  3.5: 'Good',
  4: 'Better',
  4.5: 'Best',
  5: 'Excellent',
};

const [hover, setHover] = React.useState(-1);


const sendRequest = async ({cat})=>{
        setloading(true)
        setcategory(cat);
     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     
     formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
     

       formData.append('course',courseurl);
       formData.append('batch_id',batchId);
       formData.append('subject',subject);
       formData.append('message',message);
       formData.append('category',category);
 
     
     const res = await axios.post(ENDPOINT+"need-placement-support.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
       setloading(false)
     ////  //  //console.log(JSON.stringify(res.data.doubts))
      if(res.data.status==="success"){
    setchat(false);
    setcallback(false)
    setpopup(false)
    setsnackmessage("Request successfully placed !")
    setsnackbar(true)
    getDoubts();
 if(cat==="chat"){
      window.open("https://api.whatsapp.com/send/?phone=919490174876");
      
 }        
      }
      else{
        setsnackmessage("Something went wrong !")
        setsnackbar(true)
      }
     }
     }






const device = DetectDevice()
const [snackbar,setsnackbar] = useState(false)
const [snackmessage,setsnackmessage] = useState(null)


const SubmitFeedback = async ()=>{

  const formData = new FormData();
  formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
  formData.append('doubt_id',doubtid);
  formData.append('rating',rating);
  formData.append('feedback',studentfeedback);
  
  const res = await axios.post(ENDPOINT+"submit-placement-feedback.php",formData,{
    headers:{
      'content-type': 'multipart/form-data'
    }
  });
  if(res){
  
  }
  
  if(res.data.status==="success"){
   setfeedback(false)
 setsnackmessage("Feedback submitted");
 setstudentfeedback(null)
 setrating(3)
 setsnackbar(true)
 getDoubts()

  }
  else{
    setsnackmessage("Something went wrong !");
    setsnackbar(true)
  }
  }



const getDoubts = async ()=>{
        
     
     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     
     formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
     

       formData.append('course',courseurl);
       formData.append('batch_id',batchId);
 
     
     
     const res = await axios.post(ENDPOINT+"placement-requests.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
       

      if(res.data.status==="success"){
    
 setdiscussions(res.data.doubts)
 db.collection('paths').add({discussions:res.data.doubts},("placement-cell-"+JSON.stringify(courseurl+batchId)))
            
      }
      else{
     
      }
     }
     }
    
     const GetOfflinedata = () => {
      db.collection('paths').doc(("placement-cell-"+JSON.stringify(courseurl+batchId))).get().then((data) =>{
          setdiscussions(data.discussions)
      }).catch(error=>{ })
     }


     useEffect(()=>{
      GetOfflinedata()
     },[])


useEffect(() => {
window.scrollTo(0, 0)
getDoubts()
}, [])

const [demo,setdemo] = useState(false)
     return (
        <>
        
        
<VideoPlayerModal visibility={demo} HandleClose={()=>setdemo(false)} video={"https://skillsuprie.com/data/videos/placement-training-demo.mp4"} poster={"https://skillsuprie.com/data/images/placement-training.png"}/>

        <Modal
      {...props}
     
      aria-labelledby="contained-modal-title-vcenter"
      centered

      show={feedback}
      onHide={()=>{setfeedback(false)}}
    >
      
      <Modal.Body>
     
      <div className="container-fluid">
<div className="flex space-between mb-4 w-100">
     <h5>Support Feedback</h5>
     <CloseIcon type="button"  onClick={()=>{setfeedback(false)}} className="text-danger"/>
</div>

  
<Box
      sx={{
        width: 200,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      
      <Rating
        name="hover-feedback"
        value={rating}
        precision={0.5}
        onChange={(event, newValue) => {
          setrating(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {rating!== null && (
        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rating]}</Box>
      )}
    </Box>
      

  <Form.Group className="mb-3" >
    <Form.Label>Feedback</Form.Label>
    <Form.Control type="text" required  value={studentfeedback?studentfeedback:null} onChange={(e)=>{setstudentfeedback(e.target.value)}} placeholder="Feedback"/>
    {messageerror?
    <Form.Text className="text-danger">
    {messageerror}
  </Form.Text>
  :null}
  </Form.Group>

  <div className=" mt-4">
    <button className="btn btn-primary w-100" type="submit"   onClick={()=>{SubmitFeedback()}}> Submit Feedback</button>
     
   </div>





      </div>
   
  



    </Modal.Body>
     
    </Modal>




<div className="toast-container">


<Toast show={snackbar} onClose={()=>setsnackbar(false)} delay={4000} autohide className="space-between bg-warning">
           <Toast.Header>
             <img
               src="holder.js/20x20?text=%20"
               className="rounded me-2"
               alt=""
             />
            
           </Toast.Header>
           <Toast.Body>{snackmessage}</Toast.Body>
         </Toast>
</div>


<Modal
      {...props}
     
    
      centered

      show={popup}
      onHide={()=>{setpopup(false)}}
    >
      
      <Modal.Body >
     
      <div className="container-fluid ">

<div className="flex space-between">
<h4>{category==="communication"?
<>Communication Development
</>
:category==="resume"?
<>
Resume Development
</>
:
<>
Mock Interview
</>
} </h4>

<CloseIcon onClick={()=>setpopup(false)} className="text-danger" type="button"/>
</div>
<hr className="w-100"/>
  <p>Your trainer will contact you immediately after your request. Incase your trainer isn't available at the moment, our student relationship manager will contact you to arrange a session according to the availability of the trainer and your convinience.</p>


  <div className="flex space-between mt-4 container-fluid">
   <button className="btn btn-danger  col-5" onClick={()=>{setpopup(false)}}><Close/> Cancel</button>
   <button className="btn btn-primary col-5 d-none d-sm-block" type="submit"  disabled={loading} onClick={()=>{sendRequest({cat:"resume"})}}><Check/> {loading?"Confirming...":"Confirm Request"}</button>
   <button className="btn btn-primary col-5 d-block d-sm-none" type="submit"  disabled={loading} onClick={()=>{sendRequest({cat:"resume"})}}><Check/> {loading?"Confirming...":"Confirm"}</button>
   </div>





      </div>
   
  



    </Modal.Body>
     
    </Modal>



<div className="pt-5 bg-dark placement-section">


<div className="container d-flex flex-wrap">
<div className="col-lg-7  text-white">
<h1 className="sm-20">From crafting a best resume to cracking your dream job, we make you the best.</h1>


<div className="py-1">
  <p className='text-light'>Learn to land your dream job the easiest way with our comprehensive placement training programs</p>
</div>

<button className='btn btn-warning mt-3' onClick={()=>setdemo(true)}> <PlayCircle/> Watch Demo</button>

</div>


<div className="col-lg-3 sm-mt-20">

<img src="https://skillsuprise.com/resources/images/placement-training.png" className="w-100"/>
</div>

</div>


</div>










               <div className="container-fluid placement-opps pt-3">


<div className="flex-wrap container d-flex  placement-menu container bg-white align-items-center justify-content-evenly">

<div className="col-lg-4 col-md-4 col-sm-12  sm-mt-5 p-0 p-sm-3 mb-3 mb-sm-0">


<div className="border shadow bb-red  text-center  br-5 ">
   <img src="https://skillsuprise.com/resources/images/resumeimage.png" className='rounded-top w-100'></img>
   <div className="p-3">
   <h4>Resume Development Training</h4>
<p>Craft a perfect resume for your next internship / job and grab a better opportunity</p>

<button   className="btn btn-primary rounded-pill mt-3" onClick={()=>{initiateRequest({cat:"resume"});}} >Request Training Session</button>

   </div>
</div>

</div>


<div className="col-lg-4 col-md-4 col-sm-12  sm-mt-5 p-0 p-sm-3 mb-3 mb-sm-0">
<div className="border shadow bb-green text-center  br-5 ">
<img src="https://skillsuprise.com/resources/images/communicationimage.png" className='rounded-top w-100'></img>
<div className="p-3">
<h4>Communication Development</h4>
<p>Learn to attract people with effective communication. </p>


<button className="btn btn-primary rounded-pill mt-3" onClick={()=>{initiateRequest({cat:"communication"})}}> Request Training Session</button>

</div>

</div>
</div>



<div className="col-lg-4 col-md-4 col-sm-12 sm-mt-5 p-0 p-sm-3 mb-3 mb-sm-0">
<div className="border shadow bb-yellow bbt-3  text-center   br-5">
 <img src="https://skillsuprise.com/resources/images/interviewimage.png" className='rounded-top w-100'></img>

 <div className="p-3">
 <h4>Mock Interview</h4>
<p>Crack job interviews like a pro. Prepare yourself to knock the opportunities . </p>


<button className="btn btn-primary rounded-pill mt-3" onClick={()=>{initiateRequest({cat:"mock"})}}>Request Mock Interview</button>

 </div>

</div>
</div>



</div>






               </div>

<div className="mt-5 mb-5">

<hr/>
</div>

 <div className="container-fluid col-lg-12 mx-auto sm-p-15 mb-5 pb-5">


{discussions?

<>

<h2 className="text-center sm-22 mb-4">Previous Training Sessions </h2>


  <div className="col-lg-6 mx-auto text-center">
  <p>On an average, we responded to you within 10 minutes of your request. How do you feel about our support <Link >Submit a Feedback</Link></p>

  </div>


</>

:<>

</>}

{discussions?

<div className="container border rounded mt-3 mb-3 p-3">
      <div className="flex-wrap align-items-center">
      {discussions.map((discussion)=>(

<>
<>



<div className="col-12 px-0 px-md-2">
  <div className="card shadow-sm border-0 mb-3 bg-light">
    <div className="card-body p-2">
      <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-between align-items-center gap-2">
        <div className="col-12 col-sm-12 flex-wrap  col-md-6 d-flex justify-content-sm-start justify-content-md-center justify-content-lg-center align-items-center mb-2 mb-sm-0 order-first order-md-last">
          {discussion.category === "resume" ? (
            <div className="d-flex align-items-center">
              <BadgeIcon className="text-danger me-2" />
              <p className="mb-0">Resume building training</p>
            </div>
          ) : discussion.category === "communication" ? (
            <div className="d-flex align-items-center w-fit">
              <ForumIcon className="text-success me-2" />
              <p className="mb-0">Communication development training</p>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <WorkIcon className="text-primary me-2" />
              <p className="mb-0">Mock Interview</p>
            </div>
          )}
        </div>
        <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between col-12 col-sm-12 col-md-6 gap-2">
          <div className='d-flex'>
            <h6 className='d-none d-md-block me-1'>ID: </h6>
          <h6 className="m-0 text-dark"> {discussion.id}</h6>
          </div>
          <p className="text-muted m-0 d-flex align-items-center">
            {dateFormat(discussion.create_datetime, "dS mmm yyyy")}
          </p>
          <span className={`badges ${discussion.status === 'initiated' ? 'bg-warning-subtle text-warning' : 
              discussion.status === 'closed' ? 'bg-success-subtle text-success' : 
              discussion.status === 'pending' ? 'bg-warning-subtle text-warning' : 'bg-danger-subtle text-danger'}`}>
            {discussion.status}
          </span>
        </div>
      </div>
      {discussion.status === "resolved" && (
        <div className="d-flex justify-content-end mt-2 gap-2">
          <div type="button" className="text-primary">
            <Chat />
          </div>
          <div type="button" className="text-primary">
            <PlayCircle />
          </div>
          {!discussion.rating && (
            <div
              type="button"
              onClick={() => {
                setdoubtid(discussion.id);
                setfeedback(true);
              }}
              className="text-primary"
            >
              Submit Feedback
            </div>
          )}
        </div>
      )}
    </div>
  </div>
</div>






</>
</>

))}
      </div>
    </div>
:<></>}
 </div>
        </>
     )
}

export default PlacementSupport
