import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ENDPOINT from "../../data/ENDPOINT";
import SecurityHeaders from "../../data/SecurityHeaders";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link, useParams } from "react-router-dom";
import ModalStyle from "../../data/ModalStyle";
import SetPage from "../../data/SetPage";
import { CircularProgress } from "@material-ui/core";
import { AuthContext } from "../../context/AuthContext";
import { Helmet } from "react-helmet";
import StudentAccessModal from "../../components/modals/StudentAccessModal";
import CourseCertifications from "../certifications/CourseCertifications";
import SearchIcon from "@mui/icons-material/Search";
import Localbase from "localbase";
import Skeleton from "@mui/material/Skeleton";

const TestTopics = ({ account }) => {
  const params = useParams();
  const test_type = params.test_type;
  const course_url = params.course_url;

  const db = new Localbase("db");

  useEffect(() => {
    GetOfflinedata();
    window.scrollTo(0, 0);
  }, []);

  const [categories, setcategories] = useState(null);

  const GetQuizCategories = async () => {
    setloading(true);
    const data = new FormData();
    data.append("test_type", test_type);
    data.append("course_url", course_url);
    const res = await axios.post(
      ENDPOINT + "test-topics.php",
      data,
      SecurityHeaders,
    );

    if (res) {
      setloading(false);
      if (res.data.status === "success") {
        setInititalData(res.data.data);
        setResponse(res.data.data);
        setcategories(res.data.data);
        console.log(res.data.data);
        db.collection("paths").add(
          {
            test: res.data.data,
          },
          JSON.stringify(test_type + course_url),
        );
      }
    }
  };

  const GetOfflinedata = () => {
    db.collection("paths")
      .doc(JSON.stringify(test_type + course_url))
      .get()
      .then((data) => {
        if (data) {
          setResponse(data.test);
        }
      });
  };
  useEffect(() => {
    GetOfflinedata();
  }, [window.location]);
  useEffect(() => {
    GetQuizCategories();
    SetPage("Test Topics");
  }, []);

  useEffect(() => {
    if (account) {
      if (account.purchases && account.purchases.length > 0) {
        setaccess(true);
      } else {
        setaccess(false);
      }
    }
  }, [account]);

  const [access, setaccess] = useState(false);
  const [modal, setmodal] = useState(false);

  const ViewQuiz = () => {
    if (access) {
    } else {
      setmodal(true);
    }
  };

  const CloseStudentAccessModal = () => {
    setmodal(false);
  };

  const { user } = useContext(AuthContext);
  const [loading, setloading] = useState(false);

  const [initialData, setInititalData] = useState(null);

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return item.title.toLowerCase().includes(term);
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };
  return (
    <>
      <Helmet>
        ‍<title>Mock Test Topics - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />
        <meta name="twitter:card" content="" />
        <meta name="twitter:site" content="@skillsuprise" />
        <meta name="twitter:creator" content="@skillsuprise" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

      <StudentAccessModal
        visibility={modal}
        HandleClose={CloseStudentAccessModal}
      />

      {test_type === "course-certification" ? (
        <>
          <CourseCertifications access={access} categories={categories} />
        </>
      ) : (
        <>
          <div className="min-vh-100 interview-praparation pb-5">
            <div className="container d-flex flex-wrap justify-content-between pt-5 ">
              <h2>Test Papers</h2>

              <div className="d-flex justify-content-end mb-3">
                <div className="d-flex border align-items-center rounded  col-12 ">
                  <SearchIcon className="ri-search-line search-icon text-secondary" />

                  <input
                    onChange={handleSearch}
                    type="text"
                    className="form-control border-0 search"
                    placeholder="Search"
                  />
                  {/* <i className="ri-search-line search-icon" /> */}
                </div>
              </div>

              <Box className="d-none">
                <FormControl onClick={() => setmodal(true)}>
                  <InputLabel id="demo-simple-select-label">
                    Difficulty
                  </InputLabel>
                  <Select value={"Python"}>
                    <MenuItem value={"Python"}>Beginner</MenuItem>
                    <MenuItem value={"ai"}>Intermediate</MenuItem>
                    <MenuItem value={"dsPython"}>Advanced</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>

            <hr />

            <div className="container mt-4 d-flex flex-wrap">
              {response ? (
                <>
                  {response.map((item) => (
                    <>
                      {user ? (
                        <>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 p-0 p-md-3 sm-p-1">
                            <div
                              onClick={() => ViewQuiz()}
                              className=" quiz-category"
                            >
                              <div className="d-flex">
                                <img
                                  className="col-4"
                                  src={item.image}
                                  alt=""
                                />

                                <div className=" p-2 w-100 position-relative">
                                  <h6 className="">{item.title}</h6>
                                  <div className="d-flex justify-content-between align-items-end">
                                    <div className="">
                                      <p className="sm-13 text-secondary">
                                        <b>Questions : </b>
                                        {item.total_quizzes}
                                      </p>
                                      <p className="sm-13 text-secondary">
                                        <b>Duration : </b>
                                        {item.duration} mins
                                      </p>
                                    </div>

                                    {access ? (
                                      <>
                                        <Link
                                          to={
                                            "/exam/" +
                                            test_type +
                                            "/" +
                                            item.course_url +
                                            "/" +
                                            item.test_code
                                          }
                                          className="h-fit w-fit btn btn-sm btn-primary rounded-pill rounded d-flex align-items-baseline position-absolute right-10 bottom-10"
                                        >
                                          Start Test
                                        </Link>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          onClick={() => setmodal(true)}
                                          className="btn btn-sm btn-primary rounded-pill rounded w-100"
                                        >
                                          Start Test
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 p-0 p-md-3 sm-p-1">
                            <div className=" quiz-category">
                              <div className="d-flex">
                                <img
                                  className="col-4"
                                  src={item.image}
                                  alt=""
                                />
                                <div className=" p-2 w-100 position-relative">
                                  <h6 className="">{item.title}</h6>
                                  <div className="d-flex justify-content-between align-items-end">
                                    <div className="">
                                      <p className="sm-13 text-secondary">
                                        <b>Questions : </b>
                                        {item.total_quizzes}
                                      </p>
                                      <p className="sm-13 text-secondary">
                                        <b>Duration : </b>
                                        {item.duration} mins
                                      </p>
                                    </div>
                                        <button
                                          onClick={() => setmodal(true)}
                                          className="h-fit w-fit btn btn-sm btn-primary rounded-pill rounded d-flex align-items-baseline position-absolute right-10 bottom-10"
                                        >
                                          Start Test
                                        </button>
                                     
                                  </div>
                                </div>
                              </div>

                              {/* <h5>{item.title}</h5>

                              <div className="d-flex text-secondary justify-content-between align-items-center">
                                <p>
                                  <b>Total quizzes : </b>
                                  {item.total_quizzes}
                                </p>
                                <p>
                                  <b>Duration : </b>
                                  {item.duration} mins
                                </p>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between mt-3">
                                <span></span>
                                <button
                                  onClick={() => setmodal(true)}
                                  className="btn  text-primary  btn-sm"
                                >
                                  Start Test
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>
                  {loading ? (
                    <>
                      <div className="mt-5 w-100 text-center">
                        <CircularProgress />
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TestTopics;
