import { Avatar, CircularProgress } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import QuestionIcon from '@mui/icons-material/QuestionAnswer';
import ImageIcon from '@mui/icons-material/Collections';
import { useState } from 'react';

import ModalStyle from '../../data/ModalStyle';
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'
import classes from '../community/classes'
import { styles } from '../community/styles'

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { withStyles } from '@material-ui/core/styles'
import ImgDialog from '../community/ImgDialog'
import getCroppedImg from '../community/cropImage'
import {  useCallback } from 'react'
import Modal from '@mui/material/Modal';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';

import ENDPOINT from '../../data/ENDPOINT';

import { CameraAlt, Edit } from '@mui/icons-material';
import FetchFingerprint from '../../actions/FetchFingerprint';

const CoverPictureEdit = ({account,ChangedCover,self}) => {
const [file,setfile] = useState(null)
const reader = new FileReader();



const [status,setstatus]= useState(false)
const [filetype,setfiletype] = useState(null)

const [uploadprogress,setuploadprogress] = useState(0);

const [filesize,setfilesize] = useState(0)



const {user} = useContext(AuthContext)



const FileChange = (e)=>{
     setfile(e.target.files[0])
   setpath(URL.createObjectURL(e.target.files[0]))
  
}



{/*


   formData.append('description',payload.desc);
     
     }
        formData.append('session',payload.user);
       
        formData.append('course_url',payload.course_url);
        formData.append('category',payload.category);
        formData.append('batch_id',payload.batch_id);
*/}
const [posts,setposts] = useState(null)
     
const UploadImage = async()=>{
    

   
     setfilesize(file.size)
       setfiletype(file.filetype)
    
    ////  //  //console.log("changed"+file.filetype)
     const config = {
       onUploadProgress: function(progressEvent) {
         var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
     // //  //  //console.log("total "+progressEvent.total)
         setuploadprogress(percentCompleted)
     
         setfilesize((progressEvent.total/1000000));
   
       }
     }
   
   
   
 
       const formData = new FormData();

  
       ////  //  //console.log("Type: " + file.type);
       ////  //  //console.log("Size: " + file.size + " bytes");
       //check for image
   if(file.type==="image/jpeg" ||file.type==="image/png" ||file.type==="image/jpg"){
   
   
   
        formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
       
        formData.append('cover',croppedImage);

        const res =  await axios.post(ENDPOINT+`upload-cover.php`, formData,config,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
   if(res){
     //  //console.log("posted info"+JSON.stringify(res))
     
     if(res.data.status==="success"){
        
 
setuploadmodal(false)
     }
    
   }
   }else{
  
   }
   
   
       
   
   

}

const [path,setpath] = useState(null)
const [imagemodal,setimagemodal] = useState(false)

const [showfilters,setshowfilters] =useState(false)
const [filtermodal,setfiltermodal] = useState(false)

const ShowFilters = ()=>{
     setimagemodal(false)
     setfiltermodal(true)
}


const StartUpload = ()=>{
const button = document.getElementById("image-upload")
button.click()

}


const dogImg = path;

const [crop, setCrop] = useState({ x: 0, y: 0 })
const [rotation, setRotation] = useState(0)
const [zoom, setZoom] = useState(1)
const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
const [croppedImage, setCroppedImage] = useState(null)

const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels)
}, [])
const [croppedImageUrl,setcroppedImageUrl] = useState(null)
const showCroppedImage = useCallback(async () => {
     setsettingimg(true)
  try {
    const croppedImage = await getCroppedImg(
      dogImg,
      croppedAreaPixels,
      rotation
    )

    setcroppedImageUrl(URL.createObjectURL(file))
     //  //console.log("croppped url"+JSON.stringify(setcroppedImageUrl))
  //  //console.log(croppedImage)
    setCroppedImage(croppedImage)
    ChangedCover({image:croppedImage})
    setuploadmodal(true)
    setfiltermodal(false)
    setsettingimg(false)
  } catch (e) {
     setsettingimg(false)
    console.error(e)
  }
}, [croppedAreaPixels, rotation])

const onClose = useCallback(() => {
  setCroppedImage(null)

}, [])

const [description,setdescription] = useState(null)

const [state,setstate] = useState()

   const [settingimg,setsettingimg] = useState(false)
const [uploadmodal,setuploadmodal] = useState(false)


const CancelUpload = ()=>{
  setfile(null)
  setCroppedImage(null)
  ChangedCover({image:null})
}


  return (
    <>


<Modal
  open={imagemodal}
  onClose={()=>{CancelUpload();setimagemodal(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-2 br-5 col-lg-4 col-md-5 col-sm-6 col-11 rounded mx-auto">
    <Typography id="modal-modal-title" variant="h6" component="h2">
  
      <div className="d-flex justify-content-between">
          <h5 className="click" onClick={()=>{CancelUpload();setimagemodal(false)}}>Cancel</h5>
          {file?
          <>
               <h5  onClick={()=>ShowFilters()} className="click text-primary">Next</h5>
   
          </>:null}
        </div>
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
    <img src={file?URL.createObjectURL(file):""} className="w-100 cropper" />
    <input id="image-upload" className="btn-upload d-none" type="file" onChange={(e)=>FileChange(e)}/>
   
    
   
    <button onClick={()=>StartUpload({file:file})} className="btn-upload  w-100 mb-3 mt-3">{file?"Change Image":"Select Image"}</button>
    
        </Typography>
  </Box>
</Modal>





<Modal
  open={uploadmodal}
  onClose={()=>{CancelUpload();setuploadmodal(false)}}

  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-2 br-5 col-lg-5 col-md-6 col-sm-8 col-11 mx-auto">
    <Typography id="modal-modal-title" variant="h6" component="h2">
  
      <div className="d-flex flex-wrap justify-content-between">
          <h5 className="click text-primary" onClick={()=>{CancelUpload();setuploadmodal(false)}}>Cancel</h5>

                </div>
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 1 }}>

     <div className="d-flex flex-wrap w-100">

{croppedImage?
   <>
    <img  src={URL.createObjectURL(croppedImage)} className="w-100 " />
    
   </>:null}



     </div>
 
     <button onClick={()=>UploadImage()} className="btn-primary  btn w-100 mb-3 mt-3">Change Cover Picture</button>
 
   
        </Typography>
  </Box>
</Modal>






<Modal
  open={filtermodal}
  onClose={()=>setfiltermodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-2 br-5 col-lg-4 col-md-5 col-sm-7 col-11 mx-auto">
    <Typography id="modal-modal-title" variant="h6" component="h2">
  
      <div className="d-flex justify-content-between">
          <h5 onClick={()=>{CancelUpload();setfiltermodal(false)}} className="click text-primary">Cancel</h5>
          <h5 className="text-primary click" onClick={()=>showCroppedImage()}>Next</h5>
      </div>
    </Typography>

     <>
     

     <div className=" h-fit position-relative">
     {path?
     <>
     <img  src={path} className="w-100 cropper op-0"/>
     </>:null}
   <div className="crop-container">
        
        <Cropper
        className="cropper"
          image={dogImg}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={3 / 1}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
  

        
   
     
    </div>
     
     
     </>
    <div>
 
      <img src={croppedImage}/>
      
    </div>

<div className=" mt-3 ">
     <div className="mb-3 mt-3 crop-range">
          <p>Zoom Percentage</p>
          <input
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value)
          }}
          className="zoom-range"
        />
     </div>

<div className="d-flex justify-content-between">
<div className="d-flex">

     <div className="text-center">

     <RotateLeftIcon type="button" onClick={()=>setRotation(rotation-90)}/>
<p className="extra-small">Rotate Left</p>

     </div>
     
     
     <div className="text-center ml-15">
     <RotateRightIcon type="button" onClick={()=>setRotation(rotation+90)}/>
  
<p className="extra-small">Rotate Right</p>
     </div>
     
     

</div>



<Button
          onClick={showCroppedImage}
          variant="contained"
          color="primary"
          classes={{ root: classes.cropButton }}
        >
          {settingimg?
          <>
          <CircularProgress color="inherit" className="mr-5" size={20}  disableShrink  />
          </>:
          
          <>
            Done</>}
        
        </Button>

</div>


 
 
</div>
   
  </Box>
</Modal>







{self?
<>
<div onClick={()=>setimagemodal(true)} className="cover-picture-edit-toggle click">
     <CameraAlt />
</div>
</>:null}




    </>
  )
}

export default CoverPictureEdit