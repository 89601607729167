import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import mobilelogo from "../assets/images/mobile-logo.png";
import logo from "../assets/images/skillsuprise-logo.png";
import supporticon from "../assets/icons/supporticon.svg";
import { Link, Route } from "react-router-dom";
import WidgetsIcon from "@mui/icons-material/Widgets";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Sidebar from "./Sidebar";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import GroupIcon from "@mui/icons-material/Group";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import SchoolIcon from "@mui/icons-material/School";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Dropdown from "react-bootstrap/Dropdown";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChatIcon from "@mui/icons-material/Chat";
import Badge from "@mui/material/Badge";
import ReviewsIcon from "@mui/icons-material/Reviews";
import MailIcon from "@mui/icons-material/Mail";
import Tooltip from "@mui/material/Tooltip";
import BackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AuthContext } from "../context/AuthContext";
import SearchCourses from "../actions/SearchCourses";
import Zoom from "@mui/material/Zoom";
import DetectDevice from "../data/DetectDevice";
import AccountOptionsDrowdown from "../pages/account/AccountOptionsDrowdown";
import ENDPOINT from "../data/ENDPOINT";
import Close from "@mui/icons-material/Close";
import { ChevronLeft, ChevronLeftOutlined, ChevronRight, Home } from "@material-ui/icons";
import FeedIcon from "@mui/icons-material/Feed";
import ContactIcon from "@mui/icons-material/ContactPage";
import EventIcon from "@mui/icons-material/Event";
import WorkIcon from "@mui/icons-material/Work";
import DashboardIcon from "@mui/icons-material/Dashboard";
import axios from "axios";
import CallIcon from '@mui/icons-material/Call';
import accounticon from "../assets/sidebar/account.svg";
import homeicon from "../assets/sidebar/home.svg";
import internshipsicon from "../assets/sidebar/internships.svg";
import mycourseicon from "../assets/sidebar/my-course.svg";
import onlinetrainingicon from "../assets/sidebar/online-training.svg";
import offlinetrainingicon from "../assets/sidebar/offline-training.svg";
import privacyicon from "../assets/sidebar/privacy.svg";
import videosicon from "../assets/sidebar/videos.svg";
import workshopsicon from "../assets/sidebar/workshop.svg";
import featuresicon from "../assets/sidebar/advantage.svg";
import community from "../assets/sidebar/community.svg";
import dashboardicon from "../assets/icons/dashboard.svg";
import mycourse from "../assets/icons/my-courses.svg";
import reviewicon from "../assets/icons/review.svg";
import selfpacedicon from "../assets/icons/selfpaced-training.svg";
import termsicon from "../assets/icons/terms.svg";
import refundicon from "../assets/icons/refund.svg";
import shareicon from "../assets/icons/share-icon.svg";
import companyicon from "../assets/icons/company-icon.svg";
import freetrainingicon from "../assets/icons/free-training.svg";
import audio from "../assets/audio/notif.mp3";
import allcourses2 from "../assets/icons/all-courses2.svg";
import specializationicon from "../assets/icons/specialization.svg";
import NavbarCourseSearch from "./NavbarCourseSearch";
import { ref, push, onValue, getDatabase } from "firebase/database";
import SetPage from "../data/SetPage";
import UpdatingModal from "./modals/UpdatingModal";
import { useActionsContext } from "../context/ActionsContext";
import { ViewSidebarTwoTone } from "@mui/icons-material";

const Navbar = ({ props, account, OpenSearch, fluid,secondaryview }) => {
  const [canvas, setcanvas] = useState(false);
  const { user } = useContext(AuthContext);
  const {search,ShowSearch,HideSearch,HideSidebar,ShowSidebar} = useActionsContext()
  const [loggedin, setloggedin] = useState(false);
  const history = useHistory();
  //  //console.log("navbar")
  const {sidebar,ToggleSidebar} = useActionsContext()
  const audioRef = React.createRef();
  const [secret, setsecret] = useState(
    JSON.parse(localStorage.getItem("uid")) || null,
  );

  useEffect(()=>{
setsecret(JSON.parse(localStorage.getItem("uid")))
  },[JSON.parse(localStorage.getItem("uid")),secret])
  const device = DetectDevice();
  useEffect(() => {
    if (user === null || user === "null") {
      setloggedin(false);
    } else {
      setloggedin(true);
    }
  }, []);

  const [title, settitle] = useState(null);

  const [page, setpage] = useState(sessionStorage.getItem("page"));
  useEffect(() => {
    setpage(sessionStorage.getItem("page"));
    JSON.parse(localStorage.getItem("uid"))
  }, [sessionStorage.getItem("page"), window.location.pathname]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      settitle(JSON.parse(sessionStorage.getItem("page")));

      if (title === "Recordings") {
        setfullwidth(true);
      }
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const [results, setresults] = useState(null);
 
 

  const HiddenPages = ["Login","Shorts","shorts"];



  useEffect(() => {
    HideSearch()
    const sidebar = document.getElementById("sidebar");
    if (sidebar) {
      if (sidebar.classList.contains("display-sidebar")) {
        setcanvas(false);
      } else {
        setcanvas(false);
      }
    }
  }, [window.location]);

  const [notifications, setnotifications] = useState(null);
  const [unread, setunread] = useState(0);
  const [messages, setmessages] = useState(0);

  const GetNotifications = async () => {
    if (account) {
      try {
        fetch(`${ENDPOINT}get-notifications.php?session=${user.SessionId}`)
          .then((r) => r.json())
          .then((result) => {
            if (result[0].status === "success") {
              setunread(result[0].unread);

              if (unread !== parseInt(result[0].unread)) {
                //document.getElementById("audio").play()
              }

              setmessages(result[0].messages);

              if (result[0].data !== null) {
                setnotifications(result[0].data);
              }
            }
          });
      } catch {}
    }
  };

  const pathname = window.location.pathname;

  const updateActivity = async (status) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)
    if (user !== null) {
      formData.append("session", user.SessionId);
    } else {
      formData.append("secret", secret);
    }

    formData.append("page", pathname);

    formData.append("device", navigator.userAgent);


    const res = await axios.post(ENDPOINT + "save-activity.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
    }
  };

  const MakeTemporarySession = () => {};

  const CheckTempSession = () => {
    if (user === null || user === "null") {
      setloggedin(false);
    } else {
      setloggedin(true);
    }
  };

  useEffect(() => {
    updateActivity();
  }, [pathname]);

  useEffect(() => {
    HideSidebar();
  }, [window.location]);

  const dbRef = ref(
    getDatabase(),
    account ? account.user_id + "/notifications" : "anonymous",
  );

  useEffect(() => {
    // Listen for changes in the 'messages' node in the database
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messageList = Object.values(data);
        if (account) {
          GetNotifications();
          console.log("retreving data");
        }
      }
    });
  }, [account]);

  const sendMessage = () => {
    // Push a new message to the 'messages' node in the database
    push(dbRef, {
      timestamp: new Date().getTime(),
    });
  };

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
useEffect(()=>{

if(device==="mobile"){

}
else{
  const navbar2 = document.getElementById("navbar2")
  if(secondaryview){
    if(visible&&navbar2){
      navbar2.classList.replace("hidenavbar2","displaynavbar2")
    
    
    }
    else{
      if(navbar2){
        navbar2.classList.replace("displaynavbar2","hidenavbar2")
    
      }
     
     }
  }
  else{
  
  
  
  navbar2.classList.add("hidenavbar2")
  
  }
}
},[visible,secondaryview])
  useEffect(() => {
    
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
//console.log(currentScrollPos)
      setVisible(
        (prevScrollPos > currentScrollPos || prevScrollPos - currentScrollPos > 10)
      );

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible]);
 
  const [fullwidth, setfullwidth] = useState(false);
 
const [updatemodal,setupdatemodal] = useState(false)

const CloseUpdateModal = ()=>{

setupdatemodal(false)


}

const GoBack = ()=>{
if(history.length>0){
history.goBack()
}
else{
history.replace("/")
}
}




  return (
    <>
      <audio id="audio" src={audio} />
<UpdatingModal visibility={updatemodal} HandleClose={CloseUpdateModal}/>
      {HiddenPages.includes(title) ? (
        <></>
      ) : (
        <>
          <div className="main-header" >
          <div className="navbar container-fluid">
            <div
              className={
                title === "Recordings" ? "container-fluid" : "container"
              }
            >
              <div className="primary-nav d-flex align-items-center">
                {device === "mobile" ? (
                  <>
                    {title === null ||
                    title === "null" ||
                    title === "Community" ? (
                      <>

                      {search?
                      <>
                      
                     
                      </>:
                      <>
                      
                      <Link to="/">
                          <img src={mobilelogo} className="logo " />
                        </Link>
                      </>}
                       
                      </>
                    ) : (
                      <>



                        {search?
                        <>
                        
                        
                        </>:
                        <>
                        <div
                          onClick={() =>GoBack()}
                          type="button"
                          className="d-flex align-items-center"
                        >
                          <BackIcon className="text-light" />
                          <h5 className="pb-0 ml-5 text-white truncate truncate1">
                            {title}
                          </h5>
                        </div>
                        
                        </>}




                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Link to="/">
                      <img src={logo} className="logo " />
                    </Link>
                  </>
                )}

            

                <div className="nav-items  d-md-flex align-items-center justify-content-end justify-content-md-between">
                  {/*left search icon desktop search*/}
       
                  <NavbarCourseSearch HandleClose={HideSearch} ShowSearch={ShowSearch} visibility={search}/>
   
      {search?
           <>    
           
           </>:
           <>
            <SearchIcon

onClick={() =>ShowSearch()}
                    className="text-light search1 d-none d-sm-none d-md-none d-lg-block mt-1  click "
                  />
           </>} 







{/*NAV ITEMS*/}

{search?
<>
{search ? (
              <>

  
              </>
                ) : (
                  <></>
                )}
</>:
<>

<Link className="nav-item  d-lg-block d-none" to="/">
                    <Home />
                    <p>Home</p>
                  </Link>

                  {loggedin ? (
                    <>
                      {/* <Link
                        className="nav-item  d-lg-block d-none"
                        to="/newsfeed"
                      >
                        <FeedIcon />
                        <p>Newsfeed</p>
                      </Link> */}
                    </>
                  ) : (
                    <></>
                  )}
                  {loggedin ? (
                    <>
                      <Link
                        className={
                          page === "Dashboard"
                            ? "nav-item active text-light  d-lg-block d-none"
                            : "nav-item  d-lg-block d-none"
                        }
                        to="/dashboard"
                      >
                        <DashboardIcon />
                        <p>Dashboard</p>
                      </Link>
                    </>
                  ) : null}
                  <Link className="nav-item  d-lg-block d-none" to="/courses">
                    <div className="nav-dropdown bg-transparent">
                      <SchoolIcon />
                      <p>Courses</p>
                      <div className="nav-dropdown-content p-3">
                        <div className="bg-white nav-dropdown-main shadow w-100 mt-10 p-3">
                          <div className="mb-2">
                            <Link
                              className="nav-item text-dark"
                              to="/live-online-courses"
                            >
                              Online Courses
                            </Link>
                          </div>
                          <div className="mb-2">
                            <Link
                              className="nav-item text-dark"
                              to="/offline-courses"
                            >
                              Offline Courses
                            </Link>
                          </div>
                          <div className="mb-2">
                            <Link
                              className="nav-item text-dark"
                              to="/selfpaced-courses"
                            >
                              Pre-recorded Courses
                            </Link>
                          </div>
                          <div className="mb-2">
                            <Link
                              className="nav-item text-dark"
                              to="/specialization-courses"
                            >
                              Specialization Courses
                            </Link>
                          </div>
                      
                          <div className="mb-2">
                            <Link className="nav-item text-dark" to="/courses">
                              All Courses
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  {user ? (
                    <>
                      <Link
                        className="nav-item  d-lg-block d-none"
                        to="/recommendations"
                      >
                        <GroupIcon />
                        <p>Connections</p>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        className="nav-item  d-lg-block d-none"
                        to="/videos"
                      >
                        <VideoLibraryIcon />
                        <p>Videos</p>
                      </Link>
                    </>
                  )}

                  <Link
                    className="nav-item d-lg-block d-none"
                    to="/interview-preparation"
                  >
                    <div className="nav-dropdown bg-transparent">
                      <SchoolIcon />
                      <p>Resources</p>
                      <div className="nav-dropdown-content p-3">
                        <div className="bg-white nav-dropdown-main shadow w-100 mt-10 p-3">
                          <div className="mb-2">
                            <Link className="nav-item text-dark" to="/shorts">
                              Shorts
                            </Link>
                          </div>

                          <div className="mb-2">
                            <Link className="nav-item text-dark" to="/videos">
                              Videos
                            </Link>
                          </div>
                          {/* <div className="mb-2">
                            <Link className="nav-item text-dark" to="/notes">
                              Course Notes
                            </Link>
                          </div> */}
                          <div className="mb-2">
                            <Link
                              className="nav-item text-dark"
                              to="/mock-tests"
                            >
                              Mock Tests
                            </Link>
                          </div>
                          <div className="mb-2">
                            <Link
                              className="nav-item text-dark"
                              to="/interview-preparation"
                            >
                              Interview Preparation Kits
                            </Link>
                          </div>
                          <div className="mb-2">
                            <Link
                              className="nav-item text-dark"
                              to="/technical-quizzes"
                            >
                              Quizzes
                            </Link>
                          </div>

                          <div className="mb-2">
                            <Link
                              className="nav-item text-dark"
                              to="/cheat-sheets"
                            >
                              Course Cheat Sheets
                            </Link>
                          </div>
                          {/* <div className="mb-2">
                            <Link
                              className="nav-item text-dark"
                              to="/free-resources"
                            >
                              Free Resources
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </Link>

                  <Link className="nav-item d-13k-none" to="/about">
                    <div className="nav-dropdown bg-transparent">
                      <SchoolIcon />
                      <p>About</p>
                      <div className="nav-dropdown-content p-3">
                        <div className="bg-white nav-dropdown-main shadow w-100 mt-10 p-3">
                          <div className="mb-2">
                            <Link className="nav-item text-dark" to="/reviews">
                              Student Reviews
                            </Link>
                          </div>

                          <div className="mb-2">
                            <Link className="nav-item text-dark" to="/about">
                              About Company
                            </Link>
                          </div>

                          <div className="mb-2">
                            <Link className="nav-item text-dark" to="/features">
                              Features
                            </Link>
                          </div>
                          <div className="mb-2">
                            <Link
                              className="nav-item text-dark"
                              to="/internships"
                            >
                              Careers
                            </Link>
                          </div>

                          <div className="mb-2">
                            <Link className="nav-item text-dark" to="/contact">
                              Contact Us
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>


                  <Link
                        className="nav-item  d-lg-block d-none"
                        to="/contact"
                      >
                        <CallIcon />
                        <p>Contact</p>
                      </Link>
                 

                  {/*left toggle */}
</>}
{/*nav items*/}
                  <div className="d-flex align-items-center">
                    <button
                      onClick={ToggleSidebar}
                      className="btn left toggle ml-15 d-none d-sm-flex  btn-primary text-white"
                    >
                      {sidebar ? (
                        <>
                          <CloseIcon className="text-white" />
                          <p className="mb-0 pb-0 d-none text-white d-sm-block">
                            Close
                          </p>
                        </>
                      ) : (
                        <>
                          
                          <MenuIcon className="text-white" />
                          <p className="mb-0 pb-0 d-none text-white d-sm-block mt-0">
                            Menu
                          </p>{" "}
                        </>
                      )}{" "}
                    </button>
                  </div>
                </div>

                {loggedin ? (
                  <>
                    <Stack
                      direction="row"
                      className={search&&device==="mobile"?"d-flex align-items-center w-100 chec":"d-flex align-items-center"}
                      spacing={1}
                    >
                      {/*left search icon 2 diaplayed in mobile after login*/}
                      {search?
                      <>
                      
                      </>:
                      <>
                      <SearchIcon
                        onClick={() =>ShowSearch()}
                        className="text-light me-2 search2 d-block d-sm-block d-md-none mt-1  click "
                      />
                      </>}
      {device==="mobile"?
      <>
      {/*main mobile search */}
       <NavbarCourseSearch HandleClose={HideSearch} ShowSearch={ShowSearch} visibility={search}/>
   
      </>:
      <>
      
      </>}
                      {device==="mobile"&&search?
                      <>
                      
                      
                      
                      </>:
                      <>
                      {/* <IconButton>
                        <Link to="/chat">
                          <Badge badgeContent={messages} color="error">
                            <ChatIcon className="text-white" />
                          </Badge >
                        </Link>
                      </IconButton> */}

                      <IconButton>
                        <Link to="/notifications">
                          <Badge badgeContent={unread} color="error">
                            <NotificationsIcon className="text-white" />
                          </Badge>
                        </Link>
                      </IconButton>
                      </>}
                      {account ? (
                        <>
                          <div className="d-none d-sm-block">
                            <AccountOptionsDrowdown account={account} />
                          </div>
                        </>
                      ) : null}

                    {search&&device==="mobile"?
                    <>
                    
                    </>:
                    <>
                      <div className="d-flex align-items-center">
                        <button
                          onClick={() => {
                            ToggleSidebar();
                          }}
                          className="btn click  d-block d-sm-none  btn-primary"
                        >
                          {search ? (
                            <>
                              <CloseIcon />
                              <p className="mb-0 pb-0 d-none d-sm-block">
                                Close
                              </p>{" "}
                            </>
                          ) : (
                            <>
                              {" "}
                              <MenuIcon />
                              <p className="mb-0 pb-0 d-none d-sm-block">
                                Menu
                              </p>{" "}
                            </>
                          )}
                        </button>
                      </div>
                    </>}
                    </Stack>
                  </>
                ) : (
                  <>
                    <div className={search&&device==="mobile"?"d-flex align-items-center w-100":"d-flex align-items-center"}>
                  {device==="mobile"?
                  <>
                      <NavbarCourseSearch HandleClose={HideSearch} ShowSearch={ShowSearch} visibility={search}/>
   
                
                  </>:
                  <>
                  
                  </>}
                     {search&&device==="mobile"?
                     <>
                     
                     </>:
                     <>
                      {/*left search icon 3 */}
                      <SearchIcon
                        onClick={() =>ShowSearch()}
                        className="text-light me-4 d-block d-sm-block d-md-none mt-1  click "
                      />
                      <button
                        onClick={() => {
                          ToggleSidebar();
                        }}
                        className="btn  d-block d-sm-none  btn-primary"
                      >
                        {canvas ? (
                          <>
                            <CloseIcon />
                            <p className="mb-0 pb-0 d-none d-sm-block">
                              Close
                            </p>{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            <MenuIcon />
                            <p className="mb-0 pb-0 d-none d-sm-block">
                              Menu
                            </p>{" "}
                          </>
                        )}
                      </button>
                     </>}
                    </div>
                    <div className=" d-none d-sm-flex align-items-center">
                      <Link
                        to="/login"
                        className="btn d-none d-sm-block btn-login"
                      >
                        Login
                      </Link>

                      <Link
                        to="/signup"
                        className="btn d-block d-sm-none btn-signup"
                      >
                        Login/SignUp
                      </Link>

                      <Link
                        to="/signup"
                        className="btn d-none d-sm-block btn-signup "
                      >
                        SignUp
                      </Link>
                    </div>{" "}
                  </>
                )}
              </div>

              <div className="sec-nav"></div>
            </div>

            <div id="sidebar" onClick={ToggleSidebar} className="sidebar click hide-sidebar">
              <div className="sidebar-content">
                {account ? (
                  <>
                    <div className="bg-dark hacking-line text-light p-3">
                      <div className="d-flex">
                        <Avatar
                          src={account.profile_pic}
                          className="me-2"
                          sx={{ width: 50, height: 50 }}
                        />
                        <div>
                          <h6 className="mb-1 pb-0">
                            {account.first_name + " " + account.last_name}
                          </h6>
                          <Link
                            className="text-light"
                            to={"/u/" + account.username}
                          >
                            @{account.username}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div
                  onClick={HideSidebar}
                  className="sidebar-close click"
                >
                  <Close />
                </div>

                <div className="w-100  sidebar-scroller overflow-y">
                  {account ? (
                    <></>
                  ) : (
                    <>
                      <Link
                        onClick={() => HideSidebar()}
                        className="d-flex"
                        to="/login"
                      >
                        <div className="sidebar-item">
                          <img src={accounticon} />

                          <p to="/signup" className="">
                            Login/Signup
                          </p>
                        </div>
                      </Link>
                    </>
                  )}

                  <Link onClick={() => HideSidebar()} className="d-flex" to="/">
                    <div className="sidebar-item">
                      <img src={homeicon} />

                      <p className="">Home</p>
                    </div>
                  </Link>

                  {account ? (
                    <>
                      <Link
                        onClick={() => HideSidebar()}
                        className="d-flex"
                        to="/dashboard"
                      >
                        <div className="sidebar-item">
                          <img src={dashboardicon} />

                          <p className="">Dashboard</p>
                        </div>
                      </Link>

                      <Link
                        onClick={() => HideSidebar()}
                        className="d-flex"
                        to="/my-courses"
                      >
                        <div className="sidebar-item">
                          <img src={mycourse} />

                          <p to="/my-courses" className="">
                            My Courses
                          </p>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}

                  <Link className="d-none" onClick={() => HideSidebar()} to="/free-courses">
                    <div className="sidebar-item">
                      <img src={freetrainingicon} />

                      <p className="">Free Courses</p>
                    </div>
                  </Link>

                  <Link onClick={() => HideSidebar()} to="/offline-courses">
                    <div className="sidebar-item">
                      <img src={offlinetrainingicon} />

                      <p className="">Offline Courses</p>
                    </div>
                  </Link>

                  <Link onClick={() => HideSidebar()} to="/online-courses">
                    <div className="sidebar-item">
                      <img src={onlinetrainingicon} />

                      <p className="">Online Courses</p>
                    </div>
                  </Link>

                  <Link
                    onClick={() => HideSidebar()}
                    to="/specialization-courses"
                  >
                    <div className="sidebar-item">
                      <img src={specializationicon} />
                      <p className="">Specialization Courses</p>
                    </div>
                  </Link>

                  <Link onClick={() => HideSidebar()} to="/courses">
                    <div className="sidebar-item">
                      <img src={allcourses2} />
                      <p className="">All Courses</p>
                    </div>
                  </Link>

                  <Link onClick={() => HideSidebar()} to="/selfpaced-courses">
                    <div className="sidebar-item">
                      <img src={selfpacedicon} />
                      <p className="">Selfpaced Courses</p>
                    </div>
                  </Link>

                  <Link onClick={() => HideSidebar()} to="/internships">
                    <div className="sidebar-item">
                      <img src={internshipsicon} />
                      <p className="">Internships</p>
                    </div>
                  </Link>

                  {/* <Link onClick={() => HideSidebar()} to="/events">
                    <div className="sidebar-item">
                      <img src={workshopsicon} />
                      <p className="">Workshops</p>
                    </div>
                  </Link> */}

                  {/* <Link onClick={() => HideSidebar()} to="/videos">
                    <div className="sidebar-item">
                      <img src={videosicon} />
                      <p className="">Video Tutorials</p>
                    </div>
                  </Link> */}

                  <Link onClick={() => HideSidebar()} to="/features">
                    <div className="sidebar-item">
                      <img src={featuresicon} />
                      <p className="">Training Features</p>
                    </div>
                  </Link>
                  <Link
                        onClick={() => HideSidebar()}
                        to="/referral-program"
                      >
                        <div className="sidebar-item">
                          <img src={shareicon} />
                          <p className="">Refer & Earn</p>
                        </div>
                      </Link>
                  {account ? (
                    <>
                      
                    </>
                  ) : (
                    <></>
                  )}

                  <Link onClick={() => HideSidebar()} to="/reviews">
                    <div className="sidebar-item">
                      <img src={reviewicon} />
                      <p className="">Students Reviews</p>
                    </div>
                  </Link>

                  <hr />

                  <Link onClick={() => HideSidebar()} to="/about">
                    <div className="sidebar-item">
                      <img src={companyicon} />
                      <p to="/about" className="">
                        About Us
                      </p>
                    </div>
                  </Link>

                  <Link onClick={() => HideSidebar()} to="/contact">
                    <div className="sidebar-item">
                      <img src={supporticon} />
                      <p to="/contact" className="">
                        Contact Us
                      </p>
                    </div>
                  </Link>

                  <Link
                    onClick={() => HideSidebar()}
                    to="/terms-and-conditions"
                  >
                    <div className="sidebar-item">
                      <img src={termsicon} />
                      <p className="">Terms & Conditions</p>
                    </div>
                  </Link>

                  <Link onClick={() => HideSidebar()} to="/privacy-policy">
                    <div className="sidebar-item">
                      <img src={privacyicon} />
                      <p className="">Privacy Policy</p>
                    </div>
                  </Link>

                  <Link onClick={() => HideSidebar()} to="/refund-policy">
                    <div className="sidebar-item">
                      <img src={refundicon} />
                      <p className="">Refund Policy</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
       {device==="mobile"?
       <>
       
       </>:
       <>
          <div id="navbar2" className="secondary-navbar nav2-items bg-dark displaynavbar2">
         <div className="px-sm-0 h-100 d-flex w-100 align-items-center">
         <div className="container sm-p-0  position-relative h-100 bg-dark  d-flex align-items-center">

<ChevronLeft className="navchev-left text-secondary d-md-none"/>

<div className="col-12 d-flex  align-items-center overflow-x mt-17">
{/* <p  className="nav-item"> <p>Our Team</p></p> */}

<Link to="/shorts" className="nav-item"> <p>Shorts</p></Link>

<Link to="/videos" className="nav-item"> <p>Videos</p></Link>

<Link to="/ethical-hacking-internship" className="nav-item"> <p>Ethical Hacking Internship</p></Link>
{/* <Link to="/summer-internship" className="nav-item"> <p>Summer Internship</p></Link> */}

<Link to="/campus" className="nav-item"> <p>For <b  className="font-weight-normal">Colleges / Universities</b></p></Link>
{/* <a href="https://globalhackersunited.com" target="_blank" className="nav-item"> <p>Join <b  className="font-weight-normal">Global Hackers United (GHU)</b></p></a> */}

<vr></vr>
{/* <Link to="/events" className="nav-item"> <p>Events</p></Link> */}

<Link to="/campus-ambassador-internship" className="nav-item"> <p>Campus Ambassador Internship</p></Link>



<Link to="/features" className="nav-item"> <p>Features</p></Link>

<Link to="/gallery" className="nav-item"> <p>Gallery</p></Link>
<Link to="/reviews" className="nav-item"> <p>Reviews</p></Link>

<Link to="/careers" className="nav-item"> <p>Internships</p></Link>

{/* <Link to="/contact" className="nav-item"> <p>Contact Us</p></Link> */}

         

</div>
<ChevronRight className="navchev-right text-secondary d-md-none"/>
   </div>
         </div>
          </div>
       </>}
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;

const StyledNavbar = styled.div``;
