import { Avatar } from '@mui/material'
import React, { useEffect } from 'react'
import Rating from '@mui/material/Rating';
import {Link} from 'react-router-dom'
import divider from '../../assets/bg/divider.png'
import SetPage from '../../data/SetPage';
import { ForkRight } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
const Reviews2 = () => {


  return (
    <div className="w-100">

<div className="text-center">
     <h1 className="font-weight-bold secondary-text ">Rated 4.8 by 20k+ Students</h1>
<h5 className="">Loved by Students & Respected by professionals</h5>

<div class="divider">
<img src={divider}  alt="divider"></img>
</div>
  

</div>
<div className="container d-flex flex-wrap animate">

<div className="col-lg-4 delay1s fade-in-bottom col-md-6 col-sm-12 col-12 sm-mt-20  sm-p-0 sm-mb-20 p-3">

<div className="border bg-white br-5 p-3">

<div className="d-flex">
<img src="https://skillsuprise.com/resources/images/viraj.png" className="userdp mr-15 rounded-circle"/>
<div>
<h5 className="sm-16 sm-pb-0">Viraj Mehta</h5>
<p className="sm-12">Sampada College, Maharashtra</p>
<p className="sm-12">4th Year - IT</p>
<div className="d-flex sm-12">
<Rating name="read-only" value={4.5} readOnly /> (5)
</div>
</div>
</div>
<hr/>
<div className="p-2">
  <p className="sm-14">


  I have learnt great things with Skills Uprise. Apart from the innovative teachings methods of manoj sir, The most interesting part is that groups will be created with different college students that helps us meet new people and share our ideas with them and we can learn with our team with everyones support and i got much more interest to learn with such a friendly environment that helped me learn many things from my team mates too. 

  </p></div>
</div>
</div>


<div className="col-lg-4 delay2s fade-in-bottom col-md-6 col-sm-12 col-12  sm-mb-20 sm-p-0  p-3">

<div className="border bg-white br-5 p-3">

<div className="d-flex">
<img src="https://skillsuprise.com/resources/images/swetha.png" className="userdp mr-15 rounded-circle"/>
<div>
<h5 className="sm-16 sm-pb-0">Swetha Kommula</h5>
<p className="sm-12">IIIT srikakulam, Andhra Pradesh</p>
<p className="sm-12">4th Year - CSE</p>
<div className="d-flex sm-12">
<Rating name="read-only" value={4.5} readOnly /> (5)
</div>
</div>
</div>
<hr/>
<div className="p-2">

<p className="sm-14">

Honestly, this is best training program ever. I have joined so many paid internships & trainings but i gained nothing except certificates. But Skills Uprise really taught so much with industry standard levels, manoj sir played absolute trainer role & i like the way he teach, the way he took examples,and training everything is perfect & i appreciate it. And i really wanted to do an intern in your company to learn more practical stuff. Manoj sir you are amazing and perfect trainer.
</p>
</div>
</div>
</div>



<div className="col-lg-4 delay3s fade-in-bottom col-md-6 col-sm-12 mx-md-auto col-12  sm-p-0 p-3">

<div className="border bg-white br-5 p-3">

<div className="d-flex">
<img src="https://skillsuprise.com/resources/images/anirudh.png" className="userdp mr-15 rounded-circle"/>
<div>
<h5 className="sm-16 sm-pb-0">Anirudh Dhibba</h5>
<p className="sm-12">Vignan Engineering College, Vizag</p>
<p className="sm-12">4th Year - CSE</p>
<div className="d-flex sm-12">
<Rating name="read-only" value={4.5} readOnly /> (5)
</div>

</div>
</div>
<hr/>
<div className="p-2">
  <p className="sm-14">
  It's really a good learning experience to learn with a faculty who have a good knowledge knowing how and what to explain in a perfect manner. Im really happy and satisfied regarding the training program Providing all the recording sessions for people to help them looking back into the concepts providing realtime application problem and helping us to sort out is really the best thing I have learnt as a Starter it helped me a lot to learn.
  </p>
</div>
</div>
</div>





</div>

<div className="text-center mt-4 mb-5">
  <Link to="/reviews" className="btn   btn-primary rounded-pill btn-border">1000s of reviews by happy students <ArrowRightIcon/></Link>
</div>
    </div>
  )
}

export default Reviews2