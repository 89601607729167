import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import GetAssignments from '../../../actions/course/GetAssignments'
import { AuthContext } from '../../../context/AuthContext'
import AssignmentDescriptionAccordion from './AssignmentDescriptionAccordion'
import SetPage from '../../../data/SetPage'
import { Alert, Skeleton } from '@mui/material'
import AssignmentsChart from './AssignmentsChart'
import ModalStyle from '../../../data/ModalStyle'
import AssignmentSubmit from './AssignentSubmit'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import noassignments from '../../../assets/images/no-assignments.svg'
import CloseIcon from '@mui/icons-material/Close';
import { Close, PlayCircle } from '@mui/icons-material'
import dateFormat from 'dateformat'
import GetCourseMilestones from '../../../actions/course/GetCourseMilestones'
import CourseMilestoneSubmit from './CourseMilestoneSubmit'
import AnimatePage from '../../../actions/animations/AnimatePage'
import MilestonesModal from './MilestonesModal'

import Typography from '@mui/material/Typography';
import Localbase from 'localbase'

const CourseMilestones = () => {

const params = useParams()
const course_url = params.course_url
const batch_id = params.batch_id
const {user} = useContext(AuthContext)
const [report,setreport] = useState(null)
const [assignments,setassignents] = useState(null)
let db = new Localbase('db')
const NewAssignments = async()=>{
  const res = await GetCourseMilestones({
    payload:{
      user:user.SessionId,
      course_url:course_url,
      batch_id:batch_id
    }
  })
  if(res){
  
    setloading(false)
  
    if(res.status==="success"&&res.data!==null){

setassignents(res.data)
const data = res.data
const report = res.report[0]
setreport(res.report[0])
db.collection('paths').add({
  data:data,
  report:report
},'milestone-'+JSON.stringify(course_url))
AnimatePage()
    }
    
  }
}

useEffect(()=>{
SetPage("Assignments")
window.scrollTo(0,0)

},[])
const [currenttask,setcurrenttask] = useState(null)
const offlinedata = () =>{
    db.collection("paths").doc('milestone-'+JSON.stringify(course_url)).get().then((data)=>{
    console.log("data is ",data.data)
    setloading(false)
    setassignents(data.data)
    setreport(data.report)
    
  })
}
useEffect(()=>{
  offlinedata()
},[])
useEffect(()=>{
NewAssignments()

},[])

const InitiateSubmission = ({id})=>{
  setcurrenttask(id)
  setsubmit(true)
}

const [data,setdata] = useState({title:null,description:null})
const [visibility,setvisibility] = useState(false)
const HandleModal = ()=>{


  setvisibility(!visibility)
}

const [demo,setdemo] = useState(false)
const [loading,setloading] = useState(true)
const [submit,setsubmit] = useState(false)
  return (
    <div className="min-vh-100 animate">
 <Modal
  open={demo}
  onClose={()=>setdemo(false)}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2">
<div className="d-flex justify-content-between">
     <h4>Introduction</h4>
     <Close className="text-danger" type="button" onClick={()=>setdemo(false)}/>
</div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <video className="w-100"  poster="https://skillsuprise.com/data/videos/milestones-demo.png"  autoPlay src="https://skillsuprise.com/data/videos/milestones-demo.mp4"></video>
    </Typography>
  </Box>
</Modal>
<div className="milestone-section pt-5">

<div className="container d-flex text-light justify-content-between">


<div className="col-lg-7  col-12 text-light">
<h1 className='fade-in-bottom delay1s'>Do More, Learn More & Achieve More</h1>
<p className="text-light fade-in-bottom delay2s">Unleash Your Potential with course Milestones. With every milestone designed for your overall growth, complete your milestones and - Transform yourself into Confident Professional. </p>


<div className="mt-3">
  <button className='btn btn-warning fade-in-bottom delay2s' onClick={()=>setdemo(true)}><PlayCircle/> Watch Video</button>
</div>
</div>
<div className="col-lg-3 me-3">
  <img src="https://skillsuprise.com/resources/images/milestone-page.png" className='w-100'></img>
</div>

</div>
</div>


{loading?
<>

<div className="container grid lg-grid-4 grid-1 mt-3">

<Skeleton height={200} variant="rectangular" className="w-100 mt-2 mb-2 "/>

<Skeleton height={200} variant="rectangular" className="w-100  mt-2 mb-2 "/>

<Skeleton height={200} variant="rectangular"  className="w-100  mt-2 mb-2 "/>


<Skeleton height={200} variant="rectangular"  className="w-100  mt-2 mb-2 "/>


<Skeleton height={200} variant="rectangular"  className="w-100  mt-2 mb-2 "/>


<Skeleton height={200} variant="rectangular"  className="w-100  mt-2 mb-2 "/>


<Skeleton height={200} variant="rectangular"  className="w-100  mt-2 mb-2 "/>


<Skeleton height={200} variant="rectangular"  className="w-100  mt-2 mb-2 "/>





</div>


</>:
<>











<Modal
        open={submit}
        onClose={()=>{setsubmit(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="col-lg-4 col-md-5 col-sm-6 col-11 rounded p-3">
          <div className="modal-header">
            <h4>Submit Milestone</h4>
            <Close className="click" onClick={()=>setsubmit(false)}/>
          </div>
          <hr/>
        <CourseMilestoneSubmit Refresh={NewAssignments} HandleClose={()=>setsubmit(false)} id={currenttask}/>
        </Box>
      </Modal>

<MilestonesModal visibility={visibility} HandleModal={HandleModal} data={data}/>


      <div className="container pt-2">

{assignments?
<>


<div className=" grid lg-grid-4 grid-1">
{assignments.map((project,index)=>(
  <>
<div className={"border text-center br-4 mb-3 mt-3  w-100 fade-in-bottom delay"+(index+1)+"s"}>

<img src={project.image} className="w-100 rounded-top bg-dark" ></img>
<div className="p-3">


<div className="d-flex justify-content-between w-100">
  <button onClick={()=>{setdata(project);setvisibility(true)}} className='btn '>Read More</button>
  {project.status==="submitted"?
<>
<button  disabled className="btn   btn-success rounded-pill ">Earned {project.marks} Points</button>



</>:
<>
<button onClick={()=>{InitiateSubmission({id:project.assignment_id})}} className="btn  btn-outline-primary rounded-pill ">Earn {project.marks} Points</button>


</>}
</div>
</div>

</div>
  </>
))}
</div>


</>:

<>

<div className="text-center col-lg-6 col-md-6 col-sm-12 mx-auto mt-5 pb-5">

<img src={noassignments} className="w-75 mx-auto mb-3"/>
<h4>No Milestones Found</h4>
<p>Please contact your trainer for your course milestones</p>

</div>


</>

}



          </div>




   



</>}




     </div>
  )
}

export default CourseMilestones