import { Avatar, CircularProgress } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import 'tui-image-editor/dist/tui-image-editor.css';
import QuestionIcon from '@mui/icons-material/QuestionAnswer';
import ImageIcon from '@mui/icons-material/Collections';
import { useState } from 'react';
import UploadPost from '../../actions/post/UploadPost';
import ModalStyle from '../../data/ModalStyle';
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'
import classes from './classes'
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import getCroppedImg from './cropImage'
import {  useCallback } from 'react'
import Modal from '@mui/material/Modal';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';

import ENDPOINT from '../../data/ENDPOINT';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import FetchFingerprint from '../../actions/FetchFingerprint';

const StatusUpdate = ({account,RefreshPosts}) => {
const [file,setfile] = useState(null)
const reader = new FileReader();



const [status,setstatus]= useState(false)
const [filetype,setfiletype] = useState(null)

const [uploadprogress,setuploadprogress] = useState(0);

const [filesize,setfilesize] = useState(0)



const {user} = useContext(AuthContext)



const FileChange = (e)=>{
     setfile(e.target.files[0])
   setpath(URL.createObjectURL(e.target.files[0]))
  
}



{/*


   formData.append('description',payload.desc);
     
     }
        formData.append('session',payload.user);
       
        formData.append('course_url',payload.course_url);
        formData.append('category',payload.category);
        formData.append('batch_id',payload.batch_id);
*/}
const [posts,setposts] = useState(null)
     
const UploadImage = async()=>{
    

   
     setfilesize(file.size)
       setfiletype(file.filetype)
    
    ////  //  //console.log("changed"+file.filetype)
     const config = {
       onUploadProgress: function(progressEvent) {
         var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
     // //  //  //console.log("total "+progressEvent.total)
         setuploadprogress(percentCompleted)
     
         setfilesize((progressEvent.total/1000000));
   
       }
     }
   
   
   
 
       const formData = new FormData();

       formData.append('resume',croppedImage);
       ////  //  //console.log("Type: " + file.type);
       ////  //  //console.log("Size: " + file.size + " bytes");
       //check for image
   if(file.type==="image/jpeg" ||file.type==="image/png" ||file.type==="image/jpg"){
   
   
     if(description){
       formData.append('description',description);
     
     }
        formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
       
        formData.append('course_url',course_url);
        formData.append('category',"student");
        formData.append('batch_id',"1501");
       
        const res =  await axios.post(ENDPOINT+`student-post.php`, formData,config,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
   if(res){
     //  //console.log("posted info"+JSON.stringify(res))
     RefreshPosts()
     if(res.data.status==="success"){
        
 
setuploadmodal(false)
     }
    
   }
   }else{
  
   }
   
   
       
   
   

}

const [path,setpath] = useState(null)
const [imagemodal,setimagemodal] = useState(false)

const [showfilters,setshowfilters] =useState(false)
const [filtermodal,setfiltermodal] = useState(false)

const ShowFilters = ()=>{
     setimagemodal(false)
     setfiltermodal(true)
     
    
}


const StartUpload = ()=>{
const button = document.getElementById("image-upload")
button.click()

}


const dogImg = path;

const [crop, setCrop] = useState({ x: 0, y: 0 })
const [rotation, setRotation] = useState(0)
const [zoom, setZoom] = useState(1)
const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
const [croppedImage, setCroppedImage] = useState(null)

const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels)
}, [])
const [croppedImageUrl,setcroppedImageUrl] = useState(null)
const showCroppedImage = useCallback(async () => {
     setsettingimg(true)
  try {
    const croppedImage = await getCroppedImg(
      dogImg,
      croppedAreaPixels,
      rotation
    )

    setcroppedImageUrl(URL.createObjectURL(file))
     //  //console.log("croppped url"+JSON.stringify(setcroppedImageUrl))
  //  //console.log(croppedImage)
    setCroppedImage(croppedImage)
    setuploadmodal(true)
    setfiltermodal(false)
    setsettingimg(false)
  } catch (e) {
     setsettingimg(false)
    console.error(e)
  }
}, [croppedAreaPixels, rotation])

const onClose = useCallback(() => {
  setCroppedImage(null)
}, [])

const [description,setdescription] = useState(null)

const DescriptionChange =(e)=>{
     setdescription(e.target.value)
     let desc = e.target.value
     const length = desc.length

     if(desc.includes("@")){
const index = desc.indexOf("@");


let slice = desc.slice(index,length)
if(slice.includes(" ")){
     const spaceindex = slice.indexOf(" ")
      //  //console.log("username is "+slice.slice(0,spaceindex))
}
 //  //console.log("slice is "+slice)

           //  //console.log("index is "+index+" length is "+length)

           //  //console.log()
     }
}
const [loading,setloading] = useState(false)
const [videomodal,setvideomodal] = useState(false)
const [state,setstate] = useState()
const [alert,setalert] = useState(false)
   const [settingimg,setsettingimg] = useState(false)
const [uploadmodal,setuploadmodal] = useState(false)
const [course_url,setcourse_url] = useState(null)
  return (
    <div>


<Modal

  open={imagemodal}
  onClose={()=>setimagemodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-2 max-vh-80 br-5 col-lg-4 col-md-5 col-sm-6 col-11 mx-auto">
    <Typography id="modal-modal-title" variant="h6" component="h2">
  
      <div className="d-flex justify-content-between">
          <h5 className="click" onClick={()=>setimagemodal(false)}>Cancel</h5>
          {file?
          <>
               <h5  onClick={()=>ShowFilters()} className="click text-primary">Next</h5>
   
          </>:null}
        </div>
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
    <img src={file?URL.createObjectURL(file):""} className="w-100" />
    <input id="image-upload" className="btn-upload d-none" type="file" onChange={(e)=>FileChange(e)}/>
   
    
   
    <button onClick={()=>StartUpload({file:file})} className="btn-upload  w-100 mb-3 mt-3">{file?"Change Image":"Select Image"}</button>
    
        </Typography>
  </Box>
</Modal>



<Modal

  open={videomodal}
  onClose={()=>setvideomodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-2 max-vh-80 br-5 col-lg-4 col-md-5 col-sm-6 col-11 mx-auto">
    <Typography id="modal-modal-title" variant="h6" component="h2">
  
      <div className="d-flex justify-content-between">
          <h5 className="click" onClick={()=>setvideomodal(false)}>Cancel</h5>
          {file?
          <>
               <h5  onClick={()=>ShowFilters()} className="click text-primary">Next</h5>
   
          </>:null}
        </div>
    </Typography>
    <Typography  sx={{ mt: 1 }}>
      {file?
      <>

      <div className="text-center">
      <video autoPlay src={file?URL.createObjectURL(file):""} className="max-vh-50 mx-auto" />
   
      </div>
     
      </>:null}
    <input id="image-upload" className="btn-upload d-none" type="file" onChange={(e)=>FileChange(e)}/>
   
    
   
    <button onClick={()=>StartUpload({file:file})} className="btn-upload  w-100 mb-3 mt-3">{file?"Change Image":"Select Video"}</button>
    
        </Typography>
  </Box>
</Modal>


<Modal
  open={alert}
  onClose={()=>setalert(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-3 br-5 col-11 col-sm-8 col-md-4 col-lg-4">
   <div className="d-flex justify-content-between">
    <h4 className="text-danger">Access Denied !</h4>
    <Close  onClick={()=>setalert(false)}/>
   </div>
   <hr/>
   <div>
    <p>Register a course to connect with friends and learn together.</p>
   </div>
   <div className="d-flex justify-content-between mt-4">
    <button onClick={()=>setalert(false)} className="btn btn-outline-danger col-5 rounded-pill">Cancel</button>
    <Link to="/courses" className="btn btn-primary rounded-pill col-5">Browse Courses</Link>
   </div>
  </Box>
</Modal>


<Modal
  open={uploadmodal}
  onClose={()=>setuploadmodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-2 max-vh-80 br-5 col-lg-7 col-md-8 col-sm-10 col-11 mx-auto">
    <Typography id="modal-modal-title" variant="h6" component="h2">
  
      <div className="d-flex flex-wrap justify-content-between">
      <h5 className="click" onClick={()=>setuploadmodal(false)}>Cancel</h5>
      
          
      
                </div>
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 1 }}>

     <div className="d-flex flex-wrap w-100">

<div className="col-lg-7 col-12">
{croppedImage?
   <>
    <img  src={URL.createObjectURL(croppedImage)} className="w-100" />
    
   </>:null}


</div>
<div className="col-lg-5 col-12 p-2 sm-p-0 sm-mt-20">


<TextareaAutosize
  aria-label="minimum height"
  minRows={3}
  onChange={(e)=>DescriptionChange(e)}
  placeholder={account.first_name+" What's your mind ?"}
  className="w-100 form-control"




/>
<p>course is {course_url} </p>

<select value={course_url} required onChange={(e)=>setcourse_url(e.target.value)}>
  <option>Select Course</option>
{account.purchases?
<>
{account.purchases.map((course)=>(
  <>
<option value={course}>{course}</option>

  </>
))}
</>:
<></>}
</select>
<button onClick={()=>UploadImage()} {...loading?'disabled="true"':null} className="btn-primary  btn w-100 mb-3 mt-3">{loading?"Uploading...":"Post Update"}</button>
    
</div>

     </div>
 
   
   
        </Typography>
  </Box>
</Modal>






<Modal
  open={filtermodal}
  onClose={()=>setfiltermodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-2 br-5 max-vh-80 col-lg-4 col-md-5 col-sm-7 col-11 mx-auto">
    <Typography id="modal-modal-title" variant="h6" component="h2">
  
      <div className="d-flex justify-content-between">
      <h5 className="click" onClick={()=>setfiltermodal(false)}>Cancel</h5>
      
          <h5 onClick={()=>{
            ShowFilters();
            showCroppedImage();

          }} className="text-primary">Nextuu</h5>
      </div>
    </Typography>

     <>
     

     <div className=" h-fit position-relative">
     {path?
     <>
     <img  src={path} className="w-100  op-0"/>
     </>:null}
   <div className="crop-container">
        
        <Cropper
        className="cropper"
          image={dogImg}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
  

        
   
     
    </div>
     
     
     </>
    <div>
 
      <img src={croppedImage}/>
      
    </div>

<div className=" mt-3 ">
     <div className="mb-3 mt-3 crop-range">
          <p>Crop</p>
          <input
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value)
          }}
          className="zoom-range"
        />
     </div>

<div className="d-flex justify-content-between">
<div className="d-flex">

     <div className="text-center">

     <RotateLeftIcon type="button" onClick={()=>setRotation(rotation-90)}/>
<p className="extra-small">Rotate Left</p>

     </div>
     
     
     <div className="text-center ml-15">
     <RotateRightIcon type="button" onClick={()=>setRotation(rotation+90)}/>
  
<p className="extra-small">Rotate Right</p>
     </div>
     
     

</div>



<Button
          onClick={showCroppedImage}
          variant="contained"
          color="primary"
          classes={{ root: classes.cropButton }}
        >
          {settingimg?
          <>
          <CircularProgress color="inherit" className="mr-5" size={20}  disableShrink  />
          </>:
          
          <>
            Done</>}
        
        </Button>

</div>


 
 
</div>
   
  </Box>
</Modal>









{account.purchases!==null&&account.purchases.length>0?
<>

<div className="shadow  p-2 br-5">
     <div className="d-flex w-100">
     <Avatar className="mr-15 click" src={account.profile_pic?account.profile_pic:null}/>
<div className="w-100">
     <input className="form-control rounded-pill w-100" placeholder={account.first_name+" What's in your minds ?"}/>

</div>
     </div>

<div className="d-flex  post-option  mt-2 justify-content-between align-items-center">
     <div onClick={()=>setimagemodal(true)} type="button" className="d-flex  post-option  click align-items-center">
     <ImageIcon/>
     <p className="extra-small post-option ">Photo</p>
     </div>
    

     <div  onClick={()=>setvideomodal(true)}  className="d-flex click  post-option  align-items-center">
     <QuestionIcon/>
     <p className="extra-small">Video</p>
     </div>

     <div className="d-flex  post-option  align-items-center">
     <QuestionIcon/>
     <p className="extra-small">Poll</p>
     </div>

     <div className="d-flex  post-option  align-items-center">
     <QuestionIcon/>
     <p className="extra-small ">Knowledgebase</p>
     </div>



</div>
</div>


</>:
<>

<div onClick={()=>setalert(true)} className="shadow   p-2 br-5">
     <div className="d-flex w-100">
     <Avatar className="mr-15 click" src={account.profile_pic?account.profile_pic:null}/>
<div className="w-100">
     <input className="form-control rounded-pill w-100" placeholder={account.first_name+" What's in your mindsss ?"}/>

</div>
     </div>

<div className="d-flex mt-2  post-option  justify-content-between align-items-center">
     <div  type="button" className="d-flex click align-items-center">
     <ImageIcon/>
     <p className="extra-small ">Photo</p>
     </div>
    

     <div className="d-flex  post-option  align-items-center">
     <QuestionIcon/>
     <p className="extra-small ">Video</p>
     </div>

     <div className="d-flex align-items-center">
     <QuestionIcon/>
     <p className="extra-small">Poll</p>
     </div>

     <div className="d-flex align-items-center">
     <QuestionIcon/>
     <p className="extra-small ">Knowledgebase</p>
     </div>



</div>
</div>


</>}


    </div>
  )
}

export default StatusUpdate