import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function DashboardCarousel() {

const [sliders,setsliders] = useState([{image:"https://skillsuprise.com/data/images/thumbnails/hanging-out.png","video":"https://skillsuprise.com/data/images/thumbnails/hanging-out.mp4",action_link:"https://skillsuprise.com/data/images/thumbnails/hanging-out.png"}])
  const GetSliders = async()=>{

const data = new FormData()

    const res = await axios.post(ENDPOINT+"get-dashboard-sliders.php",data,SecurityHeaders)


    if(res&&res.data){

console.log(res.data)
      if(res.data.status==="success"){
setsliders(res.data.sliders)
      }
      else{


      }
    }
  }

  useEffect(()=>{
GetSliders()
  },[])
  return (
   
        
<>


{sliders?
<>
<Carousel>
{sliders.map((slider,index)=>(


<Carousel.Item className='w-100' key={index}>
<a href={slider.action_link} className="w-100">

<img src={slider.image} className='w-100'></img>
</a>
      </Carousel.Item>
))}

</Carousel>
</>:
<>
<Carousel>
      <Carousel.Item>
      <Link to="/course/ethical-hacking-specialization/specialization">
      <img src="https://skillsuprise.com/data/images/thumbnails/dashboard-local-banner-1.png" className='w-100'></img>
</Link>
      </Carousel.Item>
      <Carousel.Item>
      <Link to="/course/fullstack-specialization/specialization">
      <img src="https://skillsuprise.com/data/images/thumbnails/dashboard-local-banner-2.png" className='w-100'></img>
 </Link>
      </Carousel.Item>
      <Carousel.Item>

<Link to="/course/data-science-specialization/specialization">
<img src="https://skillsuprise.com/data/images/thumbnails/dashboard-local-banner-3.png" className='w-100'></img>
</Link>
</Carousel.Item>
      </Carousel>
</>


}</>

  );
}

export default DashboardCarousel;