import React from "react";

const QuizAttempts = () => {
  return (
    <>
      <div className="container py-3">
        <h6>1. How to prepare for an interview?</h6>
        <div className="col-12 p-2">
          <ul>
            <li>watch youtube videos</li>
            <li>Be yourself</li>
            <li>pretend like you are confident</li>
            <li>Ask others who attended</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default QuizAttempts;
