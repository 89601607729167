import React from 'react'
import { Form, Modal, Toast } from 'react-bootstrap'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import ENDPOINT from '../../../data/ENDPOINT'
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import SetPage from '../../../data/SetPage'
import Skeleton from '@mui/material/Skeleton';
import nonotesicon from '../../../assets/icons/pdf-not-found.svg'
import FetchFingerprint from '../../../actions/FetchFingerprint'
const CourseNotes = ({props}) => {
const {user} = useContext(AuthContext)

const params = useParams();
useEffect(()=>{
  SetPage("Notes")
  document.addEventListener('DOMContentLoaded', function () {
    var iframe = document.getElementById('myframe'); // Replace 'myIframe' with your iframe's id
    if (iframe) {
        iframe.contentDocument.addEventListener('contextmenu', function (e) {
            e.preventDefault(); // Disable right-click inside the iframe
        });
    }
});
  },[])
  

const courseurl = params.course_url;
const batchid = params.batch_id;

const [chat,setchat] = useState(false);
const [subject,setsubject] = useState(null);
const [message,setmessage] = useState(null);
const [messageerror,setmessageerror] = useState(null);
const [subjecterror,setsubjecterror] = useState(null);
const [category,setcategory]= useState(null);
const [callback,setcallback]=  useState(false)
const [discussions,setdiscussions] = useState(null)

const [popup,setpopup] = useState(false)





useEffect(() => {
  window.scrollTo(0, 0)
  getDoubts()
  }, [discussions])
  







const [snackbar,setsnackbar] = useState(false)
const [snackmessage,setsnackmessage] = useState(null)



const [loading,setloading] = useState(true)


const getDoubts = async ()=>{
        
     
     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     
     formData.append('session',user.SessionId)
        formData.append("fp",FetchFingerprint());
     

       formData.append('course_url',courseurl);
      
     
     
     const res = await axios.post(ENDPOINT+"get-notes.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
         //  //console.log("got notes" + JSON.stringify(res.data))
setloading(false)
      if(res.data.status==="success"){
    
 setdiscussions(res.data.notes)
            
      }
      else{
     
      }
     }
     }




     return (
      <>
      

<div className="position-fixed justify-center bottom-60 left-0 container-fluid">


<Toast show={snackbar} onClose={()=>setsnackbar(false)} delay={4000} autohide className="space-between bg-warning">
           <Toast.Header>
             <img
               src="holder.js/20x20?text=%20"
               className="rounded me-2"
               alt=""
             />
            
           </Toast.Header>
           <Toast.Body>{snackmessage}</Toast.Body>
         </Toast>
</div>


<Modal
      {...props}
     
      aria-labelledby="contained-modal-title-vcenter"
      centered

      show={popup}
      onHide={()=>{setpopup(false)}}
    >
      
      <Modal.Body>
     
      <div className="container-fluid">
  
  <p>Our student relationship manager will immediately after your request to arrange a session according to the availability of the trainer and your convinience.</p>


  <div className="flex space-between mt-4">
   <button className="btn btn-danger w-100 col-5" onClick={()=>{setpopup(false)}}><Close/> Cancel</button>
   <button className="btn btn-primary w-100 col-5" type="submit"  ><Check/> Confirm Request</button>
     
   </div>





      </div>
   
  



    </Modal.Body>
     
    </Modal>








{loading?
<>

<div className="container-fluid d-flex flex-wrap justify-content-between">

<div className="col-lg-8">
<Skeleton variant="rectangular" className="w-100 mt-2 mb-2 min-vh-100" height={100} />


</div>


<div className="col-lg-3">

<Skeleton variant="rectangular" className="w-100 mt-2 mb-2" height={200} />


<Skeleton variant="rectangular" className="w-100 mt-2 mb-2" height={200} />

</div>
</div>

</>:
<>
{discussions?
<>


<div className="container-fluid min-vh-100 bg-dark min-vh-90 pt-0 pb-2">
               <iframe id="myframe" className="w-100 vh-100" title="notes" src={discussions.notes_url+"#toolbar=0"} aria-controls="false">
                 </iframe>         </div>



</>:
<>

<div className="text-center  px-2 px-md-0  min-vh-100 pt-5 pb-5">

  <div className="col-lg-3 col-md-4 col-sm-5 col-7 mb-4 mx-auto">
    <img src={nonotesicon} className="w-100"/>
  </div>
  <h4>No notes found</h4>
  <p>Please contact your trainer to get access to your notes.</p>
</div>
</>}

        

</>}





      

      </>
     )
}

export default CourseNotes
