import React from 'react'

import FingerprintJS from '@fingerprintjs/fingerprintjs';

const FetchFingerprint = async ()=>{
  // Initialize an agent at application startup.
  const fp = await FingerprintJS.load();

  // Get the visitor identifier when you need it.
  const result = await fp.get();

  // Device ID
  const deviceId = result.visitorId;
  


  return deviceId;
}





export default FetchFingerprint